import React, { createContext, useMemo, useState } from "react";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { App } from "./App";
import {
  materialDarkTheme,
  materialLightTheme,
} from "@accenture/energy-transition-ui-library";

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
export const ColorModeContext = createContext({
  toggleColorMode: (value: boolean) => {},
});

export const ProviderWrapper = () => {
  const [isDarkModeOn, setIsDarkModeOn] = useState<boolean>(false);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: (value: boolean) => {
        setIsDarkModeOn(value);
      },
    }),
    [],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={isDarkModeOn ? materialDarkTheme : materialLightTheme}
          >
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </ColorModeContext.Provider>
  );
};
