import React, { useState } from "react";
import {
  LayerInfoSidebarBody,
  LayerInfoSidebarContent,
  LayerInfoSidebarDivider,
  LayerInfoSidebarHeader,
} from "./LayerInfoSidebar";
import { styled } from "@mui/material";
import {
  CollapsiblePanel,
  HexagonIcon,
} from "@accenture/energy-transition-ui-library";
import {
  PrioritizationFactorsResponse,
  editPrioritizationFactorsQuery,
} from "../Sidebar/SidebarComponents/RunPrioritization/runPrioritizationQueries";
import { useQuery } from "@tanstack/react-query";
import {
  selectPrioritizationFactors,
  selectSelectedHex,
  setSelectedHex,
} from "../redux/workspaceSlice";
import { useSelector } from "react-redux";
import { useMap } from "react-map-gl";
import { useAppDispatch } from "../redux/store";

const TabsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 12px 0px;
`;

const Tab = styled("button")<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  padding: 11px 16px;
  height: 40px;
  align-items: flex-start;
  cursor: pointer;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "bold !important" : 400)};
  line-height: 110%;
  letter-spacing: -0.32px;
  text-transform: capitalize;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 4px solid
    ${(props) =>
      props.selected
        ? props.theme.colors.button.primary
        : props.theme.colors.border.light};
  color: ${(props) => props.theme.colors.text.major};
  background-color: transparent;
  font-weight: normal;
  justify-content: center;

  &:hover {
    border-bottom: 4px solid ${(props) => props.theme.colors.button.secondary};
  }

  &:focus {
    border-bottom: 4px solid ${(props) => props.theme.colors.button.primary};
    font-weight: bold;
  }
`;

const EVChargersTabContents = styled("div")`
  .panel-container {
    margin-top: 16px;
  }
  ol {
    margin: 0;
  }
`;

const StyledLi = styled("li")`
  cursor: pointer;
  text-decoration: underline;
`;

export const RunPrioritizationTabs = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();
  const factors = useSelector(selectPrioritizationFactors);
  const selectedHex = useSelector(selectSelectedHex);
  const { data } = useQuery({
    queryKey: editPrioritizationFactorsQuery(factors).queryKey,
    queryFn: editPrioritizationFactorsQuery(factors).queryFn,
  });
  enum RunPrioritizationTabs {
    EVChargers = "ev-chargers-tab",
    Buildings = "buildings-tab",
  }
  const [tabIndex, setTabIndex] = useState<string>(
    RunPrioritizationTabs.EVChargers,
  );

  const handleSelectTab = (tab: string) => {
    setTabIndex(tab);
  };

  const zoomToHex = (latitude: number, longitude: number) => {
    if (!map) return;

    map.flyTo({
      // These options control the ending camera position: centered at
      // the target, at zoom level 13, and north up.
      center: { lat: latitude, lng: longitude },
      zoom: 13,
      bearing: 0,

      // These options control the flight curve, making it move
      // slowly and zoom out almost completely before starting
      // to pan.
      speed: 1, // make the flying slow
      curve: 1, // change the speed at which it zooms out

      // this animation is considered essential with respect to prefers-reduced-motion
      essential: true,
    });
  };

  const handleClickOnHexCoords = (item: PrioritizationFactorsResponse) => {
    dispatch(setSelectedHex(item.hexagon_id));
    zoomToHex(item.latitude, item.longitude);
  };

  const formatLatitude = (latitude: number) => {
    const direction = latitude > 0 ? "N" : "S";
    const lat = latitude.toFixed(2);
    return (
      <span>
        {lat}&deg;{direction}
      </span>
    );
  };

  const formatLongitude = (longitude: number) => {
    const direction = longitude > 0 ? "E" : "W";
    const lon = longitude.toFixed(2);
    return (
      <span>
        {lon}&deg;{direction}
      </span>
    );
  };

  const clickableListItem = (item: PrioritizationFactorsResponse) => (
    <StyledLi
      key={item.hexagon_id}
      onClick={() => handleClickOnHexCoords(item)}
      style={selectedHex === item.hexagon_id ? { fontWeight: "bold" } : {}}
    >
      {formatLatitude(item.latitude)}, {formatLongitude(item.longitude)}
    </StyledLi>
  );

  const highList = (
    <ol className="b3">
      {data?.high.slice(0, 5).map((item) => clickableListItem(item))}
    </ol>
  );
  const mediumList = (
    <ol className="b3">
      {data?.medium.slice(0, 5).map((item) => clickableListItem(item))}
    </ol>
  );
  const lowList = (
    <ol className="b3">
      {data?.low.slice(0, 5).map((item) => clickableListItem(item))}
    </ol>
  );

  return (
    <LayerInfoSidebarContent>
      <LayerInfoSidebarHeader>
        <h3>Run Prioritization</h3>
        <LayerInfoSidebarDivider />
      </LayerInfoSidebarHeader>
      <LayerInfoSidebarBody>
        <TabsContainer>
          <Tab
            id={RunPrioritizationTabs.EVChargers}
            selected={tabIndex === RunPrioritizationTabs.EVChargers}
            onClick={() => {
              handleSelectTab(RunPrioritizationTabs.EVChargers);
            }}
          >
            EV Chargers
          </Tab>
          <Tab
            disabled
            id={RunPrioritizationTabs.Buildings}
            selected={tabIndex === RunPrioritizationTabs.Buildings}
            onClick={() => {
              handleSelectTab(RunPrioritizationTabs.Buildings);
            }}
          >
            Buildings
          </Tab>
        </TabsContainer>
        {tabIndex === RunPrioritizationTabs.EVChargers && (
          <EVChargersTabContents>
            <h4>Prioritized Charger Locations</h4>
            <CollapsiblePanel
              title="High"
              icon={<HexagonIcon color="#460073" />}
            >
              {highList}
            </CollapsiblePanel>
            <CollapsiblePanel
              title="Medium"
              icon={<HexagonIcon color="#A055F5" />}
            >
              {mediumList}
            </CollapsiblePanel>
            <CollapsiblePanel
              title="Low"
              icon={<HexagonIcon color="#DCAFFF" />}
            >
              {lowList}
            </CollapsiblePanel>
          </EVChargersTabContents>
        )}
      </LayerInfoSidebarBody>
    </LayerInfoSidebarContent>
  );
};
