import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import * as geocodingTools from "@esri/arcgis-rest-geocoding";
import * as arcgisRest from "@esri/arcgis-rest-request";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useDebounce } from "../../utils/hooks";
import { MdSearch } from "react-icons/md";
import { useMap } from "react-map-gl";

declare const ARCGIS_ACCESS_TOKEN: string;

const GeocodeContainer = styled("div")`
  z-index: 2;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
`;
const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.background.base};
  }
`;

interface ISuggestion {
  text: string;
  magicKey: string;
  isCollection: boolean;
}

export const GeocoderControl = () => {
  const mapRef = useMap();
  const [value, setValue] = useState<ISuggestion | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [suggestedOptions, setSuggestedOptions] = useState<ISuggestion[]>([]);
  const searchQuery = useDebounce(inputValue, 500);
  const authentication = arcgisRest.ApiKeyManager.fromKey(ARCGIS_ACCESS_TOKEN);

  useEffect(() => {
    if (inputValue.length === 0) {
      setSuggestedOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    if (!value) return;

    querySpecificLocation();
  }, [value]);

  useEffect(() => {
    const query = searchQuery.toString();
    if (query.length > 1) {
      querySuggestedLocations(query);
    }
  }, [searchQuery]);

  const querySuggestedLocations = async (query: string) => {
    const suggestedLocations = await geocodingTools.suggest(query, {
      authentication: authentication,
    });
    setSuggestedOptions(suggestedLocations.suggestions);
  };

  const querySpecificLocation = async () => {
    if (!value) return;

    const queriedAddress = await geocodingTools.geocode({
      authentication: authentication,
      address: value?.text,
      magicKey: value?.magicKey,
    });
    const featureBbox = queriedAddress.candidates[0].extent;
    if (featureBbox) {
      mapRef.current?.fitBounds([
        [featureBbox.xmin, featureBbox.ymax],
        [featureBbox.xmax, featureBbox.ymin],
      ]);
    }
  };

  return (
    <GeocodeContainer className="maplibregl-ctrl">
      <Autocomplete
        autoHighlight
        autoSelect
        filterOptions={(x) => x}
        options={suggestedOptions}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          } else {
            return option.text;
          }
        }}
        onChange={(_, newValue: ISuggestion | null) => setValue(newValue)}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        isOptionEqualToValue={(option, value) => option.text === value.text}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            sx={{ width: 300 }}
            placeholder="Search City, State, County"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </GeocodeContainer>
  );
};
