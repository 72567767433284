import axios from "../../../utils/axiosConfig";
import { PrioritizationFactorsKeys } from "./RunPrioritization";

export type IBody = {
  [k in PrioritizationFactorsKeys]: number;
};
interface PrioritizationFactorsBuckets {
  high: PrioritizationFactorsResponse[];
  medium: PrioritizationFactorsResponse[];
  low: PrioritizationFactorsResponse[];
}
export type PrioritizationFactorsResponse = {
  ev_prioritization_bucket: "low" | "medium" | "high";
  ev_prioritization_score: number;
  hexagon_id: string;
  latitude: number;
  longitude: number;
};
export const editPrioritizationFactorsQuery = (
  prioritizationFactors: IBody | null,
) => ({
  queryKey: ["prioritizationFactor", prioritizationFactors],
  queryFn: async (): Promise<PrioritizationFactorsBuckets | null> => {
    if (!prioritizationFactors) return null;
    const url = "/dt_base/get_ev_prioritization";
    const presigned = await axios.post(url, prioritizationFactors);
    const res = await axios.get(presigned.data);

    const data = res.data as PrioritizationFactorsResponse[];
    const high = data
      .filter((e) => e.ev_prioritization_bucket === "high")
      .sort((a, b) => b.ev_prioritization_score - a.ev_prioritization_score);
    const medium = data
      .filter((e) => e.ev_prioritization_bucket === "medium")
      .sort((a, b) => b.ev_prioritization_score - a.ev_prioritization_score);
    const low = data
      .filter((e) => e.ev_prioritization_bucket === "low")
      .sort((a, b) => b.ev_prioritization_score - a.ev_prioritization_score);

    return {
      high: high,
      medium: medium,
      low: low,
    };
  },
});
