import React from "react";
import { MdClose, MdMinimize, MdMoreVert } from "react-icons/md";
import { styled } from "@mui/material/styles";

interface ChatbotPlaceholderProps {
  isChatbotOpen: boolean;
  onClose?: () => void;
}

const ChatbotContainer = styled("div")`
  position: fixed;
  bottom: 0;
  right: 32px;
  width: 341px;
  height: 48px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.background.contrast};
  color: ${(props) => props.theme.colors.text.contrast};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  z-index: 2;

  h4 {
    margin: 0;
    color: ${(props) => props.theme.colors.text.contrast};
  }
`;
const Actions = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ChatbotPlaceholder = (props: ChatbotPlaceholderProps) => {
  return (
    <>
      {props.isChatbotOpen && (
        <ChatbotContainer>
          <h4>Digital Impact Advisor</h4>
          <Actions>
            <MdMoreVert />
            <MdMinimize />
            <MdClose onClick={props.onClose} />
          </Actions>
        </ChatbotContainer>
      )}
    </>
  );
};
