import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const transmissionLinesLayerMetadata: LayerMetadata = {
  layerName: "Transmission Lines",
  sourceID: "transmission-lines-layer-source",
  layerID: "transmission-lines-layer",
  dataset: "electric_power_transmission_lines",
  toggleDotColor: "#BA55D3",
  description:
    "This dataset displays the location of electric power transmission lines.",
  fieldsToDisplay: {
    NAICS_DESC: "Industry Classification Description",
    OWNER: "Administrator",
    SOURCE: "Source",
    STATUS: "Status",
    TYPE: "Transmission Line Type",
    VOLTAGE: "Voltage",
    VOLT_CLASS: "Voltage Class",
  },
  theme: [DataSetTheme.EnergyInfrastructure],
};
export const TransmissionLinesLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, transmissionLinesLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, transmissionLinesLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", transmissionLinesLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.ID);
      }
    });
    map.on("mouseleave", transmissionLinesLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: transmissionLinesLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${transmissionLinesLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: transmissionLinesLayerMetadata.layerID,
    source: transmissionLinesLayerMetadata.sourceID,
    "source-layer": transmissionLinesLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Line),
    type: "line",
    paint: {
      "line-color": transmissionLinesLayerMetadata.toggleDotColor,
      "line-width": [
        "case",
        [
          "any",
          ["==", ["get", "ID"], hoveredFeatureID],
          [
            "in",
            ["get", "ID"],
            ["literal", selectedFeatures.map((f) => f.properties.ID)],
          ],
        ],
        3,
        1,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
TransmissionLinesLayer.metadata = transmissionLinesLayerMetadata;
