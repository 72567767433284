import React from "react";
import { useSelector } from "react-redux";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";
import {
  CheckboxList,
  CustomTooltip,
} from "@accenture/energy-transition-ui-library";
import {
  addActiveLayer,
  removeActiveLayer,
  selectIsLayerActive,
} from "../../../redux/mapSlice";
import { RootState, useAppDispatch } from "../../../redux/store";

interface LayerToggleProps {
  metadata: LayerMetadata;
}

export const LayerToggle = ({ metadata }: LayerToggleProps) => {
  const dispatch = useAppDispatch();
  const isLayerActive = useSelector((state: RootState) =>
    selectIsLayerActive(state, metadata.layerID),
  );

  const onToggleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isLayerActive) {
      dispatch(removeActiveLayer(e.target.name));
    } else {
      dispatch(addActiveLayer(e.target.name));
    }
  };

  return (
    <CustomTooltip title={metadata.description ?? metadata.layerName}>
      <CheckboxList.Item
        onChange={(e) => onToggleChecked(e)}
        name={metadata.layerID}
        checked={isLayerActive}
        icon={metadata.toggleIcon}
        dotColor={metadata.toggleDotColor}
      >
        {metadata.layerName}
      </CheckboxList.Item>
    </CustomTooltip>
  );
};
