import { Component } from "react";
import { IconType } from "react-icons/lib";
import { DataSetTheme } from "./IDataSetTheme";

export interface LayerMetadata {
  layerName: string;
  sourceID: string;
  layerID: string;
  dataset: string;
  clusterLayerID?: string;
  description?: string;
  layerImages?: {
    [key: string]: string;
  };
  toggleIcon?: IconType;
  toggleDotColor?: string;
  fieldsToDisplay?: { [key: string]: string };
  theme?: DataSetTheme[];
}

export interface LayerComponent extends Partial<Component> {
  metadata: LayerMetadata;
}

export interface SelectedFeature {
  layerID: string;
  layerName: string;
  properties: { [key: string]: unknown };
}

export enum LayerGeometryType {
  Fill = "fill",
  Point = "point",
  Line = "line",
  Circle = "circle"
}
