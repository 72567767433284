import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSelectedFeatures } from "../redux/mapSlice";
import { DropdownDivider } from "@accenture/energy-transition-ui-library";
import React from "react";
import { selectRunPrioritization } from "../redux/workspaceSlice";
import { SelectedDataAttributes } from "./SelectedDataAttributes";
import { RunPrioritizationTabs } from "./RunPrioritizationTabs";

const StyledAside = styled("aside")`
  z-index: 2;
  position: absolute;
  right: 0;
  height: 100%;
  width: 400px;
  background: ${(props) => props.theme.colors.background.base};
  border-left: 2px solid ${(props) => props.theme.colors.separators.major};
  display: flex;
  flex-direction: column;
  & > * {
    flex-basis: 50%;
    flex-grow: 1;
  }
  h3 {
    padding: 16px;
    margin: 0;
  }
`;
export const LayerInfoSidebarHeader = styled("div")`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.colors.background.base};
`;
export const LayerInfoSidebarDivider = styled(DropdownDivider)`
  margin: 0;
  height: 2px;
`;
export const LayerInfoSidebarContent = styled("div")`
  overflow-y: scroll;
`;
export const LayerInfoSidebarBody = styled("div")`
  padding: 8px 16px;
`;

export const LayerInfoSidebar = () => {
  const features = useSelector(selectSelectedFeatures);
  const isRunPrioritizationOn = useSelector(selectRunPrioritization);
  const showSidebar = features.length > 0 || isRunPrioritizationOn;
  return (
    <>
      {showSidebar && (
        <StyledAside>
          {isRunPrioritizationOn && <RunPrioritizationTabs />}
          {features.length > 0 && <SelectedDataAttributes />}
        </StyledAside>
      )}
    </>
  );
};
