import axios from "../utils/axiosConfig";
import { getDatasetInfoByLayerIDs } from "../utils/utils";
import * as layersModule from "../Map/layers";
import {
  IWorkspace,
  LayerMetadata,
} from "@accenture/energy-transition-interfaces";

export interface CreateWorkspaceQueryProps {
  userID: string;
  workspaceName: string;
  layerIDs?: string[];
}
export const createWorkspaceQuery = async ({
  userID,
  workspaceName,
  layerIDs = [],
}: CreateWorkspaceQueryProps) => {
  const layers = Object.values({ ...layersModule });
  const metadata = layers.map((layer) => layer.metadata);
  const datasetInfo = getDatasetInfoByLayerIDs(layerIDs, metadata);
  const url = "/dt_base/create_workspace";
  const res = await axios.post(url, {
    user_id: userID,
    workspace_name: workspaceName,
    datasets: datasetInfo.datasets,
    dataset_layers: datasetInfo.dataset_layers,
  });
  return res.data;
};

export interface EditWorkspaceQueryProps {
  workspaceID: string;
  layerIDs?: string[];
  metadata: LayerMetadata[];
}
export const editWorkspaceQuery = async ({
  workspaceID,
  layerIDs = [],
  metadata,
}: EditWorkspaceQueryProps) => {
  const datasetInfo = getDatasetInfoByLayerIDs(layerIDs, metadata);
  const url = "/dt_base/edit_workspace";
  const res = await axios.post(url, {
    workspace_globalid: workspaceID,
    datasets: datasetInfo.datasets,
    dataset_layers: datasetInfo.dataset_layers,
  });
  return res.data;
};

interface DeleteWorkspaceProps {
  workspaceID: string;
}
export const deleteWorkspaceQuery = async ({
  workspaceID,
}: DeleteWorkspaceProps) => {
  const url = "/dt_base/delete_workspace";
  const res = await axios.post(url, { workspace_globalid: workspaceID });
  return res.data;
};

export const getWorkspaceByGlobalIDQuery = (workspaceID: string) => ({
  queryKey: ["workspaceByID", workspaceID],
  queryFn: async (): Promise<IWorkspace> => {
    console.log("getWorkspaceByGlobalIDQuery", { workspaceID });
    const res = await axios.post("/dt_base/get_workspace_by_globalid", {
      workspace_globalids: workspaceID,
    });
    return res.data[0] as IWorkspace;
  },
});

export const workspaceListQuery = (userID: string) => ({
  queryKey: ["workspaceList"],
  queryFn: async () => {
    const res = await axios.post("/dt_base/get_userid_workspaces", {
      userids: userID,
    });
    return res.data;
  },
});
