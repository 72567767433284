import React, { ButtonHTMLAttributes } from "react";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { lighten } from "polished";
import { Button, ButtonType } from "../../atoms/Button/Button";

const Menu = styled("menu")`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0px;
  margin: 0px;
  align-items: center;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  width: 226px;
  color: ${(props) => props.theme.colors.text.major};
  text-decoration: none;
  padding: 8px 16px;
  margin: 0 0 8px 0;
  border-radius: 1.5em;
  white-space: nowrap;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;

  &:hover {
    background-color: ${(props) =>
      lighten(
        props.theme.colors.hoverMix / 4,
        props.theme.colors.background.sidebar,
      )};
  }
`;

export const UploadItemLabel = styled("label")`
  pointer-events: none;
  display: flex;
  width: 226px;
  height: 24px;
  justify-content: space-between;
  svg {
    margin-left: 10px;
  }
`;

export const MenuListLink = styled(StyledNavLink)`
  &.active {
    color: white;
    background-color: #a100ff;
  }
`;

export const MenuListDivider = styled("p")`
  background-color: ${(props) => props.theme.colors.separators.major};
  margin: 24px 0px 20px;
  width: 322px;
  height: 0.15em;
`;

export const StyledUploadButton = styled("button")`
  width: 258px;
  padding: 8px 50px 8px 16px;
  background-color: transparent;
  margin: 0 0 8px 0;
  border-radius: 1.5em;
  border-color: transparent;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      lighten(
        props.theme.colors.hoverMix / 4,
        props.theme.colors.background.sidebar,
      )};
  }
`;

const StyledButton = styled(Button)`
  &:active {
    color: ${(props) => props.theme.colors.text.major};
    background-color: ${(props) => props.theme.colors.button.primary};
  }
`;

export function MenuListButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <MenuListLink {...props} as={StyledButton} buttonType={ButtonType.Nav} />
  );
}

export function MenuList(
  props: React.MenuHTMLAttributes<HTMLMenuElement>,
): React.ReactElement {
  return <Menu {...props} />;
}

MenuList.Link = MenuListLink;
MenuList.Button = MenuListButton;
MenuList.Divider = MenuListDivider;
