import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { MapProvider } from "react-map-gl/maplibre";
import { HashRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RouterScrollToTop from "../utils/RouterScrollToTop";
import { SidebarContainer } from "../Sidebar/SidebarContainer";
import {
  GlobalStyle,
  Header,
  Logout,
  Sidebar,
  Footer,
  AuthProvider,
} from "@accenture/energy-transition-ui-library";
import { LandingPage } from "../LandingPage/LandingPage";
import { WorkspacesPage } from "../Workspaces/WorkspacesPage";
import { Map } from "../Map/Map";
import { WorkspaceMetrics } from "../Workspaces/WorkspaceMetrics";
import { DashboardPage } from "../DashboardPage/DashboardPage";
import { DataCatalog } from "../dataCatalog/DataCatalog";
import AccountSettings from "../AcoountSettings/AccountSettings";
import { selectIsDarkModeOn } from "../redux/appSlice";
import { ColorModeContext } from "./ProviderWrapper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: false,
    },
  },
});

export enum SidebarOptions {
  Workspace = "workspaceID",
  Cluster = "clusterID",
}

export function App(): React.ReactElement {
  const colorMode = useContext(ColorModeContext);
  const isDarkModeOn = useSelector(selectIsDarkModeOn);

  useEffect(() => {
    colorMode.toggleColorMode(isDarkModeOn);
  }, [isDarkModeOn]);

  return (
    <HashRouter>
      <GlobalStyle />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <MapProvider>
            <Footer />
            <RouterScrollToTop />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/workspaces" element={<WorkspacesPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route
                path="/account-settings/:userEmail"
                element={<AccountSettings />}
              />
              <Route
                path="/workspaces/:workspaceID/*"
                element={<Sidebar.Layout sidebar={<SidebarContainer />} />}
              >
                <Route
                  path="map"
                  element={
                    <>
                      <WorkspaceMetrics />
                      <Map />
                    </>
                  }
                />
                <Route path="dataCatalog" element={<DataCatalog />} />
                <Route path="other" element={<>PLACEHOLDER</>} />
              </Route>
            </Routes>
            <Header />
            {/* <ReactQueryDevtools /> */}
          </MapProvider>
        </QueryClientProvider>
      </AuthProvider>
    </HashRouter>
  );
}
