import React from "react";

export const AccentureLogoFooter = () => {
  return (
    <svg
      className="dark-mode-compatible"
      width="96"
      height="23"
      viewBox="0 0 96 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7705 6.38734L61.2347 4.52415L55.7712 2.60795V0L65.1294 3.45937V5.58827L55.7705 9.04765V6.38734Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 19.9586C0.5 21.9814 2.12724 22.8859 4.10322 22.8859V22.8865C5.26665 22.9402 6.42794 22.4611 7.12472 21.5024V22.6738H10.0898V15.9678C10.0898 13.5195 8.1145 12.4547 5.55608 12.4547C2.99981 12.4547 1.081 13.5195 0.848745 15.7551H3.75519V15.7008C3.81379 14.9567 4.56916 14.4246 5.38171 14.477C6.54443 14.477 7.00895 15.0627 7.00895 16.0738V16.5529H5.67257C2.82473 16.5529 0.5 17.4049 0.5 19.8525V19.9586ZM7.00966 18.4159V19.3734C7.00966 20.3845 6.0792 20.9702 5.03296 20.9702C3.98744 20.9702 3.52293 20.4912 3.52293 19.7988V19.6921C3.52293 18.7876 4.27759 18.4159 5.78834 18.4159H7.00966Z"
        fill="white"
      />
      <path
        d="M17.0061 22.8858C13.9831 22.8858 11.7749 21.1829 11.7749 17.7765V17.6699C11.7749 14.2642 14.0996 12.454 17.0061 12.454C19.5052 12.454 21.539 13.6254 21.7713 16.2334H18.8663C18.6912 15.2753 18.1102 14.6365 17.064 14.6365C15.7848 14.6365 14.8557 15.5946 14.8557 17.5639V17.8832C14.8557 19.9054 15.669 20.8105 17.064 20.8105C18.1102 20.8105 18.8649 20.1168 19.0399 19.0004H21.8299C21.6555 21.2889 19.9697 22.8851 17.0061 22.8851V22.8858Z"
        fill="white"
      />
      <path
        d="M28.3972 22.8858C25.3743 22.8858 23.166 21.1829 23.166 17.7765V17.6699C23.166 14.2642 25.4907 12.454 28.3972 12.454C30.8963 12.454 32.9302 13.6254 33.1624 16.2334H30.2574C30.0823 15.2753 29.5013 14.6365 28.4551 14.6365C27.1759 14.6365 26.2468 15.5946 26.2468 17.5639V17.8832C26.2468 19.9054 27.0601 20.8105 28.4551 20.8105C29.5013 20.8105 30.256 20.1168 30.4311 19.0004H33.221C33.0466 21.2889 31.3608 22.8851 28.3972 22.8851V22.8858Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5581 17.8296C34.5581 21.1829 36.7077 22.8858 39.8464 22.8858L39.8486 22.8851C42.6964 22.8851 44.4402 21.4486 44.7882 19.5324H41.9403C41.7074 20.3838 40.8362 20.9696 39.905 20.8629C38.6273 20.8629 37.7554 20.1711 37.6396 18.361H44.7868V17.1372C44.7868 13.7845 42.5192 12.401 39.7893 12.401C36.8235 12.401 34.5581 14.2635 34.5581 17.6169V17.8296ZM37.6961 16.4986H37.6953L37.696 16.4992C37.6961 16.499 37.6961 16.4988 37.6961 16.4986ZM37.6961 16.4986H41.8224C41.7652 15.0091 41.0091 14.3703 39.7885 14.3703C38.8003 14.4227 37.9285 14.9023 37.6961 16.4986Z"
        fill="white"
      />
      <path
        d="M46.6467 12.6673H49.6696V14.1575C50.3085 13.0397 51.587 12.401 52.982 12.454C54.958 12.454 56.2944 13.5188 56.2944 15.967V22.6731H53.2143V16.3937C53.2143 15.2223 52.6911 14.6896 51.587 14.6896C50.5987 14.6372 49.7275 15.3283 49.6689 16.2864V22.6731H46.646L46.6467 12.6673Z"
        fill="white"
      />
      <path
        d="M61.9897 9.63358V12.6676H64.0821V14.7421H61.9904V19.4803C61.9904 20.225 62.3391 20.5444 63.0952 20.5444C63.4433 20.5444 63.792 20.4921 64.1407 20.3847V22.5666C63.559 22.7263 62.978 22.78 62.397 22.78C60.0137 22.78 58.9096 21.7689 58.9096 19.746V14.6898H57.6304V12.6139H58.9096V10.7514L61.9897 9.63358V9.63358Z"
        fill="white"
      />
      <path
        d="M75.5903 22.673H72.5102V21.1828C71.9285 22.3006 70.65 22.9393 69.3136 22.8856C67.3376 22.8856 65.8848 21.8215 65.8848 19.4263V12.6672H68.9649V19.0545C68.9649 20.2247 69.4887 20.7561 70.5342 20.7561C71.5226 20.8104 72.3944 20.065 72.4523 19.1606V12.6679H75.5324V22.6743L75.5903 22.673Z"
        fill="white"
      />
      <path
        d="M77.7986 12.6675H80.8215V14.5301C81.286 13.2526 82.681 12.4542 84.0753 12.5602V15.3285C81.9835 15.3285 80.8208 15.9142 80.8208 17.564V22.7263H77.7979V12.6675H77.7986Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1797 17.8296C85.1797 21.1829 87.3308 22.8858 90.468 22.8858L90.4687 22.8851C93.3166 22.8851 95.0017 21.4486 95.4091 19.5324H92.5605C92.3275 20.3838 91.4557 20.9696 90.5266 20.8629C89.2481 20.8629 88.3763 20.1711 88.2598 18.361H95.4083V17.1372C95.4083 13.7845 93.1408 12.401 90.4109 12.401C87.4451 12.401 85.1797 14.2635 85.1797 17.6169V17.8296ZM88.2606 16.4986H88.2598L88.2605 16.4992C88.2606 16.499 88.2606 16.4988 88.2606 16.4986ZM88.2606 16.4986H92.3861C92.329 15.0091 91.5729 14.3703 90.3523 14.3703C89.4234 14.4227 88.493 14.9023 88.2606 16.4986Z"
        fill="white"
      />
    </svg>
  );
};
