import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const airToxicsIndexLayerMetadata: LayerMetadata = {
  layerName: "Air Toxics Index",
  sourceID: "air-toxics-index-layer-source",
  layerID: "air-toxics-index-layer",
  dataset: "environmental_justice_screening",
  toggleDotColor: "#B22222",
  description:
    "This dataset displays the air toxics respiratory hazard index. The index is the sum of hazard indices for air toxics with reference concentrations based on respiratory endpoints. Each hazard index is the ratio of exposure concentration in the air to the health-based reference concentration set by EPA.",
  theme: [DataSetTheme.Environment],
};

export const AirToxicsIndexLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, airToxicsIndexLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, airToxicsIndexLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", airToxicsIndexLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.ID);
      }
    });
    map.on("mouseleave", airToxicsIndexLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: airToxicsIndexLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${airToxicsIndexLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: airToxicsIndexLayerMetadata.layerID,
    source: airToxicsIndexLayerMetadata.sourceID,
    "source-layer": airToxicsIndexLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "ID"], hoveredFeatureID],
          [
            "in",
            ["get", "ID"],
            ["literal", selectedFeatures.map((f) => f.properties.ID)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": airToxicsIndexLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "RESP"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
AirToxicsIndexLayer.metadata = airToxicsIndexLayerMetadata;
