import React from "react";
import { styled } from "@mui/material/styles";
import { Link, LinkProps } from "react-router-dom";
import { Button, ButtonType } from "../../atoms/Button/Button";
import { mix } from "polished";

const Aside = styled("aside")<{
  orientation: "left" | "right";
  inset: string;
  position?: "relative" | "absolute";
}>`
  position: ${(props) => props.position ?? "relative"};
  float: ${(props) => props.orientation};
  z-index: 1;
  margin: ${(props) => props.inset};

  > menu {
    ${(props) => props.orientation}: 0;
  }
`;

const Menu = styled("menu")`
  display: block;
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid ${(props) => props.theme.colors.separators.minor};
  background-color: ${(props) => props.theme.colors.background.base};
  border-radius: 0.3em;
  box-shadow: ${(props) => props.theme.styles.shadows.popup};
  min-width: 10em;
  overflow: hidden;
  z-index: 1;
  top: 48px;
`;

const MenuItem = styled("li")``;

const MenuItemLink = styled("a")`
  display: block;
  padding: 0.8em 1em;
  color: ${(props) => props.theme.colors.text.major};
  text-decoration: none;
  border: 0;
  background-color: transparent;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;

  &:hover {
    background-color: ${(props) =>
      mix(
        props.theme.colors.hoverMix,
        props.theme.colors.background.sidebar,
        props.theme.colors.background.base,
      )};
  }
`;

export interface IDropdownLinkProps extends LinkProps {}

export function DropdownLink(props: IDropdownLinkProps): React.ReactElement {
  return (
    <MenuItem>
      <MenuItemLink {...props} as={Link} />
    </MenuItem>
  );
}

export interface IDropdownButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export function DropdownButton(
  props: IDropdownButtonProps,
): React.ReactElement {
  return (
    <MenuItem>
      <MenuItemLink {...props} as="button" />
    </MenuItem>
  );
}

export const DropdownDivider = styled("li")`
  display: block;
  background-color: ${(props) => props.theme.colors.separators.minor};
  margin: 0.2em 0;
  height: 1px;
`;

export interface IDropdownProps {
  title: React.ReactNode;
  orientation?: "left" | "right";
  className?: string;
  inset?: string;
  onBlur?: () => void;
  position?: "relative" | "absolute";
}

export class Dropdown extends React.Component<
  React.PropsWithChildren<IDropdownProps>,
  { isOpen: boolean }
> {
  static Link = DropdownLink;
  static Button = DropdownButton;
  static Divider = DropdownDivider;

  state = { isOpen: false };

  close() {
    this.setState({ isOpen: false });
  }

  closeDropdown(e: React.FocusEvent<HTMLButtonElement, Element>) {
    if (e.type === "blur" && e.relatedTarget === null) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { orientation, inset, className, title, children, position } =
      this.props;
    const { isOpen } = this.state;

    return (
      <Aside
        orientation={orientation ?? "left"}
        inset={inset ?? "0"}
        position={position}
      >
        <Button
          buttonType={ButtonType.Nav}
          className={`${className} ${isOpen ? " hover" : ""}`}
          onClick={() => this.setState({ isOpen: !isOpen })}
          onBlur={(e) => this.closeDropdown(e)}
        >
          {title}
        </Button>
        {isOpen ? <Menu>{children}</Menu> : null}
      </Aside>
    );
  }
}
