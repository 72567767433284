import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const waterAndWasteBurdenLayerMetadata: LayerMetadata = {
  layerName: "Water & Waste Burden",
  sourceID: "water-and-waste-burden-layer-source",
  layerID: "water-and-waste-burden-layer",
  dataset: "cejst",
  toggleDotColor: "#FF77FF",
  description:
    "This dataset highlights disadvantaged communities with water and wastewater burdens. These disadvantaged communities are in census tracts that are at or above the 90th percentile for underground storage tanks and releases or wastewater discharge and are at or above the 65th percentile for low income.",
  fieldsToDisplay: {
    "Low median household income as a percent of area median income (percentile)":
      "Low median household income as a percent of area median income (percentile)",
    "Leaky underground storage tanks (percentile)":
      "Leaky underground storage tanks (percentile)",
    "Wastewater discharge (percentile)": "Wastewater discharge (percentile)",
  },
  theme: [DataSetTheme.Environment],
};

export const WaterAndWasteBurdenLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, waterAndWasteBurdenLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, waterAndWasteBurdenLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", waterAndWasteBurdenLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", waterAndWasteBurdenLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: waterAndWasteBurdenLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${waterAndWasteBurdenLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: waterAndWasteBurdenLayerMetadata.layerID,
    source: waterAndWasteBurdenLayerMetadata.sourceID,
    "source-layer": waterAndWasteBurdenLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": waterAndWasteBurdenLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Water Factor (Definition N)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
WaterAndWasteBurdenLayer.metadata = waterAndWasteBurdenLayerMetadata;
