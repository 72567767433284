import React, { useCallback, useEffect, useState } from "react";
import { CheckboxList } from "@accenture/energy-transition-ui-library";
import { DataSetTheme } from "@accenture/energy-transition-interfaces";
import {
  addActiveLayer,
  removeActiveLayer,
  selectActiveLayers,
  selectMetadata,
} from "../../../redux/mapSlice";
import { useSelector } from "react-redux";
import { mapThemeToLayerIDs } from "../../../utils/utils";
import { useAppDispatch } from "../../../redux/store";

export const ThemeToggles = () => {
  const dispatch = useAppDispatch();
  const metadata = useSelector(selectMetadata);
  const activeLayers = useSelector(selectActiveLayers);
  const [activeThemes, setActiveThemes] = useState<DataSetTheme[]>([]);

  useEffect(() => {
    if (!metadata) return;
    // Automatically toggle on/off themes based on selected layers
    let active: DataSetTheme[] = [];
    Object.values(DataSetTheme).forEach((theme) => {
      const layerIDs = mapThemeToLayerIDs(theme, metadata);
      if (
        layerIDs.every((id) => activeLayers.includes(id)) &&
        !active.includes(theme)
      ) {
        active.push(theme);
      } else if (active.includes(theme)) {
        active = active.filter((t) => t !== theme);
      }
    });
    setActiveThemes(active);
  }, [activeLayers, metadata]);

  const onThemeToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const theme = event.target.name as DataSetTheme;
      const layerIDs = mapThemeToLayerIDs(theme, metadata);

      if (activeThemes.includes(theme)) {
        layerIDs.forEach((id) => {
          dispatch(removeActiveLayer(id));
        });
      } else {
        layerIDs.forEach((id) => {
          dispatch(addActiveLayer(id));
        });
      }
    },
    [activeThemes],
  );

  return (
    <CheckboxList title={<>Themes</>}>
      {Object.values(DataSetTheme).map((theme) => (
        <CheckboxList.Item
          key={theme}
          onChange={onThemeToggle}
          name={theme}
          checked={activeThemes.includes(theme)}
        >
          {theme}
        </CheckboxList.Item>
      ))}
    </CheckboxList>
  );
};
