import React from "react";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";

const LayoutContainer = styled("div")`
  flex: 1;
  display: flex;
`;

const LayoutMain = styled("main")`
  flex: 1;
  min-width: calc(100% - 322px);
`;

const Aside = styled("aside")`
  max-width: 322px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.background.sidebar};
  box-shadow: ${(props) => props.theme.styles.shadows.header};
  overflow-y: hidden;
`;

export interface ISidebarProps {}

export interface ISidebarLayoutProps {
  sidebar: React.ReactNode;
}

function SidebarLayout(
  props: React.PropsWithChildren<ISidebarLayoutProps>,
): React.ReactElement {
  return (
    <LayoutContainer>
      {props.sidebar}
      <LayoutMain>
        <Outlet />
      </LayoutMain>
    </LayoutContainer>
  );
}

export function Sidebar(
  props: React.PropsWithChildren<ISidebarProps>,
): React.ReactElement {
  return <Aside>{props.children}</Aside>;
}

Sidebar.Layout = SidebarLayout;
