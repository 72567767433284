import React from "react";
import { styled } from "@mui/material/styles";

interface ImageTextRowProps {
  title: string;
  text: string;
  image: string;
  reverse?: boolean;
}

const RowContainer = styled("div")<Partial<ImageTextRowProps>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
`;
const ContentContainer = styled("div")`
  height: 426px;
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled("div")`
  max-width: 584px;
  text-align: left;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;
const ImageContainer = styled("div")<Partial<ImageTextRowProps>>`
  height: 426px;
  width: 49%;
  background: center / cover no-repeat url(${(props) => props.image});
`;
export const ImageTextRow = ({
  title,
  text,
  image,
  reverse = false,
}: ImageTextRowProps) => {
  return (
    <RowContainer reverse={reverse}>
      <ContentContainer>
        <Content>
          <h2>{title}</h2>
          <p className="b2">{text}</p>
        </Content>
      </ContentContainer>
      <ImageContainer image={image} />
    </RowContainer>
  );
};
