import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const buildingLossRateLayerMetadata: LayerMetadata = {
  layerName: "Building Loss Rate",
  sourceID: "building-loss-rate-layer-source",
  layerID: "building-loss-rate-layer",
  dataset: "cejst",
  toggleDotColor: "#FF0000",
  description:
    "This dataset identifies expected building value at risk from losses due to fourteen types of natural hazards that all link to climate change (avalanche, coastal flooding, cold wave, drought, hail, heat wave, hurricane, ice storm, landslide, riverine flooding, strong wind, tornado, wildfire, and winter weather). The rate is calculated by dividing the building value at risk by the total building value.",
  fieldsToDisplay: {
    "Expected building loss rate (Natural Hazards Risk Index) (percentile)":
      "Expected building loss rate (Natural Hazards Risk Index) (percentile)",
  },
  theme: [DataSetTheme.UrbanPlanning],
};

export const BuildingLossRateLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, buildingLossRateLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, buildingLossRateLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", buildingLossRateLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", buildingLossRateLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: buildingLossRateLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${buildingLossRateLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: buildingLossRateLayerMetadata.layerID,
    source: buildingLossRateLayerMetadata.sourceID,
    "source-layer": buildingLossRateLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": buildingLossRateLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        [
          "to-number",
          [
            "get",
            "Expected building loss rate (Natural Hazards Risk Index) (percentile)",
          ],
        ],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
BuildingLossRateLayer.metadata = buildingLossRateLayerMetadata;
