import React from "react";
import { styled } from "@mui/material";
import { ExpandIcon } from "../../atoms/Assets/ToolMetricsIcons/ExpandIcon";
import { CollapseIcon } from "../../atoms/Assets/ToolMetricsIcons/CollapseIcon";

const Item = styled("div")<{
  width: string;
  height: string;
}>`
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  border: 8px solid ${(props) => props.theme.colors.separators.major};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.background.base};
`;

const Icon = styled("div")`
  cursor: pointer;
`;

const Header = styled("div")`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  h4 {
    margin: 0px;
  }
`;

const Content = styled("div")<{
  align?: string;
  padding?: string;
}>`
  display: flex;
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: space-evenly;
  height: 100%;
  padding: ${(props) => (props.padding ? props.padding : "0px")};
`;

const Footer = styled("div")`
  display: flex;
  padding: 0px 8px 4px 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  p {
    margin: 0px;
  }
`;

interface IMetricCardProps {
  title: string;
  source: string;
  width: string;
  height: string;
  minHeight?: string;
  align?: string;
  children?: JSX.Element | JSX.Element[];
  padding?: string;
  expandDisabled?: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
  isModalOpen?: boolean;
}

export const MetricItemContent = (props: IMetricCardProps) => {
  return (
    <Item width={props.width} height={props.height}>
      <Header>
        <h4>{props.title}</h4>
        {props.expandDisabled ? null : props.isModalOpen ? (
          <Icon onClick={props.handleClose}>
            <CollapseIcon />
          </Icon>
        ) : (
          <Icon onClick={props.handleOpen}>
            <ExpandIcon />
          </Icon>
        )}
      </Header>
      <Content align={props.align} padding={props.padding}>
        {props.children}
      </Content>
      <Footer>
        <p className="b4">Source: {props.source}</p>
      </Footer>
    </Item>
  );
};

export default MetricItemContent;
