import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const healthBurdenLayerMetadata: LayerMetadata = {
  layerName: "Health Burden",
  sourceID: "health-burden-layer-source",
  layerID: "health-burden-layer",
  dataset: "cejst",
  toggleDotColor: "#0000CD",
  description:
    "This dataset highlights disadvantaged communities with health burdens. These disadvantaged communities are in census tracts that are at or above the 90th percentile for asthma, diabetes, heart disease, or low life expectancy and are at or above the 65th percentile for low income.",
  fieldsToDisplay: {
    "Low median household income as a percent of area median income (percentile)":
      "Low median household income as a percent of area median income (percentile)",
    "Coronary heart disease among adults aged greater than or equal to 18 years (percentile)":
      "Coronary heart disease among adults aged greater than or equal to 18 years (percentile)",
    "Current asthma among adults aged greater than or equal to 18 years (percentile)":
      "Current asthma among adults aged greater than or equal to 18 years (percentile)",
    "Diagnosed diabetes among adults aged greater than or equal to 18 years (percentile)":
      "Diagnosed diabetes among adults aged greater than or equal to 18 years (percentile)",
    "Low life expectancy (percentile)": "Low life expectancy (percentile)",
  },
  theme: [DataSetTheme.Environment],
};

export const HealthBurdenLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, healthBurdenLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, healthBurdenLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", healthBurdenLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", healthBurdenLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: healthBurdenLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${healthBurdenLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: healthBurdenLayerMetadata.layerID,
    source: healthBurdenLayerMetadata.sourceID,
    "source-layer": healthBurdenLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": healthBurdenLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Health Factor (Definition N)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HealthBurdenLayer.metadata = healthBurdenLayerMetadata;
