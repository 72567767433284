import React from "react";
import { ITags } from "@accenture/energy-transition-interfaces";
import { Section, StyledChip, TagsProps } from "./TagsList";

function ThemeTags(props: TagsProps): React.ReactElement {
  const { tagsFilter, onChangeFilter } = props;

  return (
    <Section gap="8px">
      <h4>Theme</h4>
      <div>
        <StyledChip
          label={ITags.Climate}
          onClick={() => onChangeFilter(ITags.Climate)}
          selected={tagsFilter?.includes(ITags.Climate)}
        />
        <StyledChip
          label={ITags.Environment}
          onClick={() => onChangeFilter(ITags.Environment)}
          selected={tagsFilter?.includes(ITags.Environment)}
        />
        <StyledChip
          label={ITags.Transportation}
          onClick={() => onChangeFilter(ITags.Transportation)}
          selected={tagsFilter?.includes(ITags.Transportation)}
        />
        <StyledChip
          label={ITags.EnergyInfrastructure}
          onClick={() => onChangeFilter(ITags.EnergyInfrastructure)}
          selected={tagsFilter?.includes(ITags.EnergyInfrastructure)}
        />
        <StyledChip
          label={ITags.UrbanPlanning}
          onClick={() => onChangeFilter(ITags.UrbanPlanning)}
          selected={tagsFilter?.includes(ITags.UrbanPlanning)}
        />
      </div>
    </Section>
  );
}

export default ThemeTags;
