export enum MapOverlay {
  Asthma = "Asthma",
  TrafficProximity = "Traffic Proximity",
  Unemployment = "Unemployment",
  HouseholdIncome = "Household Income",
}

export enum TransitOverlay {
  Highways = "Highways",
  Railways = "Railways",
  Ports = "Ports",
  ShippingChannels = "Shipping Channels",
}
