import React from "react";
import { styled } from "@mui/material";
import { SelectedFeature } from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { getCountyName, mapLayerIDToMetadata } from "../utils/utils";
import { selectRunPrioritization } from "../redux/workspaceSlice";
import { selectMetadata, selectSelectedFeatures } from "../redux/mapSlice";
import {
  LayerInfoSidebarBody,
  LayerInfoSidebarContent,
  LayerInfoSidebarDivider,
  LayerInfoSidebarHeader,
} from "./LayerInfoSidebar";
import { HiddenCensusDP03Layer, HiddenCensusDP05Layer } from "./layers";

const manuipulateFeatures = (
  features: SelectedFeature[],
): SelectedFeature[] => {
  const incomeAttr =
    "Estimate!!INCOME AND BENEFITS (IN 2021 INFLATION-ADJUSTED DOLLARS)!!Per capita income (dollars)";
  const populationAttr = "Estimate!!SEX AND AGE!!Total population";

  // Seperate hidden census layers
  const censusTractDP03Features = structuredClone(
    features.filter((f) =>
      f.layerID.includes(HiddenCensusDP03Layer.metadata.layerID),
    ),
  );
  const censusTractDP05Features = structuredClone(
    features.filter((f) =>
      f.layerID.includes(HiddenCensusDP05Layer.metadata.layerID),
    ),
  );

  if (censusTractDP03Features.length > 0) {
    const summedDP03Feature = censusTractDP03Features[0];

    // Sum population in case more than one was clicked on
    const summedIncome = censusTractDP03Features.reduce((acc, cur) => {
      return acc + Number(cur.properties[incomeAttr]);
    }, 0);

    // Sum income in case more than one was clicked
    const summedPopulation = censusTractDP05Features.reduce((acc, cur) => {
      return acc + Number(cur.properties[populationAttr]);
    }, 0);

    // Add properties to summed census feature
    summedDP03Feature.properties[incomeAttr] = summedIncome;
    summedDP03Feature.properties[populationAttr] = summedPopulation;
    summedDP03Feature.properties.countyname = getCountyName(
      String(summedDP03Feature.properties.state),
      String(summedDP03Feature.properties.county),
    );

    // Recombine and sort features
    const combinedFeatures = [
      ...features.filter((f) => !f.layerID.includes("hidden-census")),
      summedDP03Feature,
    ].sort((a, b) => (a.layerName > b.layerName ? 1 : -1));
    return combinedFeatures;
  } else {
    return features.filter((f) => !f.layerID.includes("hidden-census"));
  }
};

export const SelectedDataAttributes = () => {
  const selectedFeatures = useSelector(selectSelectedFeatures);
  const features = manuipulateFeatures(selectedFeatures);

  const isRunPrioritizationOn = useSelector(selectRunPrioritization);
  return (
    <LayerInfoSidebarContent>
      <LayerInfoSidebarHeader>
        {isRunPrioritizationOn && <LayerInfoSidebarDivider />}
        <h3>Data Attributes</h3>
        <LayerInfoSidebarDivider />
      </LayerInfoSidebarHeader>
      <LayerInfoSidebarBody>
        {features.map((feature, index) => (
          <FeatureInfoBoxContainer key={feature.layerID + index}>
            <h4>{feature.layerName}</h4>
            <FeatureInfoBox feature={feature} />
          </FeatureInfoBoxContainer>
        ))}
      </LayerInfoSidebarBody>
    </LayerInfoSidebarContent>
  );
};

const FeatureInfoBoxContainer = styled("div")`
  h4 {
    margin: 8px 0 4px;
  }
`;
const FeatureBox = styled("div")`
  max-height: 212px;
  padding: 12px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.background.sidebar};
  overflow-y: scroll;

  .b3 {
    line-height: normal;
  }
`;
const AttributeContainer = styled("div")`
  h4 {
    margin-top: 12px;
    margin-bottom: 2px;
  }
  &:first-of-type {
    h4 {
      margin-top: 0;
    }
  }
  p {
    margin: 0;
  }
`;

const renamedFuelTypes = {
  BD: "Biodiesel (B20 and above)",
  CNG: "Compressed Natural Gas",
  ELEC: "Electric",
  E85: "Ethanol",
  HY: "Hydrogen",
  LNG: "Liquefied Natural Gas",
  LPG: "Propane",
  RD: "Renewable Diesel (R20 and above)",
};

const DollarAttributeKeys = [
  "Estimate!!INCOME AND BENEFITS (IN 2021 INFLATION-ADJUSTED DOLLARS)!!Per capita income (dollars)",
];
const ThousandsAttributeKeys = ["Estimate!!SEX AND AGE!!Total population"];
const OtherFuelTypesAttributeKey = "fuel_type_code";

const FeatureInfoBox = ({ feature }: { feature: SelectedFeature }) => {
  const metadata = useSelector(selectMetadata);
  const fieldsToUse = mapLayerIDToMetadata(
    feature.layerID,
    metadata,
  ).fieldsToDisplay;
  const allowedFields =
    fieldsToUse && Object.keys(fieldsToUse).length > 0
      ? Object.keys(fieldsToUse)
      : Object.keys(feature.properties);
  const filtered = Object.keys(feature.properties)
    .filter((key) => allowedFields.includes(key))
    .sort()
    .reduce((obj, key) => {
      obj[key] = feature.properties[key];
      return obj;
    }, {} as { [key: string]: unknown });

  const getValue = (value: unknown, key: string): string | number => {
    if (typeof value !== "string" && typeof value !== "number") return "-";
    if (key.includes("percentile")) return convertToPercentage(value);
    if (DollarAttributeKeys.includes(key))
      return "$" + convertToThousands(value);
    if (ThousandsAttributeKeys.includes(key)) return convertToThousands(value);
    if (key === OtherFuelTypesAttributeKey) return renameFuelType(value);
    return value;
  };

  const convertToThousands = (value: string | number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const convertToPercentage = (value: string | number) => {
    return `${(100 * +value).toFixed(0)}%`;
  };

  const renameFuelType = (fuelType: string | number) => {
    let valueToDisplay = fuelType;
    Object.entries(renamedFuelTypes).map(([key, renamedValue]) => {
      if (key === fuelType) valueToDisplay = renamedValue;
    });
    return valueToDisplay;
  };

  return (
    <FeatureBox>
      {Object.entries(filtered).map(([key, value]) => (
        <AttributeContainer key={`${key}-${value}`}>
          <h4>{fieldsToUse && fieldsToUse[key] ? fieldsToUse[key] : key}</h4>
          <p className="b3">{getValue(value, key)}</p>
        </AttributeContainer>
      ))}
    </FeatureBox>
  );
};
