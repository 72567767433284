import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const floodingLayerMetadata: LayerMetadata = {
  layerName: "Flooding",
  sourceID: "flooding-layer-source",
  layerID: "flooding-layer-layer",
  dataset: "cejst_nri_census_tracts",
  toggleDotColor: "#0070B8",
  description:
    "This dataset highlights census tracts based on the risk index for coastal and riverine flooding. These are two of the 18 natural hazards identified in the National Risk Index. In the National Risk Index, natural hazards are represented in terms of expected annual loss, which incorporate data for exposure, annualized frequency, and historic loss ratio.",
  fieldsToDisplay: {
    "Riverine Flooding - Hazard Type Risk Index Rating": "Riverine Flood Risk",
    "Riverine Flooding - Hazard Type Risk Index Score":
      "Riverine Flooding Risk Score",
    "Coastal Flooding - Hazard Type Risk Index Rating": "Coastal Flood Risk",
    "Coastal Flooding - Hazard Type Risk Index Score":
      "Coastal Flooding Risk Score",
  },
  theme: [DataSetTheme.Climate],
};

export const FloodingLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, floodingLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, floodingLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", floodingLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0].properties !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(
          e?.features[0]?.properties["National Risk Index ID"],
        );
      }
    });
    map.on("mouseleave", floodingLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: floodingLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${floodingLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: floodingLayerMetadata.layerID,
    source: floodingLayerMetadata.sourceID,
    "source-layer": floodingLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "National Risk Index ID"], hoveredFeatureID],
          [
            "in",
            ["get", "National Risk Index ID"],
            [
              "literal",
              selectedFeatures.map(
                (f) => f.properties["National Risk Index ID"],
              ),
            ],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": floodingLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        [
          "to-number",
          ["get", "Riverine Flooding - Hazard Type Risk Index Score"],
        ],
        0,
        0,
        100,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
FloodingLayer.metadata = floodingLayerMetadata;
