import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Instance } from "@popperjs/core";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement={props.placement ? props.placement : "right-start"}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "black",
    boxShadow: "0 0.15em 1em 0 #0006;",
    borderRadius: 8,
    width: 272,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Graphik",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: -0.28,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

interface ICustomTooltipProps {
  children: React.ReactElement;
  title: string;
  vitualElement?: boolean;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
}

export const CustomTooltip = (props: ICustomTooltipProps) => {
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);
  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const popperProps = {
    popperRef,
    anchorEl: {
      getBoundingClientRect: () => {
        return new DOMRect(300, positionRef.current.y);
      },
    },
  };

  return (
    <StyledTooltip
      title={props.title}
      placement={props.placement}
      PopperProps={props.vitualElement ? popperProps : {}}
    >
      <Box sx={{ width: "100%" }} ref={areaRef} onMouseMove={handleMouseMove}>
        {props.children}
      </Box>
    </StyledTooltip>
  );
};

export default CustomTooltip;
