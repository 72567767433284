import React, { useCallback, useEffect } from "react";
import ThemeTags from "./ThemeTags";
import DataSourceTags from "./DataSourceTags";
import DataUpdateFrequencyTags from "./DataUpdateFrequencyTags";
import UploadSourceTags from "./UploadSourceTags";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { ITags } from "@accenture/energy-transition-interfaces";
import FilterAll from "./FilterAll";
import { useAppDispatch } from "../../../redux/store";
import {
  setSelectedTags,
  selectSelectedTags,
} from "../../../redux/dataCatalogSlice";
import { useSelector } from "react-redux";

export interface TagsProps {
  tagsFilter: ITags[] | null;
  onChangeFilter: (id: ITags | "All Items") => void;
}

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  h3,
  h4 {
    margin: 0px;
  }
`;

export const Section = styled("div")<{ gap: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  gap: ${(props) => props.gap};
`;

export const StyledChip = styled(Chip)<{ selected?: boolean }>`
  margin: 0px 8px 8px 0px;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  color: ${(props) =>
    props.selected
      ? props.theme.colors.text.major
      : props.theme.colors.text.contrast};
  background-color: ${(props) =>
    props.selected ? "transparent" : props.theme.colors.button.inactive};
  border: ${(props) =>
    props.selected ? "1.5px solid #B3B2B5" : "1.5px solid transparent"};
`;

export function TagsList(): React.ReactElement {
  const tagsFilter = useSelector(selectSelectedTags);
  const totalTags = Object.entries(ITags);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedTags(Object.values(ITags)));
  }, []);

  const onChangeFilter = useCallback(
    (id: ITags | "All Items") => {
      const item = id as ITags;
      const tagsFilterSet = new Set(tagsFilter);
      if ((item as unknown) === "All Items") {
        const areAllTagsSelected =
          tagsFilter && tagsFilter.length === totalTags.length;
        areAllTagsSelected
          ? dispatch(setSelectedTags([]))
          : dispatch(setSelectedTags(Object.values(ITags)));
        return;
      }
      tagsFilterSet.has(item)
        ? tagsFilterSet.delete(item)
        : tagsFilterSet.add(item);
      return dispatch(setSelectedTags(Array.from(tagsFilterSet)));
    },
    [tagsFilter],
  );

  useEffect(() => {
    onChangeFilter("All Items");
  }, []);

  return (
    <Content>
      <h3>Results</h3>
      <FilterAll tagsFilter={tagsFilter} onChangeFilter={onChangeFilter} />
      <Section gap="4px">
        <ThemeTags tagsFilter={tagsFilter} onChangeFilter={onChangeFilter} />
        <DataSourceTags
          tagsFilter={tagsFilter}
          onChangeFilter={onChangeFilter}
        />
        <DataUpdateFrequencyTags
          tagsFilter={tagsFilter}
          onChangeFilter={onChangeFilter}
        />
        <UploadSourceTags
          tagsFilter={tagsFilter}
          onChangeFilter={onChangeFilter}
        />
      </Section>
    </Content>
  );
}
