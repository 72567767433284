import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";

const ColumnContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;

  h2 {
    margin-top: 24px;
  }
  p {
    margin: 0;
  }
`;
const IconContainer = styled("div")`
  background: ${(props) => props.theme.colors.background.sidebar};
  font-size: 100px;
  padding: 50px;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  margin: auto;
`;

interface IconTextColumnProps {
  icon: ReactNode;
  title: string;
  text: string;
}
export const IconTextColumn = (props: IconTextColumnProps) => {
  const { icon, title, text } = props;
  return (
    <ColumnContainer>
      <IconContainer>{icon}</IconContainer>
      <h2>{title}</h2>
      <p className="b2">{text}</p>
    </ColumnContainer>
  );
};
