import React from "react";
import { styled } from "@mui/material/styles";
import { ResourcesCarousel } from "../ResourcesCarousel/ResourcesCarousel";

const ResourcesWrapper = styled("div")``;

interface IResource {
  title: string;
  sourceLink: string;
}
const resourcesData: IResource[] = [
  {
    title: "World Economic Forum",
    sourceLink:
      "https://www.accenture.com/us-en/insights/strategy/wef-industries",
  },
  {
    title: "System Value Framework",
    sourceLink: "https://www.weforum.org/projects/system-value",
  },
  {
    title: "Accenture Energy Transition Services",
    sourceLink:
      "https://www.accenture.com/us-en/services/utilities/energy-transition-utilities",
  },
  {
    title: "Intergovernmental Panel on Climate Change",
    sourceLink: "https://www.ipcc.ch/",
  },
];

export function ResourcesContainer() {
  return (
    <ResourcesWrapper>
      <ResourcesCarousel sources={resourcesData} />
    </ResourcesWrapper>
  );
}
