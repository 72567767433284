import React from "react";
import Slider from "@mui/material/Slider";
import { PanelSubTitle } from "../CollapsiblePanel/CollapsiblePanel";
import { styled } from "@mui/material";

export const SliderContainer = styled("div")<{ width?: string }>`
  display: flex;
  width: ${(props) => props.width ?? "calc(100% - 24px)"};
  padding: 0px 12px;
`;

export const Content = styled("div")`
  width: 100%;
  gap: "4px";
`;

const StyledSlider = styled(Slider)({
  "& .MuiSlider-thumb": {
    zIndex: "99",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 6px rgba(161, 0, 255, 0.16)`,
    },
    "&:after": {
      display: "none",
    },
  },
});

interface CustomSliderProps {
  title: string;
  value: number;
  width?: string;
  onChange: (e: Event) => void;
}

export const CustomSlider = (props: CustomSliderProps) => {
  return (
    <Content>
      <PanelSubTitle>
        <p className="b3">{props.title}</p>
        <h4>{props.value}</h4>
      </PanelSubTitle>
      <SliderContainer width={props.width}>
        <StyledSlider
          size="small"
          value={props.value}
          disableSwap
          onChange={props.onChange}
        />
      </SliderContainer>
    </Content>
  );
};

export default CustomSlider;
