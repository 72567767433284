import React, { useState } from "react";
import { styled, useTheme } from "@mui/material";
import {
  AddIcon,
  LargeButton,
  CustomModal,
  Form,
} from "@accenture/energy-transition-ui-library";
import AreaChartImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/ChartsImages/AreaChart.png";
import BarChartImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/ChartsImages/BarChart.png";
import LineChartImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/ChartsImages/LineChart.png";
import PieChartImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/ChartsImages/PieChart.png";
import RadarChartImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/ChartsImages/RadarChart.png";
import ScatterChartImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/ChartsImages/ScatterChart.png";

const ChartsLayout = styled("div")`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 146.5px);
  grid-template-columns: repeat(3, 248px);
  grid-gap: 24px;
`;

const Chart = styled("div")<{
  selected: boolean;
}>`
  display: flex;
  width: 248px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.theme.colors.button.primary}`
      : `2px dashed ${props.theme.colors.text.minor}`};
  h4 {
    margin: 0px;
  }
`;

const chartImages = [
  { id: "area-chart-image", src: AreaChartImage, title: "Area Chart" },
  { id: "bar-chart-image", src: BarChartImage, title: "Bar Chart" },
  { id: "line-chart-image", src: LineChartImage, title: "Line Chart" },
  { id: "pie-chart-image", src: PieChartImage, title: "Pie Chart" },
  { id: "radar-chart-image", src: RadarChartImage, title: "Radar Chart" },
  { id: "scatter-chart-image", src: ScatterChartImage, title: "Scatter Chart" },
];

const DashboardTab = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [chartType, setChartType] = useState<string | null>(null);

  const onCancel = () => {
    setOpenModal(false);
  };

  return (
    <>
      <LargeButton
        title="Add Widget"
        icon={<AddIcon />}
        width="432px"
        height="80px"
        backgroundColor={theme.colors.background.base}
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}
      />
      <CustomModal isModalOpen={openModal} onCancel={onCancel}>
        <h2>Add Widget</h2>
        <p>Select the chart you wish to view data in.</p>
        <Form.Spacer gap="24px" />
        <ChartsLayout>
          {chartImages.map((image) => (
            <Chart
              key={image.id}
              onClick={() => setChartType(image.title)}
              selected={chartType === image.title ? true : false}
            >
              <img src={image.src} height={"74px"} />
              <h4>{image.title}</h4>
            </Chart>
          ))}
        </ChartsLayout>
        <Form.Divider />
        <Form.ButtonContainer>
          <Form.StyledButton
            type="button"
            id="data-clasification"
            disabled={!chartType}
            onClick={onCancel}
          >
            Continue
          </Form.StyledButton>
        </Form.ButtonContainer>
      </CustomModal>
    </>
  );
};

export default DashboardTab;
