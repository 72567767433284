import React, { useRef, useState, useMemo } from "react";
import { Form } from "@accenture/energy-transition-ui-library/src/molecules/Form/Form";
import {
  UploadFileIcon,
  ClipIcon,
} from "@accenture/energy-transition-ui-library";
import { styled } from "@mui/material/styles";
import { ModalPages } from "./DataUpload";
import axios from "axios";

export interface IUploadDataset {
  file?: File | null;
  setFile: (files: File) => void;
  handleModalPage: (page: string) => void;
}

export enum FileTypes {
  CSV = "text/csv",
  GeoJson = "application/geo+json",
}

const UploadProgress = styled("div")`
  display: flex;
  height: 72px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 2px solid #cccbce;
  background: var(--white, #fff);
`;

const FileInfo = styled("div")`
  display: flex;
  width: 720px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const ProgressContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const DragAndDropContainer = styled("div")`
  display: flex;
  height: 232px;
  padding: 32px 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 2px dashed ${(props) => props.theme.colors.text.minor};
`;

const DescriptionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const InputContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Subtitle = styled("h4")`
  margin: 0px;
`;

const Size = styled("p")`
  color: ${(props) => props.theme.colors.text.minor};
`;

const TabsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 24px 0px;
`;

const Tab = styled("button")<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  padding: 11px 16px;
  height: 40px;
  align-items: flex-start;
  cursor: pointer;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "bold !important" : 400)};
  line-height: 110%;
  letter-spacing: -0.32px;
  text-transform: capitalize;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 4px solid
    ${(props) =>
      props.selected
        ? props.theme.colors.button.primary
        : props.theme.colors.border.light};
  color: ${(props) => props.theme.colors.text.major};
  background-color: transparent;
  font-weight: normal;

  &:hover {
    border-bottom: 4px solid ${(props) => props.theme.colors.button.secondary};
  }

  &:focus {
    border-bottom: 4px solid ${(props) => props.theme.colors.button.primary};
    font-weight: bold;
  }
`;

export const UploadDatasetPage = (
  props: React.PropsWithChildren<IUploadDataset>,
) => {
  const { file, setFile, handleModalPage } = props;
  const [tabIndex, setTabIndex] = useState<string>("LocalFileTab");
  const inputRef = useRef<HTMLInputElement>(null);
  const [link, setLink] = useState<string | null>(null);
  const validMimeTypes = [FileTypes.CSV, FileTypes.GeoJson];
  const loading = false; //Harcoded variable

  const handleSelectTab = (tab: string) => {
    setTabIndex(tab);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const addFile = (newFile: File) => {
    const re = /(?:\.([^.]+))?$/;
    const fileExt = newFile.name.match(re);
    if (newFile.type === "" && fileExt && fileExt[1] === "geojson") {
      const file = new File([newFile], newFile.name, {
        type: FileTypes.GeoJson,
      });
      return setFile(file);
    }
    setFile(newFile);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    addFile(selectedFile);
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const selectedFile = event.target.files[0];
    addFile(selectedFile);
  };

  const subtitle =
    tabIndex === "LocalFileTab"
      ? "Select a file or drag and drop here"
      : "Select a link or drag and drop here";

  const fileSize = useMemo(
    () =>
      file
        ? +file.size < 1000000
          ? +(file.size / 1000000).toFixed(4)
          : +(file.size / 1000000).toFixed(2)
        : null,
    [file],
  );

  const notValidFile = useMemo(
    () =>
      !file ||
      (fileSize && fileSize > 20) ||
      !validMimeTypes.includes(file.type as FileTypes)
        ? true
        : false,
    [file],
  );

  async function URLtoFile(url: string | null) {
    if (!url) return;

    axios
      .get(url, {
        responseType: "blob",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const file = new File([response.data], response.data.name, {
          type: response.data.type,
        });
        addFile(file);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  return (
    <>
      <h2>Upload Dataset</h2>
      <TabsContainer>
        <Tab
          id="local-tab"
          selected={tabIndex === "LocalFileTab"}
          onClick={() => {
            handleSelectTab("LocalFileTab");
          }}
        >
          Local file
        </Tab>
        <Tab
          selected={tabIndex === "DirectLinkTab"}
          id="link-tab"
          onClick={() => {
            handleSelectTab("DirectLinkTab");
          }}
        >
          Direct link
        </Tab>
      </TabsContainer>
      <DragAndDropContainer onDrop={handleDrop} onDragOver={handleDragOver}>
        <input
          type="file"
          onChange={selectFile}
          hidden
          accept={validMimeTypes.toLocaleString()}
          ref={inputRef}
        />
        <div>
          <UploadFileIcon />
        </div>
        <DescriptionContainer>
          <Subtitle>{subtitle}</Subtitle>
          <p className="b3">File format: Geojson and CSV</p>
          <p className="b3">Maximum size: 20MB</p>
        </DescriptionContainer>
        <InputContainer>
          {tabIndex === "DirectLinkTab" && (
            <Form.Input
              type="url"
              onChange={(e) => {
                setLink(e.target.value);
              }}
              placeholder="Enter link"
            ></Form.Input>
          )}
          <Form.StyledButton
            id="select-file"
            type="button"
            onClick={() =>
              tabIndex === "LocalFileTab"
                ? inputRef.current?.click()
                : URLtoFile(link)
            }
          >
            {tabIndex === "LocalFileTab" ? "SELECT FILE" : "ADD FILE"}
          </Form.StyledButton>
        </InputContainer>
      </DragAndDropContainer>
      <Form.Spacer gap="16px" />

      {file && (
        <UploadProgress>
          <div>
            <ClipIcon />
          </div>
          <ProgressContainer>
            <FileInfo>
              <p className="b3">{file?.name}</p>
              <Size className="b3">{fileSize}MB</Size>
            </FileInfo>
            {/* This part is pending to show the loading bar when we have an end point*/}
            {loading && <div>...</div>}
          </ProgressContainer>
        </UploadProgress>
      )}

      <Form.Divider />
      <Form.ButtonContainer>
        <Form.StyledButton
          id="upload-dataset"
          type="button"
          disabled={notValidFile}
          onClick={() => handleModalPage(ModalPages.UploadDetails)}
        >
          UPLOAD DATA
        </Form.StyledButton>
      </Form.ButtonContainer>
    </>
  );
};

export default UploadDatasetPage;
