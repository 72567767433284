import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { styled } from "@mui/material";
import { IBody } from "./runPrioritizationQueries";
import {
  CustomizedSwitch,
  CollapsiblePanel,
  PanelBox,
  CustomTooltip,
  CustomSlider,
  StyledButton,
} from "@accenture/energy-transition-ui-library";
import {
  setRunPrioritization,
  selectRunPrioritization,
  setPrioritizationFactors,
} from "../../../redux/workspaceSlice";

export const SliderContainer = styled("div")`
  display: flex;
  width: calc(100% - 24px);
  padding: 0px 12px;
`;

const ButtonContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const Subtitle = styled("h4")`
  color: ${(props) => props.theme.colors.text.minor};
`;

export enum PrioritizationFactors {
  DistanceToSubstation = "Distance to Substation",
  EVDemandForecast = "EV Demand Forecast",
  ChargerWithin5Miles = "Charger Within 5 Miles",
  TrafficScore = "Traffic Score",
  AirPollution = "Air Pollution",
  FloodRisk = "Flood Risk",
}

export enum PrioritizationFactorsKeys {
  DistanceToSubstation = "power_plant_weight",
  EVDemandForecast = "ev_demand_weight",
  ChargerWithin5Miles = "ev_proximity_weight",
  TrafficScore = "traffic_score_weight",
  AirPollution = "air_pollution_weight",
  FloodRisk = "flood_risk_weight",
}

interface IPrioritizationFactors {
  key: PrioritizationFactorsKeys;
  title: PrioritizationFactors;
  value: number;
}

const prioritizationFactors = [
  {
    key: PrioritizationFactorsKeys.AirPollution,
    title: PrioritizationFactors.AirPollution,
    value: 0,
  },
  {
    key: PrioritizationFactorsKeys.ChargerWithin5Miles,
    title: PrioritizationFactors.ChargerWithin5Miles,
    value: 0,
  },
  {
    key: PrioritizationFactorsKeys.DistanceToSubstation,
    title: PrioritizationFactors.DistanceToSubstation,
    value: 0,
  },
  {
    key: PrioritizationFactorsKeys.EVDemandForecast,
    title: PrioritizationFactors.EVDemandForecast,
    value: 0,
  },
  {
    key: PrioritizationFactorsKeys.FloodRisk,
    title: PrioritizationFactors.FloodRisk,
    value: 0,
  },
  {
    key: PrioritizationFactorsKeys.TrafficScore,
    title: PrioritizationFactors.TrafficScore,
    value: 0,
  },
];

export const RunPrioritization = () => {
  const dispatch = useAppDispatch();
  const isPriorizationOn = useSelector(selectRunPrioritization);
  const [disableButton, setDisableButton] = useState(true);
  const [factors, setFactors] = useState<IPrioritizationFactors[]>(
    prioritizationFactors,
  );

  const handleSwitch = () => {
    dispatch(setRunPrioritization(!isPriorizationOn));
  };

  const handleChange = (newValue: number, title: PrioritizationFactors) => {
    setFactors((prevState) =>
      prevState.map((factor) => {
        if (factor.title === title) {
          return { ...factor, value: newValue };
        }
        return { ...factor };
      }),
    );
  };

  const handleSubmit = () => {
    setDisableButton(true);
    const prioritizationFactors = Object.fromEntries(
      factors.map((f): [PrioritizationFactorsKeys, number] => [f.key, f.value]),
    );
    dispatch(setPrioritizationFactors(prioritizationFactors as IBody));
  };

  useEffect(() => {
    const disable = !factors
      .map((factor) => factor.value)
      .some((value) => value > 1);
    setDisableButton(disable);
  }, [factors]);

  return (
    <PanelBox>
      <CustomizedSwitch
        label="Run Prioritization"
        checked={isPriorizationOn}
        width="100%"
        titleSize="large"
        onChange={handleSwitch}
      />
      {isPriorizationOn && (
        <CollapsiblePanel title="EV Charger Placement">
          <PanelBox gap="8px">
            <PanelBox gap="0px">
              <Subtitle>Prioritization Factors</Subtitle>
            </PanelBox>
            <CustomTooltip
              title="The range for the prioritization of factors is 0-100. 0 = not a priority and 100 = high priority."
              placement="right"
              vitualElement
            >
              <PanelBox>
                {factors.map((factor, key) => (
                  <CustomSlider
                    key={key}
                    title={factor.title}
                    value={factor.value}
                    onChange={(e) =>
                      handleChange(
                        +(e.target as HTMLInputElement).value,
                        factor.title,
                      )
                    }
                  />
                ))}
              </PanelBox>
            </CustomTooltip>
            <ButtonContainer>
              <StyledButton
                type="button"
                id="sliders"
                disabled={disableButton}
                onClick={handleSubmit}
              >
                Generate
              </StyledButton>
            </ButtonContainer>
          </PanelBox>
        </CollapsiblePanel>
      )}
    </PanelBox>
  );
};
