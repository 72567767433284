import React from "react";

const AccentureLogo = () => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 40L36 25.2221V14.7779L0 0V10.4443L23.2785 20L0 29.5557V40Z"
        fill="#A100FF"
      />
    </svg>
  );
};

export default AccentureLogo;
