import React, { useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Dropdown } from "@accenture/energy-transition-ui-library";
import { MdMoreVert } from "react-icons/md";
import { WorkspaceMetadata } from "../../../Workspaces/modals/WorkspaceMetadata";
import { DeleteWorkspace } from "../../../Workspaces/modals/DeleteWorkspace";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getWorkspaceByGlobalIDQuery } from "../../../Workspaces/workspaceQueries";

const InputBar = styled("input")`
  width: 185px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.button.inactive};
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 0px 0px 11px;
`;

const WorkspaceCardWrapper = styled("div")`
  position: relative;
  width: 258px;
  height: 48px;
  margin: 12px 0px 32px 0px;
`;

const WorkspaceButton = styled(Dropdown.Button)``;
const WorkspaceDropdown = styled(Dropdown)`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #a100ff;
  background-color: ${(props) => props.theme.colors.background.base};
  padding: 10px;
`;

export function SidebarDropdown(): React.ReactElement {
  const theme = useTheme();
  const { workspaceID } = useParams();
  const workspaceQuery = useQuery(
    getWorkspaceByGlobalIDQuery(workspaceID || ""),
  );
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const dropdownRef = useRef<Dropdown>(null);

  return (
    <>
      <WorkspaceMetadata
        workspace={workspaceQuery.data}
        isModalOpen={isRenameModalOpen}
        onCancel={() => {
          setRenameModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
        onSave={() => {
          setRenameModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
        action="rename"
      />
      <DeleteWorkspace
        workspace={workspaceQuery.data}
        isModalOpen={isDeleteModalOpen}
        onCancel={() => {
          setDeleteModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
        onConfirm={() => {
          setDeleteModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
      />

      <WorkspaceCardWrapper>
        <InputBar value={workspaceQuery?.data?.workspace_name || ""} disabled />
        <WorkspaceDropdown
          className="dropdown"
          ref={dropdownRef}
          title={<MdMoreVert size={"24px"} color={theme.colors.text.minor} />}
          orientation="right"
        >
          <WorkspaceButton
            onClick={(e) => {
              setRenameModalOpen(true);
              e.preventDefault();
            }}
          >
            Rename
          </WorkspaceButton>
          <WorkspaceButton
            onClick={(e) => {
              setDeleteModalOpen(true);
              e.preventDefault();
            }}
          >
            Delete
          </WorkspaceButton>
        </WorkspaceDropdown>
      </WorkspaceCardWrapper>
    </>
  );
}
