export enum Workspace {
  All = "All",
  None = "None",
}

export enum DataSourceTag {
  External = "External",
  Internal = "Internal",
}

export enum SaveToDataCatalog {
  Yes = "Yes",
  No = "No",
}
