import React, { useMemo, useEffect } from "react";
import { LayerToggleGroup } from "./LayerToggleGroup";
import { ThemeToggles } from "./ThemeToggles";
import { CheckboxList, Spinner } from "@accenture/energy-transition-ui-library";
import { useQuery } from "@tanstack/react-query";
import { getLayerMetadata } from "../../../utils/utils";
import { uploadedDatasetsQuery } from "../../../dataCatalog/dataCatalogQueries";
import { useParams } from "react-router-dom";
import { getWorkspaceByGlobalIDQuery } from "../../../Workspaces/workspaceQueries";
import * as layersModule from "../../../Map/layers";
import { useAppDispatch } from "../../../redux/store";
import { setMetadata } from "../../../redux/mapSlice";
import {
  IWorkspace,
  LayerMetadata,
} from "@accenture/energy-transition-interfaces";

export const LayerTogglesContainer = () => {
  const dispatch = useAppDispatch();
  const { workspaceID } = useParams();
  const layers = useMemo(() => Object.values({ ...layersModule }), []);
  const { data: uploadedDatasets, isLoading: uploadedDatasetsLoading } =
    useQuery({
      queryKey: uploadedDatasetsQuery().queryKey,
      queryFn: uploadedDatasetsQuery().queryFn,
    });
  const { data: workspaceQuery, isLoading: workspaceLoading } =
    useQuery<IWorkspace>({
      queryKey: getWorkspaceByGlobalIDQuery(workspaceID || "").queryKey,
      queryFn: getWorkspaceByGlobalIDQuery(workspaceID || "").queryFn,
    });

  const filteredUploadedLayers = useMemo(
    () =>
    uploadedDatasets?.filter(
      (layer) =>
        layer.workspace_name === workspaceQuery?.workspace_name || layer.workspace_name === "All",
    ),
    [uploadedDatasets, workspaceQuery],
  );
  const uploadedMetadataLayer = useMemo(
    () =>
      filteredUploadedLayers
        ? filteredUploadedLayers.map<LayerMetadata>((layer) =>
            getLayerMetadata(layer),
          )
        : null,
    [filteredUploadedLayers],
  );
  const globalMetadata = useMemo(
    () => layers.map((layer) => layer.metadata),
    [layers],
  );

  useEffect(() => {
    let metadata = globalMetadata;
    if (uploadedMetadataLayer) {
      metadata = globalMetadata.concat(uploadedMetadataLayer);
    }
    metadata.forEach((m) => {
      dispatch(setMetadata(JSON.stringify(m)));
    });
  }, [
    uploadedMetadataLayer,
    globalMetadata,
  ]);

  return (
    <>
      {uploadedDatasetsLoading ||
        (workspaceLoading && (
          <Spinner isLoading={uploadedDatasetsLoading || workspaceLoading} />
        ))}
      <ThemeToggles />
      <CheckboxList title="Data">
        <LayerToggleGroup
          subtitle="Areas & Lines"
          layerMetadata={[
            layersModule.ActiveEarthquakesLayer.metadata,
            layersModule.AirToxicsIndexLayer.metadata,
            layersModule.BuildingLossRateLayer.metadata,
            layersModule.ClimateChangeBurdenLayer.metadata,
            layersModule.EndangeredSpeciesLayer.metadata,
            layersModule.EnergyBurdenLayer.metadata,
            layersModule.FloodingLayer.metadata,
            layersModule.HealthBurdenLayer.metadata,
            layersModule.HeatWavesLayer.metadata,
            layersModule.HighwayLayer.metadata,
            layersModule.HomesPriorTo1960Layer.metadata,
            layersModule.HousingBurden.metadata,
            layersModule.PipelinesLayer.metadata,
            layersModule.PollutionBurdenLayer.metadata,
            layersModule.TrafficProximityLayer.metadata,
            layersModule.TransmissionLinesLayer.metadata,
            layersModule.TransportationBurdenLayer.metadata,
            layersModule.TribalLandsLayer.metadata,
            layersModule.WaterAndWasteBurdenLayer.metadata,
            layersModule.WildfiresLayer.metadata,
          ]}
        />
        <LayerToggleGroup
          marginTop="4px"
          subtitle="Points"
          layerMetadata={[
            layersModule.EVChargersLayer.metadata,
            layersModule.OtherFuelTypesLayer.metadata,
            layersModule.PowerPlantsLayer.metadata,
          ]}
        />
        {uploadedMetadataLayer && uploadedMetadataLayer.length > 0 && (
          <>
            <LayerToggleGroup
              marginTop="4px"
              subtitle="Uploads"
              layerMetadata={uploadedMetadataLayer}
            />
          </>
        )}
      </CheckboxList>
    </>
  );
};
