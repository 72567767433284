import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const climateChangeBurdenLayerMetadata: LayerMetadata = {
  layerName: "Climate Change Burden",
  sourceID: "climate-change-burden-layer-source",
  layerID: "climate-change-burden-layer",
  dataset: "cejst",
  toggleDotColor: "#E86100",
  description:
    "This dataset highlights disadvantaged communities with climate change burdens. These disadvantaged communities are in census tracts that are at or above the 90th percentile for expected agriculture loss rate, expected building loss rate, expected population loss rate, projected flood risk, or projected wildfire risk and are at or above the 65th percentile for low income.",
  fieldsToDisplay: {
    "Low median household income as a percent of area median income (percentile)":
      "Low median household income as a percent of area median income (percentile)",
    "Expected agricultural loss rate (Natural Hazards Risk Index) (percentile)":
      "Expected agricultural loss rate (Natural Hazards Risk Index) (percentile)",
    "Expected building loss rate (Natural Hazards Risk Index) (percentile)":
      "Expected building loss rate (Natural Hazards Risk Index) (percentile)",
    "Expected population loss rate (Natural Hazards Risk Index) (percentile)":
      "Expected population loss rate (Natural Hazards Risk Index) (percentile)",
    "Share of properties at risk of fire in 30 years (percentile)":
      "Share of properties at risk of fire in 30 years (percentile)",
    "Share of properties at risk of flood in 30 years (percentile)":
      "Share of properties at risk of flood in 30 years (percentile)",
  },
  theme: [DataSetTheme.Climate],
};

export const ClimateChangeBurdenLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, climateChangeBurdenLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, climateChangeBurdenLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", climateChangeBurdenLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", climateChangeBurdenLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: climateChangeBurdenLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${climateChangeBurdenLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: climateChangeBurdenLayerMetadata.layerID,
    source: climateChangeBurdenLayerMetadata.sourceID,
    "source-layer": climateChangeBurdenLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": climateChangeBurdenLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Climate Factor (Definition N)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
ClimateChangeBurdenLayer.metadata = climateChangeBurdenLayerMetadata;
