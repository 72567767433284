import React, { useEffect } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { useAppDispatch } from "../../redux/store";
import {
  setMetricAverageIncome,
  setMetricUnemployment,
} from "../../redux/mapSlice";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";
import { getBeforeIdByLayerType, getUniqueFeatures } from "../../utils/utils";
import { LayerGeometryType } from "@accenture/energy-transition-interfaces/src/ILayer";
import { HiddenCensusDP05Layer } from "./HiddenCensusDP05Layer";

declare const AWS_API_URI: string;

const hiddenCensusDP03LayerMetadata: LayerMetadata = {
  layerName: "General Demographic Info",
  sourceID: "hidden-census-dp03-layer-source",
  layerID: "hidden-census-dp03-layer",
  dataset: "census_tract_dp03",
  fieldsToDisplay: {
    countyname: "County",
    census_tract: "Census Tract ID",
    "Estimate!!SEX AND AGE!!Total population": "Population",
    "Estimate!!INCOME AND BENEFITS (IN 2021 INFLATION-ADJUSTED DOLLARS)!!Per capita income (dollars)":
      "Income Per Capita",
  },
};
export const HiddenCensusDP03Layer = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;
    map.on("moveend", () => {
      updateMetrics();
    });
  }, []);

  const updateMetrics = () => {
    if (!map || !map.getLayer(hiddenCensusDP03LayerMetadata.layerID)) return;

    const dp05Features = map.queryRenderedFeatures(undefined, {
      layers: [HiddenCensusDP05Layer.metadata.layerID],
    });
    const uniqueDP05Features = getUniqueFeatures(dp05Features, "tract").map(
      (f) => {
        if (!f.properties) return;
        let populationAttr = 0;
        if (populationAttr !== undefined && populationAttr >= 0) {
          populationAttr = Number(
            f.properties["Estimate!!SEX AND AGE!!Total population"],
          );
        }
        return {
          population: populationAttr,
          tract: f.properties.tract,
        };
      },
    );

    const dp03Features = map.queryRenderedFeatures(undefined, {
      layers: [hiddenCensusDP03LayerMetadata.layerID],
    });
    const features = getUniqueFeatures(dp03Features, "tract");

    // Only get data if within the zoom bounds of the dataset (any features exist)
    if (features.length === 0) {
      dispatch(setMetricUnemployment("N/A"));
      dispatch(setMetricAverageIncome("N/A"));
      return;
    }

    // Add population attribute to each feature
    features.forEach((feature) => {
      if (!feature.properties) return;
      const tract = feature.properties.tract;
      const population = uniqueDP05Features.find(
        (f) => f?.tract === tract,
      )?.population;
      feature.properties.population = population || 0;
    });

    // Average Income Calculation (weighted by population)
    const sumPopulation = features
      .map((f) => f.properties && f.properties.population)
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
    const incomeAttribute =
      "Estimate!!INCOME AND BENEFITS (IN 2021 INFLATION-ADJUSTED DOLLARS)!!Per capita income (dollars)";
    const meanIncomeArr = features
      .filter(
        (f) =>
          f.properties &&
          typeof f.properties[incomeAttribute] !== "undefined" &&
          Number(f.properties[incomeAttribute]) >= 0,
      )
      .map(
        (f) =>
          f.properties &&
          Number(f.properties[incomeAttribute]) * f.properties.population,
      );
    const sumMeanIncomes =
      meanIncomeArr.reduce(
        (partialSum, a) => Number(partialSum) + Number(a),
        0,
      ) || 0;
    const meanIncome = Math.round(sumMeanIncomes / sumPopulation);
    dispatch(setMetricAverageIncome(meanIncome));

    // Unemployment Calculation
    const totalInWorkforce = features
      .map(
        (f) =>
          f.properties &&
          f.properties[
            "Estimate!!EMPLOYMENT STATUS!!Population 16 years and over"
          ],
      )
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    const totalUnemployed = features
      .map(
        (f) =>
          f.properties &&
          f.properties[
            "Estimate!!EMPLOYMENT STATUS!!Population 16 years and over!!In labor force!!Civilian labor force!!Unemployed"
          ],
      )
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    const unemploymentPercent = totalUnemployed / totalInWorkforce;
    const formattedUnemploymentPercent = Math.round(unemploymentPercent * 100);
    dispatch(setMetricUnemployment(formattedUnemploymentPercent));
  };

  const sourceConfig: SourceProps = {
    id: hiddenCensusDP03LayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${hiddenCensusDP03LayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: hiddenCensusDP03LayerMetadata.layerID,
    source: hiddenCensusDP03LayerMetadata.sourceID,
    "source-layer": hiddenCensusDP03LayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-opacity": 0,
    },
    layout: {
      visibility: "visible",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HiddenCensusDP03Layer.metadata = hiddenCensusDP03LayerMetadata;
