import React from "react";

export const ChevronUpIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1826_40096"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1826_40096)">
        <path
          d="M6.69999 14.6754C6.51665 14.4921 6.42499 14.2587 6.42499 13.9754C6.42499 13.6921 6.51665 13.4587 6.69999 13.2754L11.3 8.67539C11.4 8.57539 11.5083 8.50456 11.625 8.46289C11.7417 8.42122 11.8667 8.40039 12 8.40039C12.1333 8.40039 12.2583 8.42122 12.375 8.46289C12.4917 8.50456 12.6 8.57539 12.7 8.67539L17.325 13.3004C17.5083 13.4837 17.6 13.7087 17.6 13.9754C17.6 14.2421 17.5 14.4754 17.3 14.6754C17.1167 14.8587 16.8833 14.9504 16.6 14.9504C16.3167 14.9504 16.0833 14.8587 15.9 14.6754L12 10.7754L8.07499 14.7004C7.89165 14.8837 7.66665 14.9754 7.39999 14.9754C7.13332 14.9754 6.89999 14.8754 6.69999 14.6754Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ChevronUpIcon;
