import React from "react";
import { styled } from "@mui/material";
import DatasetMetrics from "./Items/DatasetMetrics";
import DatasetUsage from "./Items/DatasetUsage";
import UsageOverTime from "./Items/UsageOverTime";
import UserActivity from "./Items/UserActivity";

const ToolsLayout = styled("div")`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
  padding: 0 0 60px;

  .metrics {
    grid-area: 1 / 1 / 5 / 7;
  }

  .usage {
    grid-area: 1 / 7 / 7 / 13;
  }

  .activity {
    grid-area: 5 / 1 / -1 / 7;
  }

  .overtime {
    grid-area: 7 / 7 / -1 / -1;
  }
`;

const ToolsMetrics = () => {
  return (
    <ToolsLayout>
      <div className="metrics">
        <DatasetMetrics />
      </div>
      <div className="usage">
        <DatasetUsage />
      </div>
      <div className="activity">
        <UserActivity />
      </div>
      <div className="overtime">
        <UsageOverTime />
      </div>
    </ToolsLayout>
  );
};

export default ToolsMetrics;
