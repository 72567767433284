import { Theme, styled } from "@mui/material/styles";
import { mix } from "polished";

export enum ButtonType {
  Default = "Default",
  Primary = "Primary",
  Nav = "Nav",
  Symbol = "Symbol",
  LargeSymbol = "LargeSymbol",
}

const BUTTON_PADDING = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: "0.7em",
    [ButtonType.Primary]: "0.7em",
    [ButtonType.Nav]: "1em",
    [ButtonType.Symbol]: "",
    [ButtonType.LargeSymbol]: "",
  };
  return types[buttonType];
};

const COLOR = (buttonType: ButtonType, theme: Theme) => {
  const types = {
    [ButtonType.Default]: `${theme.colors.text.white}`,
    [ButtonType.Primary]: `${theme.colors.text.white}`,
    [ButtonType.Nav]: `${theme.colors.text.minor}`,
    [ButtonType.Symbol]: `${theme.colors.text.major}`,
    [ButtonType.LargeSymbol]: `${theme.colors.text.contrast}`,
  };
  return types[buttonType];
};

const MARGIN = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: `1em 0 1em 1em`,
    [ButtonType.Primary]: `1em 0 1em 1em`,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: `0`,
    [ButtonType.LargeSymbol]: `0`,
  };
  return types[buttonType];
};

const BORDER_RADIUS = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: `0.3em`,
    [ButtonType.Primary]: `0.3em`,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: `0.3em`,
    [ButtonType.LargeSymbol]: `8px`,
  };
  return types[buttonType];
};

const BACKGROUND_COLOR = (buttonType: ButtonType, theme: Theme) => {
  const types = {
    [ButtonType.Default]: `${theme.colors.button.primary}`,
    [ButtonType.Primary]: `${theme.colors.button.primary}`,
    [ButtonType.Nav]: `transparent`,
    [ButtonType.Symbol]: `${theme.colors.button.secondary}`,
    [ButtonType.LargeSymbol]: `${theme.colors.background.base}`,
  };
  return types[buttonType];
};

const ALIGN_ITEMS = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: `center`,
    [ButtonType.Primary]: `center`,
    [ButtonType.Nav]: `center`,
    [ButtonType.Symbol]: ``,
    [ButtonType.LargeSymbol]: ``,
  };
  return types[buttonType];
};

const WIDTH = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: ``,
    [ButtonType.Primary]: ``,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: `1.5em`,
    [ButtonType.LargeSymbol]: `1.5em`,
  };
  return types[buttonType];
};

const HEIGHT = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: ``,
    [ButtonType.Primary]: ``,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: `1.5em`,
    [ButtonType.LargeSymbol]: `1.5em`,
  };
  return types[buttonType];
};

const DISPLAY = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: ``,
    [ButtonType.Primary]: ``,
    [ButtonType.Nav]: `flex`,
    [ButtonType.Symbol]: `grid`,
    [ButtonType.LargeSymbol]: `grid`,
  };
  return types[buttonType];
};

const TEXT_TRANSFORM = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: `uppercase`,
    [ButtonType.Primary]: `uppercase`,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: ``,
    [ButtonType.LargeSymbol]: ``,
  };
  return types[buttonType];
};

const BORDER_COLOR = (buttonType: ButtonType, theme: Theme) => {
  const types = {
    [ButtonType.Default]: `${theme.colors.button.primary}`,
    [ButtonType.Primary]: `${theme.colors.button.primary}`,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: ``,
    [ButtonType.LargeSymbol]: `${theme.colors.button.primary}`,
  };
  return types[buttonType];
};

const PLACE_CONTENT = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: ``,
    [ButtonType.Primary]: ``,
    [ButtonType.Nav]: ``,
    [ButtonType.Symbol]: `center`,
    [ButtonType.LargeSymbol]: `center`,
  };
  return types[buttonType];
};

const FONT_SIZE = (buttonType: ButtonType, _theme: Theme) => {
  const types = {
    [ButtonType.Default]: `16px`,
    [ButtonType.Primary]: `16px`,
    [ButtonType.Nav]: `1em`,
    [ButtonType.Symbol]: `1em`,
    [ButtonType.LargeSymbol]: `2em`,
  };
  return types[buttonType];
};

const EXTRA_CONTENT = (buttonType: ButtonType, theme: Theme) => {
  const types = {
    [ButtonType.Default]: ``,
    [ButtonType.Primary]: ``,
    [ButtonType.Nav]: `
      border: 0;
        font-family: inherit;
      }
      font-size: inherit;
      text-align: left;
      text-decoration: none;

      &.active {
        color: ${theme.colors.text.major};
        background-color: ${theme.colors.background.sidebar};
      }
    `,
    [ButtonType.Symbol]: ``,
    [ButtonType.LargeSymbol]: `
      border: 2px solid ${BORDER_COLOR(ButtonType.LargeSymbol, theme)};
    `,
  };
  return types[buttonType];
};

export interface ButtonProps {
  buttonType: ButtonType;
}

export const Button = styled("button", {
  shouldForwardProp: (prop) => prop !== "buttonType",
})<ButtonProps>`
  place-content: ${(props) => PLACE_CONTENT(props.buttonType, props.theme)};
  border-color: ${(props) => BORDER_COLOR(props.buttonType, props.theme)};
  text-transform: ${(props) => TEXT_TRANSFORM(props.buttonType, props.theme)};
  display: ${(props) => DISPLAY(props.buttonType, props.theme)};
  height: ${(props) => HEIGHT(props.buttonType, props.theme)};
  width: ${(props) => WIDTH(props.buttonType, props.theme)};
  padding: ${(props) => BUTTON_PADDING(props.buttonType, props.theme)};
  color: ${(props) => COLOR(props.buttonType, props.theme)};
  margin: ${(props) => MARGIN(props.buttonType, props.theme)};
  border-radius: ${(props) => BORDER_RADIUS(props.buttonType, props.theme)};
  background-color: ${(props) =>
    BACKGROUND_COLOR(props.buttonType, props.theme)};
  align-items: ${(props) => ALIGN_ITEMS(props.buttonType, props.theme)};
  font-size: ${(props) => FONT_SIZE(props.buttonType, props.theme)};
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-decoration: none;

  border: 0 solid;
  cursor: pointer;
  &:hover,
  &.hover {
    background-color: ${(props) =>
      mix(
        props.theme.colors.hoverMix,
        props.theme.colors.background.sidebar,
        BACKGROUND_COLOR(props.buttonType, props.theme),
      )};
  }

  ${(props) => EXTRA_CONTENT(props.buttonType, props.theme)}
`;
