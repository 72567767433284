import { FeatureCollection } from "geojson";
import axios from "../utils/axiosConfig";

export const datasetGeojsonQuery = (dataset_name: string) => ({
  queryKey: ["datasetGeojson-" + dataset_name],
  queryFn: async () => {
    const presigned = await axios.post("/dt_base/get_dataset_geojson", {
      dataset: dataset_name,
    });
    const response = await axios.get<FeatureCollection>(presigned.data);
    return response.data;
  },
});

export const pipelinesQuery = () => ({
  queryKey: ["pipelines"],
  queryFn: async () => {
    const presigned1 = await axios.post("/dt_base/get_dataset_geojson", {
      dataset: "crude_oil_pipelines",
    });
    const presigned2 = await axios.post("/dt_base/get_dataset_geojson", {
      dataset: "HGL_pipelines",
    });
    const presigned3 = await axios.post("/dt_base/get_dataset_geojson", {
      dataset: "petroleum_product_pipelines",
    });
    const presigned4 = await axios.post("/dt_base/get_dataset_geojson", {
      dataset: "natural_gas_pipelines",
    });
    const response1 = await axios.get<FeatureCollection>(presigned1.data);
    const response2 = await axios.get<FeatureCollection>(presigned2.data);
    const response3 = await axios.get<FeatureCollection>(presigned3.data);
    const response4 = await axios.get<FeatureCollection>(presigned4.data);
    const combinedFeatures = [
      ...response1.data.features,
      ...response2.data.features,
      ...response3.data.features,
      ...response4.data.features,
    ];
    response1.data.features = combinedFeatures;
    return response1.data;
  },
});
