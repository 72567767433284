import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import mapReducer from "./mapSlice";
import dataCatalogReducer from "./dataCatalogSlice";
import workspaceReducer from "./workspaceSlice";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    app: appReducer,
    map: mapReducer,
    dataCatalog: dataCatalogReducer,
    workspace: workspaceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
