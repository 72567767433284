import React from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import WelcomeBackgroundImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/LandingPageImages/WelcomeBackgroundImage.png";
import OverviewSolarImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/LandingPageImages/OverviewSolarImage.png";
import OverviewEnergyDrawingImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/LandingPageImages/OverviewEnergyDrawingImage.png";
import OverviewPresentationImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/LandingPageImages/OverviewPresentationImage.png";
import PurposeImage from "@accenture/energy-transition-ui-library/src/atoms/Assets/LandingPageImages/PurposeImage.png";
import {
  IconTextColumn,
  ImageTextRow,
  Button,
  ButtonType,
  ChartIcon,
  MagnifyIcon,
  ResourcesIcon,
  NewsCarousel,
} from "@accenture/energy-transition-ui-library";

const LandingPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.colors.background.base};
`;

const WelcomeContainer = styled("div")`
  width: 100%;
  display: block;
  height: 920px;
  background: center / cover no-repeat url(${() => WelcomeBackgroundImage});
`;
const WelcomeContentContainer = styled("div")`
  width: 100%;
  height: 100%;
  max-width: 1800px;
  position: relative;
  margin: 0 auto;
`;
const WelcomeContent = styled("div")`
  max-width: 888px;
  position: absolute;
  padding: 140px 60px;
  bottom: 0;
  left: 0;
  h1 {
    font-size: 64px;
  }
  h1,
  .b1 {
    color: #000;
  }
`;
const TryItOutButton = styled(Button)`
  margin: 40px 0 0;
`;
const OverviewContainer = styled("div")`
  padding: 80px 60px 0;
  width: calc(100% - 120px);
  max-width: 1800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const PurposeContainer = styled("div")`
  width: calc(100% - 120px);
  max-width: 1800px;
  padding: 0 60px;
  margin: 112px auto 0;
`;
const PurposeImg = styled("img")`
  width: 100%;
`;

const HowItWorksContainer = styled("div")`
  max-width: 1800px;
  width: calc(100% - 120px);
  padding: 0 60px;
  margin: 112px auto 0;
`;
const HowItWorksColumnContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const NewsContainer = styled("div")`
  max-width: 1800px;
  width: calc(100% - 120px);
  padding: 0 60px;
  margin: 112px auto 0;
`;

export const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <LandingPageContainer>
      <WelcomeContainer>
        <WelcomeContentContainer>
          <WelcomeContent>
            <h1>Make data driven insights to accelerate decarbonization</h1>
            <p className="b1">
              Consider new data sets and dimensions to inform strategic
              decisions for decarbonization
            </p>
            <TryItOutButton
              onClick={() => navigate("/workspaces")}
              buttonType={ButtonType.Primary}
            >
              Try it out
            </TryItOutButton>
          </WelcomeContent>
        </WelcomeContentContainer>
      </WelcomeContainer>
      <OverviewContainer>
        <ImageTextRow
          title="Democratizing the energy transition"
          text="Considering new data sets and dimensions to inform strategic decisions for decarbonization"
          image={OverviewSolarImage}
        />
        <ImageTextRow
          title="Bring to light new community insights"
          text="Embed equity into how we make decisions as a company and community partner for the energy transition"
          image={OverviewEnergyDrawingImage}
          reverse
        />
        <ImageTextRow
          title="Evangelize data science across the enterprise"
          text="Empower teams with diverse goals and mandates to align on common metrics and data-drive decisions"
          image={OverviewPresentationImage}
        />
      </OverviewContainer>
      <PurposeContainer>
        <h1>The Purpose</h1>
        <p className="b1">
          Increase data-driven decision making capabilities to address climate
          and community challenges across industries.
        </p>
        <PurposeImg src={PurposeImage} />
      </PurposeContainer>
      <HowItWorksContainer>
        <h1>How it Works</h1>
        <HowItWorksColumnContainer>
          <IconTextColumn
            icon={<ChartIcon />}
            title="DATA"
            text="Dynamically consume internal and external sources of data to provide integrated, community context for decarbonization"
          />
          <IconTextColumn
            icon={<MagnifyIcon />}
            title="VISUALIZATION"
            text="View complex and related data sets through a cloud-based, easy to consume visualization to break down information siloes"
          />
          <IconTextColumn
            icon={<ResourcesIcon />}
            title="INSIGHTS"
            text="Leverage cloud-based advanced analytics and data science to unhidden insights and drive decision-making"
          />
        </HowItWorksColumnContainer>
      </HowItWorksContainer>
      <NewsContainer>
        <h1>Recent News</h1>
        <NewsCarousel />
      </NewsContainer>
    </LandingPageContainer>
  );
};
