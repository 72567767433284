import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const StyledSwitch = styled((props: CustomizedSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, checked }) => ({
  width: 51,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 4,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(27px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        border: "2px solid #A100FF",
        backgroundColor: "#A100FF",
        opacity: 1,
      },
    },

    "&.Mui-disabled": {
      transform: "translateX(27px)",
      color: "#B3B2B5",
      "& + .MuiSwitch-track": {
        backgroundColor: "#B3B2B5",
        opacity: 0.16,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: checked ? "#fff" : theme.colors.text.major,
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-input": {
    left: "-200%",
    width: "500%",
  },

  "& .MuiSwitch-track": {
    boxSizing: "border-box",
    opacity: 1,
    border: `2px solid ${theme.colors.text.major}`,
    borderRadius: 26 / 2,
    backgroundColor: "transparent",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Container = styled("div")<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  align-items: flex-start;
  gap: 24px;
  justify-content: space-between;
  h4,
  h3 {
    margin: 0px;
  }
`;

interface CustomizedSwitchProps extends SwitchProps {
  label: string;
  width?: string;
  titleSize?: "large" | "medium";
}

export const CustomizedSwitch = (props: CustomizedSwitchProps) => {
  const { titleSize, ...rest } = props;
  return (
    <Container width={props.width}>
      {titleSize === "large" ? <h3>{props.label}</h3> : <h4>{props.label}</h4>}
      <StyledSwitch {...rest} />
    </Container>
  );
};

export default CustomizedSwitch;
