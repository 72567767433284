import React from "react";
import { styled } from "@mui/material/styles";
import { Portal } from "../../utils/portal";

import {
  Dialogbox,
  DialogboxProps,
} from "../../organisms/ModalDialog/Dialogbox/Dialogbox";

const Overlay = styled("div")<{ isModalOpen: boolean }>`
  visibility: ${(props) => (props.isModalOpen ? `visible` : `hidden`)};
  opacity: ${(props) => (props.isModalOpen ? `1` : `0`)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 99;
`;

export interface ModalProps extends DialogboxProps {
  isModalOpen: boolean;
  children?: JSX.Element | JSX.Element[];
}

export function Modal(props: React.PropsWithChildren<ModalProps>) {
  return (
    <Portal>
      <Overlay isModalOpen={props.isModalOpen}>
        <Dialogbox {...props} />
      </Overlay>
    </Portal>
  );
}
