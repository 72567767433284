import React, { useCallback, useState, useEffect, useMemo } from "react";
import { ModalProps } from "@accenture/energy-transition-ui-library/src/atoms/Modal/Modal";
import { Form } from "@accenture/energy-transition-ui-library/src/molecules/Form/Form";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "@accenture/energy-transition-ui-library/src/molecules/CustomModal/CustomModal";
import { useMsal } from "@azure/msal-react";
import { AxiosError } from "axios";
import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateWorkspaceQueryProps,
  createWorkspaceQuery,
} from "../workspaceQueries";

export function CreateWorkspace(props: ModalProps): React.ReactElement {
  const navigate = useNavigate();
  const [workspaceName, setworkspaceName] = useState<string>();
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation({
    mutationFn: createWorkspaceQuery,
    onSuccess: (workspaceID) => {
      queryClient.invalidateQueries(["workspaceList"]);
      navigate(`/workspaces/${workspaceID}/map`);
    },
    onError: (err: AxiosError, data: CreateWorkspaceQueryProps) => {
      console.log(err);
      if (err.response?.data === "Workspace already exists for this user") {
        setworkspaceName(data.workspaceName);
      }
    },
  });

  return (
    <CustomModal
      isModalOpen={props.isModalOpen}
      isLoading={isLoading}
      onCancel={props.onCancel}
      onSuccess={props.onSuccess}
    >
      <h2>Create New Workspace</h2>
      <p className="b2">What would you like to name your workspace?</p>
      <CreateWorkspaceForm
        mutate={mutate}
        workspaceName={workspaceName}
        {...props}
      />
    </CustomModal>
  );
}

interface CreateWorkspaceFormProps extends ModalProps {
  mutate: UseMutateFunction<
    unknown,
    unknown,
    CreateWorkspaceQueryProps,
    unknown
  >;
  workspaceName?: string;
}
const CreateWorkspaceForm = ({
  mutate,
  workspaceName,
  ...props
}: CreateWorkspaceFormProps) => {
  const { accounts } = useMsal();
  const [name, setName] = useState("");

  useEffect(() => {
    if (workspaceName) return setName(workspaceName);
  }, []);

  const handleCreateWorkspace = () => {
    const userid = accounts[0].username;
    if (!userid) return; // Can't create a workspace without a userid!
    mutate({ userID: userid, workspaceName: name });
  };

  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  const invalidValue = useMemo(() => name === workspaceName, [name]);

  return (
    <Form {...props} onSubmit={handleCreateWorkspace}>
      <Form.Input
        id="workspace-name"
        type="text"
        title="Workspace Name"
        required={true}
        value={name}
        onChange={onChangeName}
        invalidValue={invalidValue}
        errorMessage="A workspace with this name already exists, please choose a different name."
      />
      <Form.Divider />
      <Form.ButtonContainer>
        <Form.StyledButton
          id="workspace-create-button"
          value="Submit"
          disabled={invalidValue}
        >
          Create Workspace
        </Form.StyledButton>
      </Form.ButtonContainer>
    </Form>
  );
};
