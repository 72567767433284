import {
  LayerMetadata,
  DataSetTheme,
  LayerGeometryType,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PowerPlantsIcon } from "@accenture/energy-transition-ui-library";
import DefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/PowerPlantsDefaultPin.png";
import HoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/PowerPlantsHoverPin.png";
import SelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/PowerPlantsSelectedPin.png";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const powerPlantsLayerMetadata: LayerMetadata = {
  layerName: "Power Plants",
  sourceID: "power-plants-layer-source",
  layerID: "power-plants-layer",
  clusterLayerID: "power-plants-layer-cluster",
  dataset: "power_plants",
  toggleIcon: PowerPlantsIcon,
  description:
    "This dataset identifies operable electric generating plants by energy source with a combined nameplate capacity of 1 megawatt (MW) or more that are operating, on standby, or out of service for short-term or long-term.",
  layerImages: {
    powerPlantsHoverPin: HoverPin,
    powerPlantsSelectedPin: SelectedPin,
    powerPlantsDefaultPin: DefaultPin,
  },
  fieldsToDisplay: {
    Utility_ID: "Utility ID",
    Utility_Name: "Utility Name",
    Plant_Code: "Plant Code",
    Plant_Name: "Plant Name",
    Street_Address: "Street Address",
    Total_MW: "Total MW",
    Zip: "Zip",
    sector_name: "Sector Name",
    source_desc: "Source Description",
    tech_desc: "Tech Description",
    Source: "Source",
  },
  theme: [DataSetTheme.EnergyInfrastructure],
};

export const PowerPlantsLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<number>(0);
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, powerPlantsLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, powerPlantsLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", powerPlantsLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.OBJECTID);
      }
    });
    map.on("mouseleave", powerPlantsLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID(0);
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: powerPlantsLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${powerPlantsLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerClusterConfig: LayerProps = {
    id: powerPlantsLayerMetadata.clusterLayerID,
    source: powerPlantsLayerMetadata.sourceID,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Point),
    "source-layer": powerPlantsLayerMetadata.dataset,
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#fff",
      "circle-stroke-color": "#000",
      "circle-stroke-width": 2,
      "circle-radius": 30,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerClusterCountConfig: LayerProps = {
    id: powerPlantsLayerMetadata.clusterLayerID + "-count",
    source: powerPlantsLayerMetadata.sourceID,
    "source-layer": powerPlantsLayerMetadata.dataset,
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Graphik", "Arial Unicode MS Bold"],
      "icon-allow-overlap": true,
      "text-size": 24,
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerPointConfig: LayerProps = {
    id: powerPlantsLayerMetadata.layerID,
    source: powerPlantsLayerMetadata.sourceID,
    "source-layer": powerPlantsLayerMetadata.dataset,
    filter: ["!", ["has", "point_count"]],
    type: "symbol",
    layout: {
      "icon-image": [
        "case",
        [
          "in",
          ["get", "OBJECTID"],
          ["literal", selectedFeatures.map((f) => f.properties.OBJECTID)],
        ],
        "powerPlantsSelectedPin",
        ["==", ["get", "OBJECTID"], hoveredFeatureID],
        "powerPlantsHoverPin",
        "powerPlantsDefaultPin",
      ],
      "icon-allow-overlap": true,
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerClusterConfig} />
      <Layer {...layerClusterCountConfig} />
      <Layer {...layerPointConfig} />
    </Source>
  );
};
PowerPlantsLayer.metadata = powerPlantsLayerMetadata;
