import React, { useRef, useState, useEffect } from "react";
import DataClassificationPage from "./DataClassificationPage";
import UploadDetailsPage from "./UploadDetailsPage";
import UploadDatasetPage from "./UploadDatasetPage";
import { IDataDetails } from "./UploadDetailsPage";
import axios from "../../../utils/axiosConfig";
import { CircularProgress, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
  CustomModal,
  Form,
  Dropdown,
  UploadItemLabel,
  StyledUploadButton,
  UploadIcon,
} from "@accenture/energy-transition-ui-library";
import { useMsal } from "@azure/msal-react";

export interface IUploadModalProps {
  isModalOpen: boolean;
  onCancel: () => void;
}

export enum ModalPages {
  DataClassification = "Data classification",
  UploadDataSet = "Upload data set",
  UploadDetails = "Upload details",
}

const LoadingSpinner = styled("div")`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function DataUpload(): React.ReactElement {
  const { accounts } = useMsal();
  const queryClient = useQueryClient();
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const modalRef = useRef<Dropdown>(null);
  const [classification, setClassification] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const details = useRef<IDataDetails | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [modalPage, setModalPage] = useState<string>(
    ModalPages.DataClassification,
  );

  const handleModalPage = (page: string) => {
    setModalPage(page);
  };

  const onCancel = () => {
    setUploadModalOpen(false);
    if (modalRef.current) {
      modalRef.current.close();
    }
  };

  useEffect(() => {
    if (!isUploadModalOpen) {
      setClassification(null);
      details.current = null;
      setFile(null);
      setModalPage(ModalPages.DataClassification);
    }
  }, [isUploadModalOpen]);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!accounts[0].username) {
      console.error("No user id");
      return;
    }
    if (!file || !classification || !details.current) {
      console.error("Data is missing, please close modal and try again");
      return;
    }
    setIsLoading(true);

    const dataForPresigned = {
      fileType: file.type,
      dataName: details.current.dataName,
    };

    // Fetch presigned POST url
    const presignedResponse = await axios
      .post("/dt_base/upload_dataset_get_presigned", dataForPresigned, {
        headers: {
          "file-name": file.name || "direct-link",
        },
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    if (!presignedResponse) return;

    // Populate formData
    const formData = new FormData();
    const presignedUrl = presignedResponse.data.presigned.url;
    const presignedFields: { [key: string]: string } =
      presignedResponse.data.presigned.fields;

    Object.entries(presignedFields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    // Upload to S3
    await axios.post(presignedUrl, formData).catch((err) => {
      console.error(err);
      setIsLoading(false);
      return;
    });

    // Make additional call to save metadata and allow data verification on backend
    const dataForVerification = {
      bucketName: presignedResponse.data.bucket,
      bucketKey: presignedResponse.data.key,
      userid: accounts[0].username,
      fileType: file.type,
      classification: classification,
      ...details.current,
    };
    await axios
      .post("/dt_base/upload_data", dataForVerification)
      .then(() => {
        setUploadModalOpen(false);
        queryClient.invalidateQueries(["uploadedDatasets"]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledUploadButton
        onClick={(e) => {
          setUploadModalOpen(true);
          e.preventDefault();
        }}
      >
        <UploadItemLabel>
          <h4>Data Upload</h4>
          <UploadIcon />
        </UploadItemLabel>
      </StyledUploadButton>
      <CustomModal isModalOpen={isUploadModalOpen} onCancel={onCancel}>
        <Form>
          <>
            {modalPage === ModalPages.DataClassification && (
              <DataClassificationPage
                classification={classification}
                setClassification={setClassification}
                handleModalPage={handleModalPage}
              />
            )}
            {modalPage === ModalPages.UploadDataSet && (
              <UploadDatasetPage
                file={file}
                setFile={setFile}
                handleModalPage={handleModalPage}
              />
            )}
            {modalPage === ModalPages.UploadDetails && (
              <UploadDetailsPage
                setDetails={(data) => (details.current = data)}
                onCancel={onCancel}
                handleModalPage={handleModalPage}
                onSubmit={onSubmit}
                file={file}
              />
            )}
          </>
        </Form>
        {isLoading ? (
          <LoadingSpinner>
            <CircularProgress color="primary" />
          </LoadingSpinner>
        ) : (
          <></>
        )}
      </CustomModal>
    </>
  );
}
