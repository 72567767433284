import React from "react";
import { styled } from "@mui/material/styles";
import { Modal, ModalProps } from "../../atoms/Modal/Modal";
import { CircularProgress } from "@mui/material";

const ModalContainer = styled(Modal)<{
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : "840px")};
  border-radius: 4px;
  background: ${(props) => props.theme.colors.background.base};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 0;
`;
const Content = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  width: 100%;

  h2 {
    margin-top: 0;
  }
  p {
    margin: 0;
  }
`;
const LoadingSpinner = styled("div")`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export interface CustomModalProps extends ModalProps {
  isLoading?: boolean;
  repeat?: boolean;
  width?: string;
}
export function CustomModal(props: CustomModalProps) {
  if (props.repeat !== undefined && !props.repeat) {
    return null;
  }

  return (
    <ModalContainer noDivider {...props}>
      {props.isLoading ? (
        <LoadingSpinner>
          <CircularProgress color="primary" />
        </LoadingSpinner>
      ) : (
        <Content className="modal-content">{props.children}</Content>
      )}
    </ModalContainer>
  );
}
