import React from "react";
import { Portal } from "../../utils/portal";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const Overlay = styled("div")<{ isLoading: boolean }>`
  visibility: ${(props) => (props.isLoading ? `visible` : `hidden`)};
  opacity: ${(props) => (props.isLoading ? `1` : `0`)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: ${(props) =>
    props.theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.3)"
      : "rgba(255, 255, 255, 0.7)"};
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface SpinnerProps {
  isLoading: boolean;
}
export function Spinner(props: SpinnerProps): React.ReactElement {
  return (
    <Portal>
      <Overlay isLoading={props.isLoading}>
        <CircularProgress color="primary" />
      </Overlay>
    </Portal>
  );
}
