import React, { useMemo, useState, useEffect } from "react";
import { selectSelectedTags } from "../redux/dataCatalogSlice";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardInfo,
  Catalog,
  Content,
  DataCatalogTitle,
  DataSetResults,
  DataSetTitle,
  Divider,
  Header,
  StyledParagraph,
  Tag,
  Tags,
} from "./styles";
import SearchBar from "@accenture/energy-transition-ui-library/src/atoms/SearchBar/SearchBar";
import {
  globalDatasetsQuery,
  uploadedDatasetsQuery,
} from "./dataCatalogQueries";
import { useQuery } from "@tanstack/react-query";
import {
  DatasetLayer,
  ITags,
  instanceOfGlobalDatasetLayer,
  instanceOfUploadedDatasetLayer,
} from "@accenture/energy-transition-interfaces";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const DataCatalog = () => {
  const { isLoading: globalLoading, data: globalDatasets } = useQuery(
    globalDatasetsQuery(),
  );
  const { isLoading: uploadedLoading, data: uploadedDatasets } = useQuery({
    queryKey: uploadedDatasetsQuery().queryKey,
    queryFn: uploadedDatasetsQuery().queryFn,
  });
  const selectedTags = useSelector(selectSelectedTags);
  const [filterByTags, setFilteredDataSets] = useState<DatasetLayer[] | null>(
    null,
  );
  const [filterBySearch, setFilterBySearch] = useState<DatasetLayer[] | null>(
    null,
  );

  const totalDataSets = useMemo(() => {
    if (filterBySearch) return filterBySearch?.length;
    return filterByTags?.length;
  }, [filterBySearch, filterByTags]);

  const filterResults = useMemo(() => {
    if (filterBySearch) return filterBySearch;
    return filterByTags;
  }, [filterBySearch, filterByTags]);

  const getDatasetLayerTags = (datasetLayer: DatasetLayer) => {
    const tags = [...datasetLayer.theme, datasetLayer.dataSourceTag];
    if (instanceOfGlobalDatasetLayer(datasetLayer)) {
      tags.push(datasetLayer["Data Update Frequency"]);
      tags.push(ITags.BuiltIn);
    }
    if (instanceOfUploadedDatasetLayer(datasetLayer)) {
      tags.push(ITags.Uploaded);
    }
    return tags;
  };

  useEffect(() => {
    const datasets = [];
    if (globalDatasets && globalDatasets.length > 0) {
      datasets.push(...globalDatasets);
    }
    if (uploadedDatasets && uploadedDatasets.length > 0) {
      datasets.push(...uploadedDatasets);
    }
    const filter: DatasetLayer[] = [];

    datasets.forEach((datasetLayer) => {
      const tags = getDatasetLayerTags(datasetLayer);
      selectedTags.map((selectedTag) => {
        const isSelected = tags.includes(selectedTag);
        const isAlreadyFiltered = filter?.includes(datasetLayer);
        if (!isSelected || isAlreadyFiltered) return;
        filter.push(datasetLayer);
      });
    });

    filter.sort((a, b) => {
      const textA = a.user_dataset_name.toUpperCase();
      const textB = b.user_dataset_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setFilteredDataSets((): DatasetLayer[] | null => filter);
  }, [globalDatasets, uploadedDatasets, selectedTags]);

  return (
    <>
      <Header>
        <DataCatalogTitle>Data Catalog</DataCatalogTitle>
      </Header>
      <Divider />
      <Content>
        <Catalog>
          <SearchBar
            id="search-bar"
            handleSelection={setFilterBySearch}
            options={globalDatasets || []}
            value={filterBySearch}
            placeholder="Search dataset"
          />
          <DataSetResults>{`${
            totalDataSets ? totalDataSets : 0
          } Results`}</DataSetResults>
          {globalLoading || uploadedLoading ? (
            <CircularProgress />
          ) : (
            filterResults?.map((datasetLayer) => {
              const tags = getDatasetLayerTags(datasetLayer);
              return (
                <Card
                  key={
                    datasetLayer.user_dataset_name + datasetLayer.description
                  }
                >
                  <CardContent>
                    <CardInfo>
                      <DataSetTitle>
                        {datasetLayer.user_dataset_name}
                      </DataSetTitle>
                      <Tags>
                        {tags.map((tag) => (
                          <Tag key={datasetLayer.user_dataset_name + tag}>
                            {tag}
                          </Tag>
                        ))}
                      </Tags>
                      {instanceOfGlobalDatasetLayer(datasetLayer) && (
                        <StyledParagraph className="s2">
                          <Link to={datasetLayer.URL}>
                            {datasetLayer["UI Link"]}
                          </Link>
                        </StyledParagraph>
                      )}
                    </CardInfo>
                    <StyledParagraph className="b3">
                      {datasetLayer.description}
                    </StyledParagraph>
                  </CardContent>
                </Card>
              );
            })
          )}
        </Catalog>
      </Content>
    </>
  );
};
