import {
  DataSetTheme,
  LayerGeometryType,
  LayerMetadata,
  UploadedDatasetLayer,
} from "@accenture/energy-transition-interfaces";
import { GeoJsonGeometryTypes } from "geojson";
import { MapboxGeoJSONFeature } from "react-map-gl";
import countyData from "./county_data.json";

export const getLayerStyleTypeByGeometryType = (
  type: GeoJsonGeometryTypes | undefined,
) => {
  switch (type) {
    case "LineString":
    case "MultiLineString":
      return LayerGeometryType.Line;
    case "Polygon":
    case "MultiPolygon":
      return LayerGeometryType.Fill;
    case "Point":
    case "MultiPoint":
    default:
      return LayerGeometryType.Circle;
  }
};

export const getLayerMetadata = (
  layer: UploadedDatasetLayer,
): LayerMetadata => {
  const layerMetadata = {
    layerName: layer.user_dataset_name,
    sourceID: `${layer.dataset_name}-source`,
    layerID: layer.dataset_name,
    dataset: layer.user_dataset_name,
    toggleDotColor: layer.color,
    description: layer.description,
    theme: layer.theme,
  };
  return layerMetadata;
};

export const mapThemeToLayerIDs = (
  theme: DataSetTheme,
  metadata: LayerMetadata[],
) => {
  const filteredMetadata = metadata.filter((layer) =>
    layer.theme?.includes(theme),
  );
  if (filteredMetadata.length < 1) return [];
  const filteredIDs = filteredMetadata.map((layer) => layer.layerID);
  return filteredIDs;
};

export const mapLayerIDToMetadata = (
  layerID: string,
  metadata: LayerMetadata[],
): LayerMetadata => {
  const filteredMetadata = metadata.filter(
    (layer) => layer.layerID === layerID,
  );
  return filteredMetadata[0];
};

export const getBeforeIdByLayerType = (type: LayerGeometryType) => {
  switch (type) {
    case LayerGeometryType.Fill:
      return "Road labels";
    case LayerGeometryType.Point:
      return "Road labels";
    case LayerGeometryType.Line:
      return "Road labels";
    default:
      return "";
  }
};

export const convertDatasetLayersToLayerIDs = (
  dataset_layers:
    | {
        [key: string]: string[];
      }
    | undefined,
) => {
  if (!dataset_layers || Object.values(dataset_layers).length === 0) return [];

  const layerIDs = Object.values(dataset_layers).reduce((acc, cur) => {
    cur.forEach((id) => acc.push(id));
    return acc;
  }, []);
  return layerIDs;
};

export const getDatasetInfoByLayerIDs = (
  layerIDs: string[],
  layers: LayerMetadata[],
) => {
  const datasetNames: string[] = [];
  const datasetLayers: { [key: string]: string[] } = {};

  if (layerIDs.length > 0) {
    layerIDs.forEach((layerID) => {
      const metadata = mapLayerIDToMetadata(layerID, layers);
      if (!datasetNames.includes(metadata.dataset)) {
        datasetNames.push(metadata.dataset);
      }

      if (datasetLayers[metadata.dataset]) {
        datasetLayers[metadata.dataset].push(layerID);
      } else {
        datasetLayers[metadata.dataset] = [layerID];
      }
    });
  }

  return {
    datasets: datasetNames,
    dataset_layers: datasetLayers,
  };
};

export const compareStringArrays = (a: string[], b: string[]) => {
  if (!a.length || !b.length) return false;
  const sortedA = [...a];
  const sortedB = [...b];
  sortedA.sort();
  sortedB.sort();
  return (
    sortedA.length === sortedB.length &&
    sortedA.every(
      (element: string, index: number) => element === sortedB[index],
    )
  );
};

export const getUniqueFeatures = (
  features: MapboxGeoJSONFeature[],
  comparatorProperty: string,
) => {
  const uniqueIds = new Set();
  const uniqueFeatures = [];
  for (const feature of features) {
    if (feature.properties === null) continue;
    const id = feature.properties[comparatorProperty];
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueFeatures.push(feature);
    }
  }
  return uniqueFeatures;
};

export const getCountyName = (statefp: string, countyfp: string) => {
  return countyData.find(
    (county) => county.statefp === statefp && county.countyfp === countyfp,
  )?.countyname;
};
