import React from "react";

export const PowerPlantsIcon = () => {
  return (
    <svg
      className="dark-mode-compatible stroke"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1288_49725"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1288_49725)">
        <path
          d="M4.8276 6.79947C4.81972 6.56631 4.86075 6.33402 4.94825 6.11638C5.03575 5.89874 5.16793 5.70019 5.33695 5.53252C5.50596 5.36485 5.70837 5.23149 5.93215 5.14034C6.15592 5.0492 6.3965 5.00214 6.6396 5.00195H12.6C13.584 5.00195 14.388 5.75504 14.4072 6.70159C14.4492 8.73747 14.7288 14.267 16.5432 21.6989H17.7432C16.59 16.9731 16.0572 12.3187 15.8112 9.03226H17.3208C18.408 9.03226 19.1868 9.92238 19.1568 10.8689C19.116 12.2058 19.2288 15.9747 21.2532 21.6989H22.8C23.1183 21.6989 23.4235 21.8202 23.6485 22.0362C23.8736 22.2521 24 22.545 24 22.8504C24 23.1558 23.8736 23.4487 23.6485 23.6647C23.4235 23.8806 23.1183 24.002 22.8 24.002H1.2C0.88174 24.002 0.576515 23.8806 0.351472 23.6647C0.126428 23.4487 0 23.1558 0 22.8504C0 22.545 0.126428 22.2521 0.351472 22.0362C0.576515 21.8202 0.88174 21.6989 1.2 21.6989H2.7084C4.9344 14.3477 4.896 8.8768 4.8276 6.79947Z"
          fill="black"
        />
        <path
          d="M10 2.00053C10.2272 1.68403 10.528 1.4276 10.8764 1.25337C11.2249 1.07915 11.6105 0.992367 12 1.00053C12.3895 0.992367 12.7751 1.07915 13.1236 1.25337C13.472 1.4276 13.7728 1.68403 14 2.00053C14.2272 2.31702 14.528 2.57345 14.8764 2.74768C15.2249 2.9219 15.6105 3.00869 16 3.00053C16.3895 3.00869 16.7751 2.9219 17.1236 2.74768C17.472 2.57345 17.7728 2.31702 18 2.00053C18.2272 1.68403 18.528 1.4276 18.8764 1.25337C19.2249 1.07915 19.6105 0.992367 20 1.00053C20.3895 0.992367 20.7751 1.07915 21.1236 1.25337C21.472 1.4276 21.7728 1.68403 22 2.00053"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
