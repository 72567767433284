import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const activeEarthquakesLayerMetadata: LayerMetadata = {
  layerName: "Active Earthquakes",
  sourceID: "active-earthquakes-layer-source",
  layerID: "active-earthquakes-layer",
  dataset: "earthquake_catalog",
  toggleDotColor: "#A0522D",
  description:
    "This provides data in real time on earthquakes by time window, magnitude, area, impact, and contributor",
  fieldsToDisplay: {
    mag: "Magnitude",
    title: "Summary",
    type: "Seismic Activity Type",
    url: "Additional Details",
  },
  theme: [DataSetTheme.Environment],
};

export const ActiveEarthquakesLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, activeEarthquakesLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, activeEarthquakesLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", activeEarthquakesLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.code);
      }
    });
    map.on("mouseleave", activeEarthquakesLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: activeEarthquakesLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${activeEarthquakesLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerPointConfig: LayerProps = {
    id: activeEarthquakesLayerMetadata.layerID,
    source: activeEarthquakesLayerMetadata.sourceID,
    "source-layer": activeEarthquakesLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "circle",
    paint: {
      "circle-stroke-width": 2,
      "circle-stroke-color": [
        "case",
        [
          "any",
          ["==", ["get", "code"], hoveredFeatureID],
          [
            "in",
            ["get", "code"],
            ["literal", selectedFeatures.map((f) => f.properties.code)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "circle-color": activeEarthquakesLayerMetadata.toggleDotColor,
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "mag"]],
        1,
        8,
        1.5,
        10,
        2,
        12,
        2.5,
        14,
        3,
        16,
        3.5,
        18,
        4.5,
        20,
        6.5,
        22,
        8.5,
        24,
        10.5,
        26,
      ],
      "circle-opacity": 0.5,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerPointConfig} />
    </Source>
  );
};
ActiveEarthquakesLayer.metadata = activeEarthquakesLayerMetadata;
