import React, { ReactNode, useState, useMemo, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { ThemeCard } from "./ThemeCard";
import * as layersModule from "../../Map/layers";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  workspaceListQuery,
  createWorkspaceQuery,
} from "../../Workspaces/workspaceQueries";
import {
  DataSetTheme,
  IWorkspace,
} from "@accenture/energy-transition-interfaces";
import { useMsal } from "@azure/msal-react";
import {
  Spinner,
  CommunityIcon,
  EcosystemIcon,
  GlobeIcon,
  LightbulbCogIcon,
  LightbulbLeafIcon,
  CardsCarousel,
} from "@accenture/energy-transition-ui-library";

const ThemesWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`;

interface IThemesProps {
  userID: string;
}
export interface ITheme {
  id: string;
  icon: ReactNode;
  title: DataSetTheme;
  text: string;
}

const themeData: ITheme[] = [
  {
    id: "climate-change",
    icon: <GlobeIcon />,
    title: DataSetTheme.Climate,
    text: "See where climate change poses the greatest risk today",
  },
  {
    id: "environment",
    icon: <EcosystemIcon />,
    title: DataSetTheme.Environment,
    text: "Protect endangered species, fragile ecosystems and maintain biodiversity",
  },
  {
    id: "transportation",
    icon: <LightbulbCogIcon />,
    title: DataSetTheme.Transportation,
    text: "Visualize existing and planned transportation infrastructure",
  },
  {
    id: "energy-infrastructure",
    icon: <LightbulbLeafIcon />,
    title: DataSetTheme.EnergyInfrastructure,
    text: "Visualize existing and planned energy infrastructure",
  },
  {
    id: "urban-planning",
    icon: <CommunityIcon />,
    title: DataSetTheme.UrbanPlanning,
    text: "Review planned development and growth with customers and communities",
  },
];

export function ThemesContainer(props: IThemesProps) {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const layers = useMemo(() => Object.values({ ...layersModule }), []);

  const { data: workspaces } = useQuery<IWorkspace[]>({
    queryKey: workspaceListQuery(props.userID).queryKey,
    queryFn: workspaceListQuery(props.userID).queryFn,
  });

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation({
    mutationFn: createWorkspaceQuery,
    onSuccess: (workspaceID) => {
      queryClient.invalidateQueries(["workspaceList"]);
      navigate(`/workspaces/${workspaceID}/map`);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const getWorkspaceName = useCallback(
    (tentativeName: string) => {
      const unavailableNames = workspaces?.map(
        (workspace) => workspace.workspace_name,
      );
      const isAvailable = (name: string) => !unavailableNames?.includes(name);
      if (isAvailable(tentativeName)) return tentativeName;
      let i = 1;
      while (!isAvailable(`${tentativeName} ${i}`)) i++;
      return `${tentativeName} ${i}`;
    },
    [workspaces],
  );

  const openWorkspace = useCallback(
    (themeName: DataSetTheme) => {
      const userid = accounts[0].username;
      if (!userid) return;
      const filteredLayers = layers.filter((layer) =>
        layer.metadata.theme?.includes(themeName),
      );
      const selectedlayers = filteredLayers.map(
        (layer) => layer.metadata.layerID,
      );
      const tentativeName = `${themeName} Workspace`;
      const name = getWorkspaceName(tentativeName);
      mutate({
        userID: userid,
        workspaceName: name,
        layerIDs: selectedlayers,
      });
    },
    [layers, workspaces],
  );

  return (
    <>
      <Spinner isLoading={isLoading} />
      <CardsCarousel
        itemQuantity={themeData.length}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      >
        <ThemesWrapper>
          {themeData.slice(currentIndex, currentIndex + 4).map((theme) => (
            <ThemeCard
              key={theme.id}
              theme={theme}
              onClick={() => openWorkspace(theme.title)}
            />
          ))}
        </ThemesWrapper>
      </CardsCarousel>
    </>
  );
}
