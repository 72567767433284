import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const homesPriorTo1960LayerMetadata: LayerMetadata = {
  layerName: "Homes Prior to 1960",
  sourceID: "homes-prior-to-1960-layer-source",
  layerID: "homes-prior-to-1960-layer",
  dataset: "cejst",
  toggleDotColor: "#008080",
  description:
    "This dataset displays the percentile of a tract's houses built prior to 1960. This can be an indication of aging infrastructure or lead paint.",
  fieldsToDisplay: {
    "Percent pre-1960s housing (lead paint indicator) (percentile)":
      "Percentile of # of homes build before 1960 as an indicator of lead paint or upgrade needs",
  },
  theme: [DataSetTheme.UrbanPlanning],
};
export const HomesPriorTo1960Layer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, homesPriorTo1960LayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, homesPriorTo1960LayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", homesPriorTo1960LayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", homesPriorTo1960LayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: homesPriorTo1960LayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${homesPriorTo1960LayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: homesPriorTo1960LayerMetadata.layerID,
    source: homesPriorTo1960LayerMetadata.sourceID,
    "source-layer": homesPriorTo1960LayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": homesPriorTo1960LayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        [
          "to-number",
          [
            "get",
            "Percent pre-1960s housing (lead paint indicator) (percentile)",
          ],
        ],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HomesPriorTo1960Layer.metadata = homesPriorTo1960LayerMetadata;
