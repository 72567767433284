import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

export function Portal({ children }: { children: ReactNode }) {
  const mount = document.getElementById("energy-transition-portal-root");
  const el = document.createElement("div");

  useEffect(() => {
    mount?.appendChild(el);
    () => mount?.removeChild(el);
  }, [el, mount]);

  return createPortal(children, el);
}
