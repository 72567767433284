import React from "react";
import { styled } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  Input,
  MetricCard,
  CustomizedSwitch,
} from "@accenture/energy-transition-ui-library";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { selectIsDarkModeOn, toggleDarkMode } from "../redux/appSlice";
import { useSelector } from "react-redux";

const Container = styled("div")`
  min-height: calc(100vh - 80px - 83px);
  background-color: ${(props) => props.theme.colors.background.base};
  h1,
  h2 {
    margin: 0px;
  }
`;

const Header = styled("div")`
  display: flex;
  padding: 80px 60px 40px 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 1800px;
  margin: auto;
`;

const Content = styled("div")`
  display: flex;
  padding: 80px 60px 40px 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px 0px;
  max-width: 1800px;
  margin: auto;
`;

const Section = styled("div")<{
  gap?: string;
  flexDirection?: string;
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : "32px")};
`;

const Cards = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

const Links = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 80px;
  a {
    color: ${(props) => props.theme.colors.text.minor};
  }
`;

const Divider = styled("div")`
  display: block;
  background-color: ${(props) => props.theme.colors.separators.minor};
  margin: 0;
  height: 2px;
`;

const usageMetricsCards = [
  { title: "Workspaces", subtitle: "Workspaces", value: 2 },
  { title: "Datasets Used", subtitle: "Datasets", value: 9 },
  { title: "Datasets Uploaded", subtitle: "Datasets", value: 4 },
  { title: "Average Monthly Logins", subtitle: "Per Month", value: 8 },
];

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const { userEmail } = useParams();
  const darkModeOn = useSelector(selectIsDarkModeOn);

  return (
    <Container>
      <Header>
        <h1>Account Settings</h1>
      </Header>
      <Divider />
      <Content>
        <Section gap="24px">
          <h2>Preferences</h2>
          <Section>
            <Input
              id="account-settings-input"
              type="email"
              title="Email"
              placeholder={userEmail}
              disabled
            />
            <CustomizedSwitch
              label="Dark Mode"
              checked={darkModeOn}
              onChange={() => dispatch(toggleDarkMode(!darkModeOn))}
            />
          </Section>
        </Section>
        <Section>
          <h2>Usage Metrics</h2>
          <Cards>
            {usageMetricsCards.map((metricCard) => (
              <MetricCard
                key={metricCard.title + metricCard.value}
                title={metricCard.title}
                subtitle={metricCard.subtitle}
                value={metricCard.value}
                width="238px"
              />
            ))}
          </Cards>
        </Section>
        <Section>
          <h2>Support</h2>
          <Links>
            <Link to="mailto:impact_accelerator@accenture.com">
              Contact Team
            </Link>
          </Links>
        </Section>
      </Content>
    </Container>
  );
};

export default AccountSettings;
