import React from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonType } from "../../atoms/Button/Button";

export interface INewsArticle {
  title: string;
  date: string;
  description: string;
  url: string;
}
interface NewsSlideProps extends INewsArticle {}

const SlideContainer = styled("div")`
  width: 100%;
  padding: 0 72px;
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 0;
  }
  p {
    margin: 0;
    &.date {
      margin-bottom: 24px;
    }
  }
  a {
    margin-top: 24px;
    align-self: end;
    button {
      margin: 0;
    }
  }
`;

export const NewsSlide = ({
  title,
  date,
  description,
  url,
}: NewsSlideProps) => {
  return (
    <SlideContainer>
      <h2>{title}</h2>
      <p className="b2 date">{date}</p>
      <p className="b2">{description}</p>
      <a href={url} target="_blank" rel="noreferrer">
        <Button buttonType={ButtonType.Primary}>Learn More</Button>
      </a>
    </SlideContainer>
  );
};
