import React from "react";
import { styled } from "@mui/material/styles";
// import AccentureLogoFooter from "../../atoms/Assets/Logos/AccentureLogoFooter.png";
import { useLocation } from "react-router-dom";
import { AccentureLogoFooter } from "../../atoms/Assets/Logos/AccentureLogoFooter";

const FooterContainer = styled("div")`
  width: 100%;
  box-shadow: ${(props) => props.theme.styles.shadows.footer};
`;
const SimpleFooterContainer = styled("div")`
  padding: 28px 0;
  text-align: center;
`;
const FooterCopyrightContainer = styled("div")`
  display: flex;
  max-width: 1800px;
  margin: auto;
  justify-content: space-between;
  padding: 32px 60px;
`;
const FooterCopyrightLinks = styled("div")`
  display: flex;
  gap: 40px;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.text.minor};
  }
`;
const FooterContent = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 1800px;
  width: calc(100% - 120px);
  padding: 0 60px;
  margin: auto;

  > * {
    flex: 50%;
  }
`;
const FooterAbout = styled("div")`
  padding-top: 40px;
  h2 {
    margin-top: 0;
  }
  p {
    max-width: 582px;
  }
`;
const FooterResources = styled("div")`
  padding-top: 48px;
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 24px;
  }
  > div {
    display: flex;
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      gap: 24px;
      a {
        color: ${(props) => props.theme.colors.text.minor};
      }
      &:not(:first-of-type) {
        padding-left: 80px;
      }
    }
  }
`;

export const Footer = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const isMapPage = location.pathname.endsWith("map");

  return (
    <FooterContainer>
      {isLandingPage ? (
        <>
          <FooterContent>
            <FooterAbout>
              <h2>About</h2>
              <p className="b2">
                Accenture created this platform to democratize data and optimize
                decision-making for decarbonization. We look forward to working
                with you to define your key challenges and embark on your own
                impact journey!
              </p>
            </FooterAbout>
            <FooterResources>
              <h3>Resources</h3>
              <div>
                <div>
                  <a
                    href="https://www.accenture.com/us-en/insights/strategy/wef-industries"
                    target="_blank"
                    rel="noreferrer"
                  >
                    World Economic Forum
                  </a>
                  <a
                    href="https://www.weforum.org/projects/system-value"
                    target="_blank"
                    rel="noreferrer"
                  >
                    System Value Framework
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.accenture.com/us-en/services/utilities/energy-transition-utilities"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Accenture Energy Transition Services
                  </a>
                  <a
                    href="https://www.ipcc.ch/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Intergovernmental Panel on Climate Change
                  </a>
                </div>
              </div>
            </FooterResources>
          </FooterContent>
          <FooterCopyrightContainer className="b3">
            <FooterCopyrightLinks>
              <a href="#" className="b3">
                Privacy Policy
              </a>
              <a href="#" className="b3">
                Cookie Policy
              </a>
              <a href="#" className="b3">
                Terms of Use
              </a>
            </FooterCopyrightLinks>
            <div>
              Copyright 2001-2021 Accenture. All rights reserved. Accenture
              Confidential. For internal use only.
            </div>
          </FooterCopyrightContainer>
        </>
      ) : (
        !isMapPage && (
          <SimpleFooterContainer>
            <AccentureLogoFooter />
          </SimpleFooterContainer>
        )
      )}
    </FooterContainer>
  );
};
