import React from "react";
import { ITags } from "@accenture/energy-transition-interfaces";
import { Section, StyledChip, TagsProps } from "./TagsList";

function DataUpdateFrequencyTags(props: TagsProps): React.ReactElement {
  const { tagsFilter, onChangeFilter } = props;

  return (
    <Section gap="8px">
      <h4>Data Update Frequency</h4>
      <div>
        <StyledChip
          label={ITags.Yearly}
          selected={tagsFilter?.includes(ITags.Yearly)}
          onClick={() => onChangeFilter(ITags.Yearly)}
        />
        <StyledChip
          label={ITags.Quarterly}
          selected={tagsFilter?.includes(ITags.Quarterly)}
          onClick={() => onChangeFilter(ITags.Quarterly)}
        />
        <StyledChip
          label={ITags.Monthly}
          selected={tagsFilter?.includes(ITags.Monthly)}
          onClick={() => onChangeFilter(ITags.Monthly)}
        />
        <StyledChip
          label={ITags.Daily}
          selected={tagsFilter?.includes(ITags.Daily)}
          onClick={() => onChangeFilter(ITags.Daily)}
        />
        <StyledChip
          label={ITags.NoFixedSchedule}
          selected={tagsFilter?.includes(ITags.NoFixedSchedule)}
          onClick={() => onChangeFilter(ITags.NoFixedSchedule)}
        />
      </div>
    </Section>
  );
}

export default DataUpdateFrequencyTags;
