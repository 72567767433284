import React from "react";
import { styled } from "@mui/material/styles";
import { MetricCard } from "@accenture/energy-transition-ui-library";
import { selectRunPrioritization } from "../redux/workspaceSlice";
import {
  selectMetricAverageCommute,
  selectMetricAverageIncome,
  selectMetricEducation,
  selectMetricPopulation,
  selectMetricUnderserved,
  selectMetricUnemployment,
} from "../redux/mapSlice";
import { useSelector } from "react-redux";

const MetricsContainer = styled("div")<{ prioritizationMode: boolean }>`
  display: flex;
  height: 163px;
  padding: 0px 24px;
  align-items: center;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  background-color: ${(props) =>
    props.prioritizationMode
      ? props.theme.colors.button.primary
      : props.theme.colors.background.base};
  border-bottom: 2px solid ${(props) => props.theme.colors.separators.major};
  h5 {
    margin: 0px;
    color: white;
  }
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.background.sidebar};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.text.minor};
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
`;

const Section = styled("div")<{ position: "right" | "left" }>`
  display: flex;
  width: 100%;
  padding: ${(props) =>
    props.position === "right" ? "0px 11px 16px 25px" : "0px 25px 16px 11px;"};
  align-items: center;
  flex-direction: column;
  border-left: ${(props) =>
    props.position === "right"
      ? `1px solid ${props.theme.colors.separators.major}`
      : "hidden"};
  border-right: ${(props) =>
    props.position === "left"
      ? `1px solid ${props.theme.colors.separators.major}`
      : "hidden"};
`;

interface IMetric {
  title: string;
  subtitle?: string;
  tooltip: string;
  value: number | string;
  unit?: string;
}

export const WorkspaceMetrics = () => {
  const population = useSelector(selectMetricPopulation);
  const underserved = useSelector(selectMetricUnderserved);
  const unemployment = useSelector(selectMetricUnemployment);
  const education = useSelector(selectMetricEducation);
  const averageCommute = useSelector(selectMetricAverageCommute);
  const averageIncome = useSelector(selectMetricAverageIncome);
  const isPriorizationOn = useSelector(selectRunPrioritization);

  const equityMetrics = [
    {
      title: "Total Population",
      subtitle: "People",
      tooltip:
        'Total number of men and women in the current visible view on the map. NOTE: Data is not available at all zoom levels. Please zoom in or out if you see "N/A"',
      value: population,
    },
    {
      title: "Underserved",
      subtitle: "of Total Population",
      tooltip:
        'This dataset displays disadvantaged communities as defined by the Climate and Economic Justice Screening Tool. The index of N : 1 highlights block groups with the highest intersection of 5 socioeconomic factors and a given environmental indicator. This calculation uses the N designation at the tract level and applies tract level population size for a more detailed view of impacted communities. NOTE: Data is not available at all zoom levels. Please zoom in or out if you see "N/A"',
      value: underserved,
      unit: "%",
    },
    {
      title: "Unemployment",
      subtitle: "of Total Population",
      tooltip:
        'Calculation: population over 16 who are unemployed divided by total population over 16 in the current visible view on the map. NOTE: Data is not available at all zoom levels. Please zoom in or out if you see "N/A"',
      value: unemployment,
      unit: "%",
    },
    {
      title: "Education",
      subtitle: "of Population W/O HS Degree",
      tooltip:
        'Calculation: population over 25 with no high school diploma divided by total population over 25 in the current visible view on the map. NOTE: Data is not available at all zoom levels. Please zoom in or out if you see "N/A"',
      value: education,
      unit: "%",
    },
    {
      title: "Average Commute",
      subtitle: "Minutes",
      tooltip:
        'Calculation: average commute for workers 16 years and older who do not work from home, in minutes. NOTE: Data is not available at all zoom levels. Please zoom in or out if you see "N/A"',
      value: averageCommute,
    },
    {
      title: "Average Income",
      subtitle: "Per Capita",
      tooltip:
        'Calculation: average income per capita weighted by population. NOTE: Data is not available at all zoom levels. Please zoom in or out if you see "N/A"',
      value: averageIncome,
      unit: "$",
    },
  ];

  const prioritizationMetrics = [
    {
      title: "",
      subtitle: "",
      tooltip: "",
      value: 0,
    },
    {
      title: "",
      subtitle: "",
      tooltip: "",
      value: 0,
    },
    {
      title: "",
      subtitle: "",
      tooltip: "",
      value: 0,
    },
    {
      title: "",
      subtitle: "",
      tooltip: "",
      value: 0,
    },
    {
      title: "",
      subtitle: "",
      tooltip: "",
      value: 0,
    },
    {
      title: "",
      subtitle: "",
      tooltip: "",
      value: 0,
    },
  ];

  const metricCards = isPriorizationOn ? prioritizationMetrics : equityMetrics;

  const card = (metric: IMetric, key: number) => {
    return (
      <MetricCard
        key={key}
        title={metric.title}
        subtitle={metric.subtitle}
        tooltip={metric.tooltip}
        value={metric.value}
        unit={metric.unit}
        height="115px"
        prioritizationMode={isPriorizationOn}
      />
    );
  };

  return (
    <MetricsContainer prioritizationMode={isPriorizationOn}>
      {isPriorizationOn ? (
        <>
          <Section position="left">
            <h5></h5>
            <Content>
              {metricCards.slice(0, 3).map((metric, key) => card(metric, key))}
            </Content>
          </Section>
          <Section position="right">
            <h5></h5>
            <Content>
              {metricCards.slice(3, 6).map((metric, key) => card(metric, key))}
            </Content>
          </Section>
        </>
      ) : (
        <Content>{metricCards.map((metric, key) => card(metric, key))}</Content>
      )}
    </MetricsContainer>
  );
};
