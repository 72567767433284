import { styled } from "@mui/material/styles";
import React, { useRef, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const StyledCheckbox = styled(Checkbox)`
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid ${(props) => props.theme.colors.border.contrast};
  border-radius: 0.3em;
  display: grid;
  place-content: center;
  cursor: pointer;
  &:hover {
    border: 2px solid #a100ff;
  }
  &:focus {
    border: 2px solid #0041f0;
  }
`;

export interface ICheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
  boxAlign?: "LEFT" | "RIGHT";
}

export function CheckboxItem(props: ICheckboxProps) {
  const { indeterminate, checked, onChange, name } = props;
  const checkboxRef = useRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    if (checkboxRef && checkboxRef.current) {
      if (indeterminate) {
        checkboxRef.current.indeterminate = true;
      } else {
        checkboxRef.current.indeterminate = false;
      }
    }
  }, [checkboxRef?.current, indeterminate]);

  return (
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
      name={name}
      indeterminate={indeterminate}
      disableRipple={true}
      sx={{
        color: "transparent",
        "& .MuiSvgIcon-root": {
          fontSize: 32,
          border: "2px #3b3944",
          borderRadius: "0.3em",
        },
        "&.Mui-checked": {
          color: "#a100ff",
        },
      }}
    />
  );
}
