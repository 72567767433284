export enum ITags {
  Climate = "Climate",
  Environment = "Environment",
  Transportation = "Transportation",
  EnergyInfrastructure = "Energy Infrastructure",
  UrbanPlanning = "Urban Planning",
  External = "External",
  Internal = "Internal",
  Yearly = "Yearly",
  Quarterly = "Quarterly",
  Monthly = "Monthly",
  Daily = "Daily",
  NoFixedSchedule = "No fixed Schedule",
  BuiltIn = "Built In",
  Uploaded = "Uploaded",
}
