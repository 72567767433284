import React, { useEffect, useRef, useState, useMemo } from "react";
import { useMap } from "react-map-gl/maplibre";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { MapMouseEvent } from "maplibre-gl";
import * as layersModule from "./layers";
import { CustomLayer } from "./layers/CustomLayer";
import {
  IWorkspace,
  LayerMetadata,
} from "@accenture/energy-transition-interfaces";
import {
  editWorkspaceQuery,
  getWorkspaceByGlobalIDQuery,
} from "../Workspaces/workspaceQueries";
import {
  compareStringArrays,
  convertDatasetLayersToLayerIDs,
  mapLayerIDToMetadata,
} from "../utils/utils";
import {
  addActiveLayer,
  addLoadingLayer,
  removeLoadingLayer,
  selectActiveLayers,
  selectAreLayersLoading,
  selectMountedLayers,
  setSelectedFeatures,
  selectMetadata,
} from "../redux/mapSlice";
import { HiddenCEJSTLayer } from "./layers/HiddenCEJSTLayer";
import { HiddenCensusDP02Layer } from "./layers/HiddenCensusDP02Layer";
import { HiddenCensusDP03Layer } from "./layers/HiddenCensusDP03Layer";
import { HiddenCensusDP05Layer } from "./layers/HiddenCensusDP05Layer";
import { HiddenCensusS0801Layer } from "./layers/HiddenCensusS0801Layer";
import { useTaskQueue } from "../utils/hooks";

export const MapLayers = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();
  const queryClient = useQueryClient();
  const loadingLayers = useSelector(selectAreLayersLoading);
  const activeLayers = useSelector(selectActiveLayers);
  const mountedLayers = useSelector(selectMountedLayers);

  // These are looped through for rendering images on the map
  // as well as for selecting features on them from click events
  const isInitialLoad = useRef(true);
  const [initialLayersActivated, setInitialLayersActivated] = useState(false);
  const { workspaceID } = useParams();
  const layers = useMemo(() => Object.values({ ...layersModule }), []);
  const layerMetadata: LayerMetadata[] = useSelector(selectMetadata);
  const globalMetadata = layers.map((layer) => layer.metadata);
  const { addTask } = useTaskQueue({ shouldProcess: true });

  const uploadedMetadataLayer = layerMetadata.filter(
    (layer) => !globalMetadata.map((l) => l.layerID).includes(layer.layerID),
  );

  const workspaceQuery = useQuery<IWorkspace>({
    queryKey: getWorkspaceByGlobalIDQuery(workspaceID || "").queryKey,
    queryFn: getWorkspaceByGlobalIDQuery(workspaceID || "").queryFn,
    refetchOnMount: true,
  });

  const { mutateAsync: editWorkspace } = useMutation({
    mutationFn: editWorkspaceQuery,
    onError: (err) => {
      console.log(err);
    },
  });

  // Invalidate get workspace by id query when leaving the map page
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(["workspaceByID"]);
    };
  }, []);

  // Populate layers when workspace query completes
  useEffect(() => {
    if (workspaceQuery.isFetchedAfterMount) {
      activateWorkspaceLayers();
    }
  }, [workspaceQuery.isFetchedAfterMount]);

  // Make map spinner show up when loading workspace
  useEffect(() => {
    if (workspaceQuery.isLoading || workspaceQuery.isFetching) {
      dispatch(addLoadingLayer("fetch-workspace-query"));
    } else {
      dispatch(removeLoadingLayer("fetch-workspace-query"));
    }
  }, [workspaceQuery.isLoading, workspaceQuery.isFetching]);

  // On initial load, check if active layers changes from workspace layers
  useEffect(() => {
    if (
      !isInitialLoad.current ||
      !initialLayersActivated ||
      !workspaceID ||
      !workspaceQuery.data ||
      !workspaceQuery.data.dataset_layers ||
      !layerMetadata ||
      loadingLayers
    )
      return;

    const queriedLayerIDs = convertDatasetLayersToLayerIDs(
      workspaceQuery.data.dataset_layers,
    );
    if (!compareStringArrays(queriedLayerIDs, activeLayers)) {
      editWorkspace({
        workspaceID: workspaceID,
        layerIDs: activeLayers,
        metadata: layerMetadata,
      });
      isInitialLoad.current = false;
    }
  }, [
    workspaceQuery.data,
    workspaceID,
    loadingLayers,
    activeLayers,
    initialLayersActivated,
    layerMetadata,
  ]);

  // Automatically send edit workspace request when active layers changes
  useEffect(() => {
    if (
      isInitialLoad.current ||
      !workspaceID ||
      !workspaceQuery.data ||
      !workspaceQuery.data.dataset_layers ||
      !layerMetadata
    )
      return;

    addTask(() =>
      editWorkspace({
        workspaceID: workspaceID,
        layerIDs: activeLayers,
        metadata: layerMetadata,
      }),
    );
  }, [
    JSON.stringify(activeLayers),
    JSON.stringify(workspaceQuery.data),
    layerMetadata,
  ]);

  useEffect(() => {
    if (!map) return;
    loadLayerImages();
  }, []);

  useEffect(() => {
    if (!map) return;
    map.on("click", (e) => {
      selectFeaturesOnClick(e);
    });
  }, [layerMetadata]);

  const loadLayerImages = () => {
    if (!map) return;

    const layerImages = layers
      .map((layer) => layer.metadata.layerImages)
      .reduce((acc, imageObj) => {
        if (imageObj && Object.keys(imageObj).length > 0) {
          acc = { ...acc, ...imageObj };
        }
        return acc;
      }, {});

    if (layerImages && Object.keys(layerImages).length > 0) {
      for (const [key, value] of Object.entries(layerImages)) {
        map.loadImage(value, (error, image) => {
          if (error) throw error;
          if (!image) return;
          map.addImage(key, image);
        });
      }
    }
  };

  const activateWorkspaceLayers = () => {
    if (!workspaceQuery.data || !workspaceQuery.data.dataset_layers) return;

    const layerIDs = convertDatasetLayersToLayerIDs(
      workspaceQuery.data.dataset_layers,
    );
    layerIDs.forEach((layerID) => {
      dispatch(addActiveLayer(layerID));
    });
    if (!initialLayersActivated) {
      setInitialLayersActivated(true);
    }
  };

  const selectFeaturesOnClick = (e: MapMouseEvent) => {
    if (!map) return;

    // List all layers here that we would like to pull data from
    // and display in the right toolbar
    const clickableLayersIDs = layerMetadata.map((l) => l.layerID);
    // Filter out layers that aren't active or on the map
    const selectedFeatures = map.queryRenderedFeatures(e.point, {
      layers: clickableLayersIDs.filter((id) => map.getLayer(id)),
    });
    const formattedFeatures = selectedFeatures.map((feature) => {
      return {
        layerID: feature.layer.id,
        layerName: mapLayerIDToMetadata(feature.layer.id, layerMetadata)
          .layerName,
        properties: feature.properties,
      };
    });
    dispatch(setSelectedFeatures(formattedFeatures));
  };

  return (
    <>
      {/* Hidden/utility layers */}
      <HiddenCEJSTLayer />
      <HiddenCensusDP02Layer />
      <HiddenCensusDP03Layer />
      <HiddenCensusDP05Layer />
      <HiddenCensusS0801Layer />
      {uploadedMetadataLayer?.map(
        (metadata) =>
          (mountedLayers.includes(metadata.layerID) ||
            activeLayers.includes(metadata.layerID)) && (
            <CustomLayer metadata={metadata} key={metadata.layerID} />
          ),
      )}
      {layers.map(
        (Layer) =>
          (mountedLayers.includes(Layer.metadata.layerID) ||
            activeLayers.includes(Layer.metadata.layerID)) && (
            <Layer key={Layer.metadata.layerID} />
          ),
      )}
    </>
  );
};
