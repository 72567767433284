import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "../../atoms/Button/Button";
import { Input } from "../../atoms/Input/Input";
import { Textarea } from "../../atoms/Textarea/Textarea";
import { DropdownDivider } from "../Dropdown/Dropdown";
import { StyledDropdown } from "../../atoms/SelectDropdown/StyledDropdown";
export interface StyledFormProps {
  position?: "left" | "center" | "right";
  onSubmit?: (e: React.FormEvent) => void;
  id?: string;
}

const Divider = styled(DropdownDivider)`
  margin: 24px 0px;
  height: 2px;
  flex-shrink: 0;
`;

export const StyledButton = styled("button")`
  box-sizing: border-box;
  display: flex;
  padding: 11px 16px;
  height: 40px;
  align-items: flex-start;
  border-radius: 4px;
  border: 2px solid transparent;
  color: white;
  background: ${(props) => props.theme.colors.button.primary};
  cursor: pointer;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  &:hover {
    background: ${(props) => props.theme.colors.button.secondary};
  }

  &:focus {
    border: 2px solid #0041f0;
    background: ${(props) => props.theme.colors.button.secondary};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${(props) => props.theme.colors.button.inactive};
  }
`;

const ButtonContainer = styled("div")`
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputContainer = styled("div")`
  display: flex;
  width: 792px;
  align-items: flex-start;
  gap: 48px;
`;

const Content = styled("div")<{
  gap?: string;
}>`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : "16px")};
  flex-direction: column;
`;

const FormContainer = styled("form")<{
  position?: "left" | "center" | "right";
}>`
  text-align: ${(props) => props.position ?? "left"};
`;

const Spacer = styled("div")<{ gap: "16px" | "24px" }>`
  height: ${(props) => props.gap};
`;

export function Form(
  props: React.PropsWithChildren<StyledFormProps>,
): React.ReactElement {
  const onSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      props.onSubmit?.(event);
    },
    [props.onSubmit],
  );

  return <FormContainer {...props} onSubmit={onSubmit} />;
}

Form.Button = Button;
Form.StyledButton = StyledButton;
Form.Input = Input;
Form.Textarea = Textarea;
Form.ButtonContainer = ButtonContainer;
Form.Divider = Divider;
Form.Dropdown = StyledDropdown;
Form.Spacer = Spacer;
Form.InputContainer = InputContainer;
Form.Content = Content;
