import React from "react";
import { ITags } from "@accenture/energy-transition-interfaces";
import { Section, StyledChip, TagsProps } from "./TagsList";

function UploadSourceTags(props: TagsProps): React.ReactElement {
  const { tagsFilter, onChangeFilter } = props;

  return (
    <Section gap="8px">
      <h4>Upload Source</h4>
      <div>
        <StyledChip
          label={ITags.BuiltIn}
          selected={tagsFilter?.includes(ITags.BuiltIn)}
          onClick={() => onChangeFilter(ITags.BuiltIn)}
        />
        <StyledChip
          label={ITags.Uploaded}
          selected={tagsFilter?.includes(ITags.Uploaded)}
          onClick={() => onChangeFilter(ITags.Uploaded)}
        />
      </div>
    </Section>
  );
}

export default UploadSourceTags;
