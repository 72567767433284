import React, { useEffect } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { useAppDispatch } from "../../redux/store";
import { setMetricAverageCommute } from "../../redux/mapSlice";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";
import { getBeforeIdByLayerType, getUniqueFeatures } from "../../utils/utils";
import { LayerGeometryType } from "@accenture/energy-transition-interfaces/src/ILayer";

declare const AWS_API_URI: string;

const hiddenCensusS0801LayerMetadata: LayerMetadata = {
  layerName: "Hidden Census Tract S0801",
  sourceID: "hidden-census-s0801-layer-source",
  layerID: "hidden-census-s0801-layer",
  dataset: "census_tract_s0801",
};

export const HiddenCensusS0801Layer = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;
    map.on("moveend", () => {
      updateCommuteMetric();
    });
  }, []);

  const updateCommuteMetric = () => {
    if (!map || !map.getLayer(hiddenCensusS0801LayerMetadata.layerID)) return;

    const allFeatures = map.queryRenderedFeatures(undefined, {
      layers: [hiddenCensusS0801LayerMetadata.layerID],
    });
    const features = getUniqueFeatures(allFeatures, "tract");

    // Only get data if within the zoom bounds of the dataset (any features exist)
    if (features.length === 0) {
      dispatch(setMetricAverageCommute("N/A"));
      return;
    }

    const commuteMinutesArray = features
      .map(
        (f) =>
          f.properties &&
          f.properties[
            "Estimate!!Total!!Workers 16 years and over who did not work from home!!TRAVEL TIME TO WORK!!Mean travel time to work (minutes)"
          ],
      )
      .filter((val) => typeof val !== "undefined" && val > 0);
    const sumCommuteMinutes = commuteMinutesArray.reduce(
      (partialSum, a) => Number(partialSum) + Number(a),
      0,
    );
    const averageCommute =
      Math.round((sumCommuteMinutes / commuteMinutesArray.length) * 10) / 10;
    dispatch(setMetricAverageCommute(averageCommute));
  };

  const sourceConfig: SourceProps = {
    id: hiddenCensusS0801LayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${hiddenCensusS0801LayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: hiddenCensusS0801LayerMetadata.layerID,
    source: hiddenCensusS0801LayerMetadata.sourceID,
    "source-layer": hiddenCensusS0801LayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-opacity": 0,
    },
    layout: {
      visibility: "visible",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HiddenCensusS0801Layer.metadata = hiddenCensusS0801LayerMetadata;
