import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { MdOutlineAccountCircle } from "react-icons/md";
import {
  NavLink,
  NavLinkProps,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Dropdown } from "../../molecules/Dropdown/Dropdown";
import { Button, ButtonType } from "../../atoms/Button/Button";
import AccentureLogo from "../../atoms/Assets/Logos/AccentureLogo";
import { ChatbotPlaceholder } from "../../molecules/ChatbotPlaceholder/ChatbotPlaceholder";
import { WelcomeModal } from "../ModalDialog/WelcomeModal";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const Container = styled("header")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.background.base};
  box-shadow: ${(props) => props.theme.styles.shadows.header};
  padding: 0;
  width: calc(100%);
  z-index: 10;
`;

const TabList = styled("menu")`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
`;

function Tab(props: NavLinkProps): React.ReactElement {
  return (
    <Button
      as={NavLink}
      style={{ padding: "20px 32px" }}
      {...props}
      buttonType={ButtonType.Nav}
    />
  );
}

export interface ITabHeaderProps {}
/** A panel along the top of the screen. */
export function Header(
  props: React.PropsWithChildren<ITabHeaderProps>,
): React.ReactElement | null {
  const auth = useIsAuthenticated();
  const { accounts } = useMsal();
  const location = useLocation();
  const state = location.state as Record<string, string> | undefined;

  if (!auth) {
    return null;
  }

  // Welcome Modal
  const modalShortTerm = JSON.parse(
    window.localStorage.getItem("welcome_modal_start") ?? JSON.stringify(true),
  );
  const modalLongTerm = JSON.parse(
    window.localStorage.getItem("welcome_modal_repeat") ?? JSON.stringify(true),
  );

  const [isWelcomeModalOpen, setWelcomeModalOpen] = useState(
    modalShortTerm ? modalLongTerm : modalShortTerm,
  );

  // Chat bot
  const [isChatbotOpen, setChatbotOpen] = useState(true);

  return (
    <>
      <ChatbotPlaceholder
        isChatbotOpen={isChatbotOpen}
        onClose={() => {
          setChatbotOpen(false);
        }}
      />
      <WelcomeModal
        isModalOpen={isWelcomeModalOpen}
        onCancel={() => {
          window.localStorage.setItem(
            "welcome_modal_repeat",
            JSON.stringify(modalLongTerm),
          );
          window.localStorage.setItem(
            "welcome_modal_start",
            JSON.stringify(false),
          );
          setWelcomeModalOpen(false);
        }}
        repeat={modalShortTerm}
        top={"10em"}
      />
      <Routes>
        <Route
          path="*"
          element={
            <Container>
              <TabList>
                <Tab to="/" aria-label="Home" state={state}>
                  <AccentureLogo />
                </Tab>
                <Tab to="/workspaces" aria-label="Workspaces" state={state}>
                  Workspaces
                </Tab>
                <Tab to="/dashboard" aria-label="Dashboard" state={state}>
                  Dashboard
                </Tab>
              </TabList>
              {props.children}
              <Dropdown
                title={
                  <>
                    {accounts[0]?.username}&nbsp;
                    <MdOutlineAccountCircle
                      style={{ paddingLeft: "16px" }}
                      size={"40px"}
                    />
                  </>
                }
                orientation="right"
              >
                <Dropdown.Button
                  onClick={() => {
                    window.localStorage.setItem(
                      "welcome_modal_start",
                      JSON.stringify(true),
                    );
                    setWelcomeModalOpen(true);
                    console.log("open welcome modal");
                  }}
                >
                  Tutorial
                </Dropdown.Button>
                <Dropdown.Link
                  to={`/account-settings/${accounts[0]?.username}`}
                >
                  Account settings
                </Dropdown.Link>
                <Dropdown.Link to="/logout">Logout</Dropdown.Link>
              </Dropdown>
            </Container>
          }
        />
      </Routes>
    </>
  );
}
