import React from "react";
import { styled } from "@mui/material/styles";
import { ITheme } from "./ThemesContainer";
import { Button, ButtonType } from "@accenture/energy-transition-ui-library";

const CardContainer = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background.sidebar};
  border-radius: 8px;
  width: 25%;

  .b3,
  h3,
  button {
    margin: 0;
  }
`;
const IconContainer = styled("div")`
  background: ${(props) => props.theme.colors.button.primary};
  font-size: 100px;
  width: 100%;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
`;

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Content = styled(Section)`
  padding: 24px;
  gap: 24px;
  width: calc(100% - 48px);
  height: calc(100% - 202px);
  justify-content: space-between;
`;

const TextContaier = styled(Section)`
  gap: 16px;
`;

const DescriptionContainer = styled(Section)`
  padding: 16px 0px 0px;
  gap: 16px;
  border-top: 2px solid ${(props) => props.theme.colors.separators.major};
`;

const ActionContainer = styled(Section)`
  align-items: flex-end;
  gap: 10px;
`;

interface ThemeCardProps {
  theme: ITheme;
  onClick: () => void;
}

export const ThemeCard = ({ theme, onClick }: ThemeCardProps) => {
  const { icon, text, title } = theme;

  return (
    <CardContainer>
      <IconContainer>{icon}</IconContainer>
      <Content>
        <TextContaier>
          <h3>{title}</h3>
          <DescriptionContainer className="b3">{text}</DescriptionContainer>
        </TextContaier>
        <ActionContainer>
          <Button buttonType={ButtonType.Primary} onClick={onClick}>
            Open
          </Button>
        </ActionContainer>
      </Content>
    </CardContainer>
  );
};
