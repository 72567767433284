import React from "react";

export const OtherFuelTypesIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 24C0.955556 24 0.638889 23.8722 0.383333 23.6167C0.127778 23.3611 0 23.0444 0 22.6667V2.66667C0 1.93333 0.261111 1.30556 0.783333 0.783333C1.30556 0.261111 1.93333 0 2.66667 0H10.6667C11.4 0 12.0278 0.261111 12.55 0.783333C13.0722 1.30556 13.3333 1.93333 13.3333 2.66667V12H14.6667C15.4 12 16.0278 12.2611 16.55 12.7833C17.0722 13.3056 17.3333 13.9333 17.3333 14.6667V20.6667C17.3333 21.0444 17.4611 21.3611 17.7167 21.6167C17.9722 21.8722 18.2889 22 18.6667 22C19.0444 22 19.3611 21.8722 19.6167 21.6167C19.8722 21.3611 20 21.0444 20 20.6667V11.0667C19.8 11.1778 19.5889 11.25 19.3667 11.2833C19.1444 11.3167 18.9111 11.3333 18.6667 11.3333C17.7333 11.3333 16.9444 11.0111 16.3 10.3667C15.6556 9.72222 15.3333 8.93333 15.3333 8C15.3333 7.28889 15.5278 6.65 15.9167 6.08333C16.3056 5.51667 16.8222 5.11111 17.4667 4.86667L15.3667 2.76667C15.1667 2.56667 15.0667 2.33333 15.0667 2.06667C15.0667 1.8 15.1667 1.56667 15.3667 1.36667C15.5667 1.16667 15.8 1.06667 16.0667 1.06667C16.3333 1.06667 16.5667 1.15556 16.7667 1.33333L21 5.46667C21.3333 5.8 21.5833 6.18889 21.75 6.63333C21.9167 7.07778 22 7.53333 22 8V20.6667C22 21.6 21.6778 22.3889 21.0333 23.0333C20.3889 23.6778 19.6 24 18.6667 24C17.7333 24 16.9444 23.6778 16.3 23.0333C15.6556 22.3889 15.3333 21.6 15.3333 20.6667V14H13.3333V22.6667C13.3333 23.0444 13.2056 23.3611 12.95 23.6167C12.6944 23.8722 12.3778 24 12 24H1.33333ZM2.66667 9.33333H10.6667V2.66667H2.66667V9.33333ZM18.6667 9.33333C19.0444 9.33333 19.3611 9.20556 19.6167 8.95C19.8722 8.69444 20 8.37778 20 8C20 7.62222 19.8722 7.30556 19.6167 7.05C19.3611 6.79444 19.0444 6.66667 18.6667 6.66667C18.2889 6.66667 17.9722 6.79444 17.7167 7.05C17.4611 7.30556 17.3333 7.62222 17.3333 8C17.3333 8.37778 17.4611 8.69444 17.7167 8.95C17.9722 9.20556 18.2889 9.33333 18.6667 9.33333Z"
        fill="black"
      />
    </svg>
  );
};
