import React, { useEffect } from "react";
import { NavigationControl, useControl, useMap } from "react-map-gl/maplibre";
import {
  Map as MapComponent,
  Spinner,
} from "@accenture/energy-transition-ui-library";
import { MapLayers } from "./MapLayers";
import { GeocoderControl } from "./controls/GeocoderControl";
import { clearActiveLayers, selectAreLayersLoading } from "../redux/mapSlice";
import { useSelector } from "react-redux";
import { LayerInfoSidebar } from "./LayerInfoSidebar";
import { useAppDispatch } from "../redux/store";
import { MapboxOverlay, MapboxOverlayProps } from "@deck.gl/mapbox/typed";
import { RunPrioritizationEVChargersLayerDECKGL } from "./layers/RunPrioritizationEVChargersLayerDECKGL";
import { selectIsDarkModeOn } from "../redux/appSlice";

declare const AWS_API_URI: string;
declare const AWS_API_KEY: string;

function DeckGLOverlay(
  props: MapboxOverlayProps & {
    interleaved?: boolean;
  },
) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export const Map = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();
  const isDarkModeOn = useSelector(selectIsDarkModeOn);
  const layersLoading = useSelector(selectAreLayersLoading);

  useEffect(() => {
    return () => {
      dispatch(clearActiveLayers());
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    onMapReady();
  }, [map]);

  const onMapReady = () => {
    if (!map) return;
    // Map is ready and available!
    map.on("load", () => {
      console.log("map loaded");
    });
    map.on("moveend", () => {
      console.log(map.getZoom());
    });
  };

  return (
    <>
      <MapComponent
        id="map"
        height="calc(100vh - 80px - 163px)"
        darkMode={isDarkModeOn}
        layers={<MapLayers />}
        transformRequest={(url: string) => {
          if (url.startsWith(AWS_API_URI)) {
            return {
              url: url,
              headers: {
                "x-api-key": AWS_API_KEY,
                Accept: "application/x-protobuf",
              },
            };
          }
          return;
        }}
      >
        <DeckGLOverlay layers={[RunPrioritizationEVChargersLayerDECKGL()]} />
        <NavigationControl position="bottom-left" />
        <GeocoderControl />
        <LayerInfoSidebar />
        <Spinner isLoading={layersLoading} />
      </MapComponent>
    </>
  );
};
