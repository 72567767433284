import * as React from "react";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Data, Order, StyledTableCell, StyledTableRow } from "./UserActivity";

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "userEmail",
    numeric: false,
    disablePadding: false,
    label: "User Email",
  },
  {
    id: "userRole",
    numeric: false,
    disablePadding: false,
    label: "User Role",
  },
  {
    id: "businessUnit",
    numeric: false,
    disablePadding: false,
    label: "Business Unit",
  },
  {
    id: "monthlyLogins",
    numeric: false,
    disablePadding: false,
    label: "Monthly Logins",
  },
  {
    id: "workspaces",
    numeric: false,
    disablePadding: false,
    label: "Workspaces",
  },
  {
    id: "dataUploads",
    numeric: false,
    disablePadding: false,
    label: "Data Uploads",
  },
];

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
