import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { MetricItem } from "@accenture/energy-transition-ui-library";
import EnhancedTableHead from "./EnhancedTableHead";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: theme.palette.common.black,
    border: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    border: "1px solid black",
    fontFamily: "Graphik",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "-0.28px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export interface Data {
  userEmail: string;
  userRole: string;
  businessUnit: string;
  monthlyLogins: string;
  workspaces: string;
  dataUploads: string;
}

function createData(
  userEmail: string,
  userRole: string,
  businessUnit: string,
  monthlyLogins: string,
  workspaces: string,
  dataUploads: string,
): Data {
  return {
    userEmail,
    userRole,
    businessUnit,
    monthlyLogins,
    workspaces,
    dataUploads,
  };
}

const rows = [
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
  createData("Label", "Label", "Label", "Label", "Label", "Label"),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function UserActivity() {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("userEmail");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <MetricItem
      width="100%"
      height="494px"
      title="User Activity"
      source="Label"
      padding="20px"
    >
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <StyledTableRow
                    hover
                    onClick={(event) => handleClick(event, row.userEmail)}
                    role="checkbox"
                    aria-checked={isSelected(row.userEmail)}
                    tabIndex={-1}
                    key={row.userEmail + index}
                    selected={isSelected(row.userEmail)}
                  >
                    <StyledTableCell
                      component="th"
                      id={`enhanced-table-checkbox-${index}`}
                      scope="row"
                    >
                      {row.userEmail}
                    </StyledTableCell>
                    <StyledTableCell>{row.userRole}</StyledTableCell>
                    <StyledTableCell>{row.businessUnit}</StyledTableCell>
                    <StyledTableCell>{row.monthlyLogins}</StyledTableCell>
                    <StyledTableCell>{row.workspaces}</StyledTableCell>
                    <StyledTableCell>{row.dataUploads}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: 33,
                    }}
                  >
                    <StyledTableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 7]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </MetricItem>
  );
}
