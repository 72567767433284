import React from "react";

export const ChevronDownIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1826_47486"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1826_47486)">
        <path
          d="M12.0002 14.9498C11.8668 14.9498 11.7418 14.9289 11.6252 14.8873C11.5085 14.8456 11.4002 14.7748 11.3002 14.6748L6.67515 10.0498C6.49182 9.86643 6.40432 9.63726 6.41265 9.36226C6.42099 9.08726 6.51682 8.8581 6.70015 8.67476C6.88349 8.49143 7.11682 8.39976 7.40015 8.39976C7.68349 8.39976 7.91682 8.49143 8.10015 8.67476L12.0002 12.5748L15.9252 8.64976C16.1085 8.46643 16.3377 8.37893 16.6127 8.38726C16.8877 8.3956 17.1168 8.49143 17.3002 8.67476C17.4835 8.8581 17.5752 9.09143 17.5752 9.37476C17.5752 9.6581 17.4835 9.89143 17.3002 10.0748L12.7002 14.6748C12.6002 14.7748 12.4918 14.8456 12.3752 14.8873C12.2585 14.9289 12.1335 14.9498 12.0002 14.9498Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ChevronDownIcon;
