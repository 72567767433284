import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { OtherFuelTypesIcon } from "@accenture/energy-transition-ui-library";
import BiodieselDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/BiodieselDefaultPin.png";
import BiodieselHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/BiodieselHoverPin.png";
import BiodieselSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/BiodieselSelectedPin.png";
import CNGDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/CNGDefaultPin.png";
import CNGHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/CNGHoverPin.png";
import CNGSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/CNGSelectedPin.png";
import EthanolDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/EthanolDefaultPin.png";
import EthanolHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/EthanolHoverPin.png";
import EthanolSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/EthanolSelectedPin.png";
import HydrogenDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/HydrogenDefaultPin.png";
import HydrogenHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/HydrogenHoverPin.png";
import HydrogenSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/HydrogenSelectedPin.png";
import LNGDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/LNGDefaultPin.png";
import LNGHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/LNGHoverPin.png";
import LNGSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/LNGSelectedPin.png";
import PropaneDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/PropaneDefaultPin.png";
import PropaneHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/PropaneHoverPin.png";
import PropaneSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/PropaneSelectedPin.png";
import RenewableDieselDefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/RenewableDieselDefaultPin.png";
import RenewableDieselHoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/RenewableDieselHoverPin.png";
import RenewableDieselSelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/RenewableDieselSelectedPin.png";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const otherFuelTypesLayerMetadata: LayerMetadata = {
  layerName: "Other Fuel Types",
  sourceID: "other-fuel-types-layer-source",
  layerID: "other-fuel-types-layer",
  clusterLayerID: "other-fuel-types-layer-cluster",
  dataset: "afdc_chargers",
  toggleIcon: OtherFuelTypesIcon,
  description:
    "This dataset displays the location of the following fuel type stations: Biodiesel, CNG, Ethanol, Hydrogen, LNG, Propane, and Renewable Diesel.",
  layerImages: {
    biodieselDefaultPin: BiodieselDefaultPin,
    biodieselHoverPin: BiodieselHoverPin,
    biodieselSelectedPin: BiodieselSelectedPin,
    CNGDefaultPin: CNGDefaultPin,
    CNGHoverPin: CNGHoverPin,
    CNGSelectedPin: CNGSelectedPin,
    ethanolDefaultPin: EthanolDefaultPin,
    ethanolHoverPin: EthanolHoverPin,
    ethanolSelectedPin: EthanolSelectedPin,
    hydrogenDefaultPin: HydrogenDefaultPin,
    hydrogenHoverPin: HydrogenHoverPin,
    hydrogenSelectedPin: HydrogenSelectedPin,
    LNGDefaultPin: LNGDefaultPin,
    LNGHoverPin: LNGHoverPin,
    LNGSelectedPin: LNGSelectedPin,
    propaneDefaultPin: PropaneDefaultPin,
    propaneHoverPin: PropaneHoverPin,
    propaneSelectedPin: PropaneSelectedPin,
    renewableDieselDefaultPin: RenewableDieselDefaultPin,
    renewableDieselHoverPin: RenewableDieselHoverPin,
    renewableDieselSelectedPin: RenewableDieselSelectedPin,
  },
  fieldsToDisplay: {
    access_code: "Access Code",
    access_days_time: "Access Days/Time",
    access_detail_code: "Access Details",
    date_last_confirmed: "Date Last Confirmed",
    facility_type: "Facility Type",
    fuel_type_code: "Fuel Type",
    station_phone: "Station Phone Number",
    street_address: "Street Address",
    zip: "Zip",
  },
  theme: [DataSetTheme.EnergyInfrastructure],
};

export const OtherFuelTypesLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<number>(0);
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, otherFuelTypesLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, otherFuelTypesLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", otherFuelTypesLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.id);
      }
    });
    map.on("mouseleave", otherFuelTypesLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID(0);
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: otherFuelTypesLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${otherFuelTypesLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
    // filter: ["!=", ["get", "fuel_type_code"], "ELEC"],
    // cluster: true,
    // clusterMaxZoom: 12, // Max zoom to cluster points on
    // clusterRadius: 100, // Radius of each cluster when clustering points (defaults to 50)
  };

  const layerClusterConfig: LayerProps = {
    id: otherFuelTypesLayerMetadata.clusterLayerID,
    source: otherFuelTypesLayerMetadata.sourceID,
    "source-layer": otherFuelTypesLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Point),
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#fff",
      "circle-stroke-color": "#000",
      "circle-stroke-width": 2,
      "circle-radius": 30,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerClusterCountConfig: LayerProps = {
    id: otherFuelTypesLayerMetadata.clusterLayerID + "-count",
    source: otherFuelTypesLayerMetadata.sourceID,
    "source-layer": otherFuelTypesLayerMetadata.dataset,
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Graphik", "Arial Unicode MS Bold"],
      "text-size": 24,
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerPointConfig: LayerProps = {
    id: otherFuelTypesLayerMetadata.layerID,
    source: otherFuelTypesLayerMetadata.sourceID,
    "source-layer": otherFuelTypesLayerMetadata.dataset,
    filter: ["!", ["has", "point_count"]],
    type: "symbol",
    layout: {
      "icon-image": [
        "case",
        [
          "in",
          ["get", "id"],
          ["literal", selectedFeatures.map((f) => f.properties.id)],
        ],
        // Check against different fuel types to get SELECTED icon
        [
          "case",
          ["==", ["get", "fuel_type_code"], "BD"],
          "biodieselSelectedPin",
          ["==", ["get", "fuel_type_code"], "CNG"],
          "CNGSelectedPin",
          ["==", ["get", "fuel_type_code"], "E85"],
          "ethanolSelectedPin",
          ["==", ["get", "fuel_type_code"], "HY"],
          "hydrogenSelectedPin",
          ["==", ["get", "fuel_type_code"], "LNG"],
          "LNGSelectedPin",
          ["==", ["get", "fuel_type_code"], "LPG"],
          "propaneSelectedPin",
          ["==", ["get", "fuel_type_code"], "RD"],
          "renewableDieselSelectedPin",
          "biodieselSelectedPin", // TODO replace with selected generic fuel pin
        ],
        ["==", ["get", "id"], hoveredFeatureID],
        // Check against different fuel types to get HOVER icon
        [
          "case",
          ["==", ["get", "fuel_type_code"], "BD"],
          "biodieselHoverPin",
          ["==", ["get", "fuel_type_code"], "CNG"],
          "CNGHoverPin",
          ["==", ["get", "fuel_type_code"], "E85"],
          "ethanolHoverPin",
          ["==", ["get", "fuel_type_code"], "HY"],
          "hydrogenHoverPin",
          ["==", ["get", "fuel_type_code"], "LNG"],
          "LNGHoverPin",
          ["==", ["get", "fuel_type_code"], "LPG"],
          "propaneHoverPin",
          ["==", ["get", "fuel_type_code"], "RD"],
          "renewableDieselHoverPin",
          "biodieselHoverPin", // TODO replace with hover generic fuel pin
        ],
        // Check against different fuel types to get Default icon
        [
          "case",
          ["==", ["get", "fuel_type_code"], "BD"],
          "biodieselDefaultPin",
          ["==", ["get", "fuel_type_code"], "CNG"],
          "CNGDefaultPin",
          ["==", ["get", "fuel_type_code"], "E85"],
          "ethanolDefaultPin",
          ["==", ["get", "fuel_type_code"], "HY"],
          "hydrogenDefaultPin",
          ["==", ["get", "fuel_type_code"], "LNG"],
          "LNGDefaultPin",
          ["==", ["get", "fuel_type_code"], "LPG"],
          "propaneDefaultPin",
          ["==", ["get", "fuel_type_code"], "RD"],
          "renewableDieselDefaultPin",
          "biodieselDefaultPin", // TODO replace with default generic fuel pin
        ],
      ],
      "icon-allow-overlap": true,
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerClusterConfig} />
      <Layer {...layerClusterCountConfig} />
      <Layer {...layerPointConfig} />
    </Source>
  );
};
OtherFuelTypesLayer.metadata = otherFuelTypesLayerMetadata;
