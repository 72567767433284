import React, { useState } from "react";
import { styled } from "@mui/material";
import ChevronUpIcon from "../Assets/ChevronIcons/ChevronUpIcon";
import ChevronDownIcon from "../Assets/ChevronIcons/ChevronDownIcon";

export const PanelBox = styled("div")<{ gap?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.gap ?? "12px"};
  h4,
  p {
    margin: 0px;
  }
`;

export const PanelSubTitle = styled("div")<{ gap?: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PanelTitle = styled(PanelSubTitle)<{ gap?: string }>`
  cursor: pointer;

  h4 {
    display: flex;
    align-items: center;
  }
`;

export const IconWrapper = styled("div")`
  padding-right: 10px;
  display: flex;
  align-items: center;
  max-width: 24px;
  max-height: 22px;
`;

interface CollapsiblePanelProps {
  title: string;
  children: React.ReactElement;
  defaultState?: boolean;
  icon?: React.ReactElement;
}

export const CollapsiblePanel = ({
  title,
  children,
  defaultState = true,
  icon = undefined,
}: CollapsiblePanelProps) => {
  const [isActive, setIsActive] = useState(defaultState);
  return (
    <PanelBox gap="8px" className="panel-container">
      <PanelTitle
        onClick={() => setIsActive((prev) => !prev)}
        className="panel-title"
      >
        <h4>
          {icon ? <IconWrapper>{icon}</IconWrapper> : <></>}
          {title}
        </h4>
        <>{isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}</>
      </PanelTitle>
      {isActive && <>{children}</>}
    </PanelBox>
  );
};

export default CollapsiblePanel;
