import React, { forwardRef, InputHTMLAttributes } from "react";
import { styled } from "@mui/material/styles";
import { InputBar, InputContainer, Label } from "../Input/Input";

const InputColor = styled("input")`
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-appearance: none;
  border-radius: 50%;

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::-webkit-color-swatch {
    border: 2px solid ${(props) => props.theme.colors.button.inactive};
    border-radius: 50%;
  }

  :hover::-webkit-color-swatch {
    border: 4px solid ${(props) => props.theme.colors.button.primary};
  }
  :focus::-webkit-color-swatch {
    border: 4px solid ${(props) => props.theme.colors.button.primary};
  }
`;

const ColorPickerContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

interface ColorPickerProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ColorPicker = forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<ColorPickerProps>
>(function ColorPicker(props, ref) {
  return (
    <InputContainer>
      {props.title && (
        <Label htmlFor={props.id}>
          <h4>{props.title}</h4>
        </Label>
      )}
      <ColorPickerContainer>
        <InputColor
          ref={ref}
          id={props.id + "-input"}
          type="color"
          onChange={props.onChange}
        />
        <InputBar
          id={props.id + "-label"}
          type="text"
          disabled
          placeholder={props.value ? props.value : "#000000"}
        />
      </ColorPickerContainer>
    </InputContainer>
  );
});

export default ColorPicker;
