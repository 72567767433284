import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const housingBurdenLayerMetadata: LayerMetadata = {
  layerName: "Housing Burden",
  sourceID: "housing-burden-layer-source",
  layerID: "housing-burden-layer",
  dataset: "cejst",
  toggleDotColor: "#008000",
  description:
    "This dataset highlights disadvantaged communities with housing burdens. These disadvantaged communities are in census tracts that experienced historic underinvestment, are at or above the 90th percentile for housing cost, lack of green space, lack of indoor plumbing, or lead paint and are at or above the 65th percentile for low income.",
  fieldsToDisplay: {
    "Low median household income as a percent of area median income (percentile)":
      "Low median household income as a percent of area median income (percentile)",
    "Housing burden (percent) (percentile)":
      "Housing burden (percent) (percentile)",
    "Percent pre-1960s housing (lead paint indicator) (percentile)":
      "Percent pre-1960s housing (lead paint indicator) (percentile)",
    "Share of homes with no kitchen or indoor plumbing (percent) (percentile)":
      "Share of homes with no kitchen or indoor plumbing (percent) (percentile)",
    "Share of the tract's land area that is covered by impervious surface or cropland as a percent (percentile)":
      "Share of the tract's land area that is covered by impervious surface or cropland as a percent (percentile)",
  },
  theme: [DataSetTheme.UrbanPlanning],
};

export const HousingBurden = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, housingBurdenLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, housingBurdenLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", housingBurdenLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", housingBurdenLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: housingBurdenLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${housingBurdenLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: housingBurdenLayerMetadata.layerID,
    source: housingBurdenLayerMetadata.sourceID,
    "source-layer": housingBurdenLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": housingBurdenLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Housing Factor (Definition N)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HousingBurden.metadata = housingBurdenLayerMetadata;
