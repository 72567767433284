import React from "react";
import { styled } from "@mui/material/styles";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { darkTheme } from "../Theme/Theme";

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* overflow: hidden; */
  position: relative;
  z-index: 0;
`;

const ButtonContainer = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
`;

const LeftButton = styled("button")`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 0 solid;
  gap: 8px;
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  padding: 0;
  float: left;
  position: absolute;
  z-index: 2;
`;

const RightButton = styled(LeftButton)`
  right: 0;
`;
interface CarouselProps {
  children: JSX.Element | JSX.Element[];
  itemQuantity: number;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function CardsCarousel(props: CarouselProps) {
  const next = () => {
    if (props.currentIndex < props.itemQuantity - 1) {
      props.setCurrentIndex((prevState: number): number => prevState + 4);
    }
  };

  const prev = () => {
    if (props.currentIndex > 0) {
      props.setCurrentIndex((prevState) => prevState - 4);
    }
  };

  return (
    <Container>
      <ButtonContainer>
        {props.currentIndex > 0 && (
          <LeftButton onClick={prev}>
            <RiArrowLeftSLine
              size={"2em"}
              style={{ color: darkTheme.colors.text.major }}
            />
          </LeftButton>
        )}
      </ButtonContainer>

      {props.children}

      <ButtonContainer>
        {props.currentIndex < props.itemQuantity - 4 &&
          props.itemQuantity > 4 && (
            <RightButton onClick={next}>
              <RiArrowRightSLine
                size={"2em"}
                style={{ color: darkTheme.colors.text.major }}
              />
            </RightButton>
          )}
      </ButtonContainer>
    </Container>
  );
}

export default CardsCarousel;
