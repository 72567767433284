import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import MetricItemContent from "./MetricItemContent";

interface IMetricCardProps {
  title: string;
  source: string;
  width: string;
  height: string;
  align?: string;
  children?: JSX.Element | JSX.Element[];
  padding?: string;
  expandDisabled?: boolean;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const MetricItem = (props: IMetricCardProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MetricItemContent
        {...props}
        isModalOpen={false}
        handleOpen={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <MetricItemContent
            {...props}
            width="840px"
            height="700px"
            isModalOpen={true}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default MetricItem;
