import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Form, ColorPicker } from "@accenture/energy-transition-ui-library";
import {
  Workspace,
  DataSourceTag,
  SaveToDataCatalog,
  DataSetTheme,
  IWorkspace,
} from "@accenture/energy-transition-interfaces";
import { workspaceListQuery } from "../../../Workspaces/workspaceQueries";
import { useQuery } from "@tanstack/react-query";
import { FileTypes } from "./UploadDatasetPage";
import { useMsal } from "@azure/msal-react";
export interface IDataDetails {
  latColName?: string;
  lonColName?: string;
  dataName: string;
  workspace: string;
  theme: string[];
  dataSourceTag: string;
  saveToDataCatalog: string;
  color: string;
  description: string;
}

export interface IUploadModalProps {
  onCancel: () => void;
  setDetails: (dataDetails: IDataDetails) => void;
  handleModalPage: (page: string) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  file: File | null;
}

export const UploadDetailsPage = (
  props: React.PropsWithChildren<IUploadModalProps>,
) => {
  const { accounts } = useMsal();
  const { data: workspaces } = useQuery<IWorkspace[]>({
    queryKey: workspaceListQuery(accounts[0].username || "").queryKey,
    queryFn: workspaceListQuery(accounts[0].username || "").queryFn,
    refetchOnMount: true,
  });

  const { setDetails, onSubmit, file } = props;
  const [workspaceOptions, setWorkspaceOptions] = useState<
    (string | Workspace)[]
  >([Workspace.All, Workspace.None]);

  useEffect(() => {
    if (workspaces && workspaces.length > 0) {
      const workspaceNames = workspaces.map((w) => w.workspace_name);
      setWorkspaceOptions([Workspace.All, ...workspaceNames, Workspace.None]);
    }
  }, [workspaces]);

  const initialInputValue = {
    value: "",
    isValid: false,
    id: "",
  };
  const [dataName, setDataName] = useState(initialInputValue);
  const [workspace, setWorkspace] = useState(initialInputValue);
  const [theme, setTheme] = useState({ value: [""], isValid: false, id: "" });
  const [dataSourceTag, setDataSourceTag] = useState(initialInputValue);
  const [saveToDataCatalog, setSaveToDataCatalog] = useState(initialInputValue);
  const [description, setDescription] = useState(initialInputValue);
  const [latColName, setLatColName] = useState(initialInputValue);
  const [lonColName, setLonColName] = useState(initialInputValue);
  const [color, setColor] = useState(initialInputValue);

  const geojsonInputs = [
    dataName,
    workspace,
    theme,
    dataSourceTag,
    saveToDataCatalog,
    color,
    description,
  ];
  const csvInputs = [...geojsonInputs, latColName, lonColName];
  const inputs = file?.type === FileTypes.CSV ? csvInputs : geojsonInputs;

  const handleDataDetails = useCallback(
    (e: React.SyntheticEvent) => {
      const detailsArray = inputs.map((input) => [input.id, input.value]);
      const detailsObj = Object.fromEntries(detailsArray);
      setDetails(detailsObj);
      onSubmit(e);
    },
    [inputs],
  );

  const disableSubmitButton = useMemo(() => {
    return inputs
      .map((input) => input.isValid)
      .some((state) => state === false);
  }, inputs);

  const onChangeOption = useCallback((value: string, id: string) => {
    let isValid = false;
    if (value !== "") isValid = true;
    switch (id) {
      case "latColName":
        setLatColName({ value, isValid, id });
        break;
      case "lonColName":
        setLonColName({ value, isValid, id });
        break;
      case "dataName":
        setDataName({ value, isValid, id });
        break;
      case "workspace":
        setWorkspace({ value, isValid, id });
        break;
      case "theme":
        setTheme((prev) => {
          if (prev.value.includes("")) {
            return { value: [value], isValid, id };
          }
          if (prev.value.includes(value)) {
            const newTheme = prev.value.filter((theme) => theme !== value);
            return { value: newTheme, isValid, id };
          }
          return { value: [...prev.value, value], isValid, id };
        });
        break;
      case "dataSourceTag":
        setDataSourceTag({ value, isValid, id });
        break;
      case "saveToDataCatalog":
        setSaveToDataCatalog({ value, isValid, id });
        break;
      case "description":
        setDescription({ value, isValid, id });
        break;
      case "color":
        setColor({ value, isValid, id });
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <Form.Content>
        <div>
          <h2>Upload Details</h2>
          <p className="b2">Please fill out all of the details below.</p>
        </div>
        {file?.type === FileTypes.CSV && (
          <Form.InputContainer>
            <Form.Input
              onChange={(event) =>
                onChangeOption(event.target.value, "latColName")
              }
              title="Latitude Column Name"
              placeholder="Enter name"
            />
            <Form.Input
              onChange={(event) =>
                onChangeOption(event.target.value, "lonColName")
              }
              title="Longitude Column Name"
              placeholder="Enter name"
            />
          </Form.InputContainer>
        )}
        <Form.InputContainer>
          <Form.Input
            onChange={(event) => onChangeOption(event.target.value, "dataName")}
            title="Data Upload Name"
            placeholder="Enter name"
          />
          <Form.Dropdown
            id="workspace"
            title="Workspace"
            placeholder="Select Workspace"
            dropdownOptions={workspaceOptions}
            handleSelection={onChangeOption}
            value={workspace.value}
          />
        </Form.InputContainer>
        <Form.InputContainer>
          <Form.Dropdown
            id="theme"
            title="Theme Tag"
            placeholder="Select tag(s)"
            dropdownOptions={Object.values(DataSetTheme)}
            handleSelection={onChangeOption}
            value={theme.value}
            multiselect
          />
          <Form.Dropdown
            id="dataSourceTag"
            title="Data Source Tag"
            placeholder="Select tag"
            dropdownOptions={Object.values(DataSourceTag)}
            handleSelection={onChangeOption}
            value={dataSourceTag.value}
          />
        </Form.InputContainer>
        <Form.InputContainer>
          <Form.Dropdown
            id="saveToDataCatalog"
            title="Save to Data Catalog"
            placeholder="Select yes or no"
            dropdownOptions={Object.values(SaveToDataCatalog)}
            handleSelection={onChangeOption}
            value={saveToDataCatalog.value}
          />
          <ColorPicker
            onChange={(e) =>
              onChangeOption(e.target.value.toUpperCase(), "color")
            }
            value={color.value}
            id="color-picker"
            title="Color Picker"
          />
        </Form.InputContainer>
        <Form.Textarea
          onChange={(event) =>
            onChangeOption(event.target.value, "description")
          }
          title="Description"
          placeholder="Enter description"
          id="description"
          rows={8}
          cols={62}
        />
      </Form.Content>
      <Form.Divider />
      <Form.ButtonContainer>
        <Form.StyledButton
          id="data-clasification"
          disabled={disableSubmitButton}
          onClick={handleDataDetails}
          type="button"
        >
          ADD DATA
        </Form.StyledButton>
      </Form.ButtonContainer>
    </>
  );
};

export default UploadDetailsPage;
