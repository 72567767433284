import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { loadAppState, saveAppState } from "../utils/localStorage";

const persistedState = loadAppState();

export interface InitialAppState {
  darkMode: boolean;
}

const initialValue: InitialAppState = {
  darkMode: false,
  ...persistedState,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialValue,
  reducers: {
    toggleDarkMode: (state, action) => {
      state.darkMode = action.payload;
      saveAppState(state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleDarkMode } = appSlice.actions;

// Selectors
export const selectIsDarkModeOn = (state: RootState) => state.app.darkMode;

export default appSlice.reducer;
