import React, { useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { MdMoreVert } from "react-icons/md";
import { IWorkspace } from "@accenture/energy-transition-interfaces";
import { Dropdown } from "@accenture/energy-transition-ui-library";
import { DeleteWorkspace } from "./modals/DeleteWorkspace";
import { WorkspaceMetadata } from "./modals/WorkspaceMetadata";

const WorkspaceCardWrapper = styled("div")<{ index: number }>`
  width: calc(25% - 18px);
  &:nth-of-type(n + 3) {
    margin-left: 24px;
  }
`;

const WorkspaceButton = styled(Dropdown.Button)``;

const WorkspaceDropdown = styled(Dropdown)`
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.button.primary};
  background-color: ${(props) => props.theme.colors.background.base};
  padding: 10px;
  float: left;
  width: 48px;
  height: 48px;
`;
const WorkspaceLink = styled(Link)`
  display: flex;
  color: ${(props) => props.theme.colors.text.major};
  text-decoration: none;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 96px;
  h4 {
    margin: 0;
  }
`;

const WorkspaceInfo = styled("div")`
  height: 80px;
  display: flex;
  color: ${(props) => props.theme.colors.text.major};
  background-color: ${(props) => props.theme.colors.background.sidebar};
  border-radius: 8px;
  align-items: center;
`;

export interface WorkspaceCardProps {
  workspace: IWorkspace;
  index?: number;
}

export function WorkspaceCard({ workspace, index = 0 }: WorkspaceCardProps) {
  const theme = useTheme();
  const { workspace_name, workspace_globalid } = workspace;
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const dropdownRef = useRef<Dropdown>(null);

  return (
    <>
      <WorkspaceCardWrapper index={index}>
        <WorkspaceInfo>
          <WorkspaceDropdown
            className="dropdown"
            ref={dropdownRef}
            title={<MdMoreVert size={"24px"} color={theme.colors.text.major} />}
            orientation="left"
            inset="10px 24px"
            position="absolute"
          >
            <WorkspaceButton
              onClick={(e) => {
                setRenameModalOpen(true);
                e.preventDefault();
              }}
            >
              Rename
            </WorkspaceButton>
            <WorkspaceButton
              onClick={(e) => {
                setDeleteModalOpen(true);
                e.preventDefault();
              }}
            >
              Delete
            </WorkspaceButton>
          </WorkspaceDropdown>
          <WorkspaceLink to={`/workspaces/${workspace_globalid}/map`}>
            <h4>{workspace_name}</h4>
          </WorkspaceLink>
        </WorkspaceInfo>
      </WorkspaceCardWrapper>

      <WorkspaceMetadata
        workspace={workspace}
        isModalOpen={isRenameModalOpen}
        onCancel={() => {
          setRenameModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
        onSave={() => {
          setRenameModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
        action="rename"
      />
      <DeleteWorkspace
        workspace={workspace}
        isModalOpen={isDeleteModalOpen}
        onCancel={() => {
          setDeleteModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
        onConfirm={() => {
          setDeleteModalOpen(false);
          if (dropdownRef.current) {
            dropdownRef.current.close();
          }
        }}
      />
    </>
  );
}
