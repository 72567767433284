import React, { TextareaHTMLAttributes } from "react";
import { styled } from "@mui/material/styles";

export const TextareaBar = styled("textarea")`
  width: "564px";
  height: "152px";
  text-align: start;
  resize: none;
  padding: 12px;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.font.family};
  font-size: ${(props) => props.theme.font.size};
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
  color: ${(props) => props.theme.colors.text.major};
  background: ${(props) => props.theme.colors.background.base};
  border: 2px solid ${(props) => props.theme.colors.button.inactive};
  border-radius: 8px;

  &:not(:placeholder-shown) {
    border: 2px solid ${(props) => props.theme.colors.text.minor};
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.button.primary};
  }

  &:focus {
    outline: none;
    border: 4px solid ${(props) => props.theme.colors.button.primary};
  }

  ::placeholder {
    position: absolute;
    top: 12px;
    left: 12px;
    color: ${(props) => props.theme.colors.text.minor};
  }
`;

const InputContainer = styled("div")`
  text-align: left;

  h4 {
    margin: 12px 0 12px;
  }
`;

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  id?: string;
  placeholder?: string;
}

export function Textarea(
  props: React.PropsWithChildren<TextareaProps>,
): React.ReactElement {
  return (
    <InputContainer>
      {props.title && (
        <label htmlFor={props.id}>
          <h4>{props.title}</h4>
        </label>
      )}
      <TextareaBar {...props} as="textarea"></TextareaBar>
    </InputContainer>
  );
}
