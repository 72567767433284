import React from "react";
import { styled } from "@mui/material/styles";
import { IconType } from "react-icons";
import { CheckboxItem } from "../../atoms/Checkbox/Checkbox";

const TitleContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0px;
  }
  h4 {
    margin: 0px;
    color: ${(props) => props.theme.colors.text.minor};
  }
`;

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled("label")<{ marginTop?: string }>`
  display: flex;
  width: 258px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.major};
  margin-top: ${(props) => props.marginTop ?? "0px"};

  &:hover {
    color: #a100ff;
  }
`;

const Dot = styled("span")<{ color: string }>`
  display: block;
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.625em;
  background-color: ${(props) => props.color};
`;

const SemiCircleDot = styled("span")<{ arrColor: Array<string> }>`
  border-radius: 0.625em;
  border-right-color: ${(props) => props.arrColor[0]};
  border-top-color: ${(props) => props.arrColor[1]};
  border-bottom-color: ${(props) => props.arrColor[0]};
  border-left-color: ${(props) => props.arrColor[1]};
  border-width: 0.625em;
  border-style: solid;
  height: 0px;
  width: 0px;
`;

const Span = styled("span")`
  flex: 1;
  letter-spacing: -0.32px;
  line-height: 150%;
`;

const IconSpan = styled(Span)`
  margin-left: 16px;
`;

const CheckboxListContainer = styled(Span)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export interface ICheckboxListProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export function CheckboxList(
  props: React.PropsWithChildren<ICheckboxListProps>,
): React.ReactElement {
  return (
    <CheckboxListContainer>
      {props.title ? (
        <TitleContainer>
          <h3>{props.title}</h3>
        </TitleContainer>
      ) : null}
      <Form>{props.children}</Form>
    </CheckboxListContainer>
  );
}

export interface ICheckboxListItemProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: IconType;
  boxAlign?: "LEFT" | "RIGHT";
  dotColor?: string | string[];
  subtitle?: boolean;
  indeterminate?: boolean;
  marginTop?: string;
}

export function CheckboxListItem(
  props: React.PropsWithChildren<ICheckboxListItemProps>,
): React.ReactElement {
  const {
    icon: Icon,
    dotColor,
    type: _type,
    children,
    subtitle,
    checked,
    onChange,
    name,
    indeterminate,
    marginTop,
  } = props;

  return (
    <Label marginTop={marginTop}>
      {Icon ? <Icon /> : null}
      {dotColor && !Array.isArray(dotColor) ? <Dot color={dotColor} /> : null}
      {dotColor && Array.isArray(dotColor) && dotColor.length === 2 ? (
        <SemiCircleDot arrColor={dotColor} />
      ) : null}
      {props.boxAlign === "LEFT" ? (
        <CheckboxItem
          checked={checked}
          onChange={onChange}
          name={name}
          indeterminate={indeterminate}
        />
      ) : null}
      {subtitle ? (
        <TitleContainer>
          <h4>{children}</h4>
        </TitleContainer>
      ) : (
        <>
          {Icon || dotColor ? (
            <IconSpan>{children}</IconSpan>
          ) : (
            <Span>{children}</Span>
          )}
        </>
      )}

      {!props.boxAlign || props.boxAlign === "RIGHT" ? (
        <CheckboxItem
          checked={checked}
          onChange={onChange}
          name={name}
          indeterminate={indeterminate}
        />
      ) : null}
    </Label>
  );
}

CheckboxList.Item = CheckboxListItem;
