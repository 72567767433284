import React, { useState, useRef } from "react";
import { ClickOutside } from "../ClickOutside/ClickOutside";
import {
  MdKeyboardArrowUp as UpArrow,
  MdKeyboardArrowDown as DownArrow,
} from "react-icons/md";
import {
  DropdownWrapper,
  SelectedOption,
  OtherOptionsWrapper,
  OtherOption,
  Placeholder,
  TextContainer,
} from "./SelectDropdown";
import { CheckboxItem } from "../Checkbox/Checkbox";

interface DropdownProps {
  title?: string;
  placeholder: string;
  dropdownOptions: string[];
  handleSelection: (option: string, id: string) => void;
  id: string;
  multiselect?: boolean;
  value: string | string[] | null;
  width?: string;
  persistentPlaceholder?: boolean;
}

export function StyledDropdown(props: DropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  function changeOption(option: string, id: string) {
    if (!option) return;
    setIsListOpen(!isListOpen);
    if (option === props.value) {
      return props.handleSelection("", id);
    }
    props.handleSelection(option, id);
  }

  function selectOption(option: string, id: string) {
    if (!option) return;
    props.handleSelection(option, id);
  }

  return (
    <>
      <ClickOutside
        ref={dropdownRef}
        closeDropdown={() => setIsListOpen(false)}
      />
      <DropdownWrapper ref={dropdownRef}>
        {props.title && (
          <label htmlFor={props.id}>
            <h4 id={props.id}>{props.title}</h4>
          </label>
        )}

        {props.multiselect ? (
          <>
            <SelectedOption
              onClick={() => setIsListOpen(!isListOpen)}
              isListOpen={isListOpen}
              selectedValue={
                props.value && props.value[0] !== "" ? true : false
              }
              width={props.width}
            >
              {props.placeholder &&
              (props.value === undefined || props.value?.includes("")) ? (
                <Placeholder>{props.placeholder}</Placeholder>
              ) : (
                props.value?.toString()
              )}

              {isListOpen ? (
                <UpArrow size={"1.5em"} fontWeight={"bold"} />
              ) : (
                <DownArrow size={"1.5em"} fontWeight={"bold"} />
              )}
            </SelectedOption>
            <OtherOptionsWrapper width={props.width}>
              {isListOpen
                ? props.dropdownOptions.map((option, idx) => (
                    <OtherOption
                      key={idx}
                      onClick={() => selectOption(option, props.id)}
                      width={props.width}
                    >
                      <CheckboxItem checked={props.value?.includes(option)} />
                      <TextContainer>{option}</TextContainer>
                    </OtherOption>
                  ))
                : null}
            </OtherOptionsWrapper>
          </>
        ) : (
          <>
            <SelectedOption
              onClick={() => setIsListOpen(!isListOpen)}
              isListOpen={isListOpen}
              selectedValue={props.value ? true : false}
              width={props.width}
              {...props}
            >
              {props.placeholder && !props.value ? (
                <Placeholder>{props.placeholder}</Placeholder>
              ) : (
                <>
                  {props.persistentPlaceholder ? `${props.placeholder} ` : null}
                  {props.value}
                </>
              )}
              {isListOpen ? (
                <UpArrow size={"1.5em"} fontWeight={"bold"} />
              ) : (
                <DownArrow size={"1.5em"} fontWeight={"bold"} />
              )}
            </SelectedOption>
            <OtherOptionsWrapper width={props.width}>
              {isListOpen
                ? props.dropdownOptions.map((option, idx) => (
                    <OtherOption
                      key={idx}
                      onClick={() => changeOption(option, props.id)}
                      selectedValue={option === props.value ? true : false}
                      width={props.width}
                    >
                      {option}
                    </OtherOption>
                  ))
                : null}
            </OtherOptionsWrapper>
          </>
        )}
      </DropdownWrapper>
    </>
  );
}
