import React from "react";
import { styled } from "@mui/material";
import {
  MetricItem,
  MetricCard,
} from "@accenture/energy-transition-ui-library";

const Divider = styled("div")`
  width: 2px;
  height: 115px;
  background-color: ${(props) => props.theme.colors.separators.major};
`;

const DatasetMetrics = () => {
  return (
    <MetricItem
      width="100%"
      height="234px"
      title="Dataset Metrics"
      source="Label"
      expandDisabled
    >
      <MetricCard
        title="Total Datasets"
        subtitle="Datasets"
        value={9}
        width="222px"
      />
      <Divider />
      <MetricCard
        title="Datasets Imported"
        subtitle="Datasets"
        value={4}
        width="222px"
      />
    </MetricItem>
  );
};

export default DatasetMetrics;
