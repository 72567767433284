import React, { useEffect, useState, useMemo } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useQuery } from "@tanstack/react-query";
import { datasetGeojsonQuery } from "../mapQueries";
import {
  LayerMetadata,
  LayerGeometryType,
} from "@accenture/energy-transition-interfaces";
import {
  getBeforeIdByLayerType,
  getLayerStyleTypeByGeometryType,
} from "../../utils/utils";
import {
  addLoadingLayer,
  removeLoadingLayer,
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";

interface ICustomLayerProps {
  metadata: LayerMetadata;
}

export const CustomLayer = (props: ICustomLayerProps) => {
  const { metadata } = props;
  const dispatch = useAppDispatch();
  const { map: map } = useMap();
  const { isLoading, data } = useQuery(datasetGeojsonQuery(metadata.dataset));
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, metadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, metadata.layerID),
  );
  const layerType = getLayerStyleTypeByGeometryType(
    data?.features[0].geometry.type,
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", metadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", metadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  useEffect(() => {
    if (isLoading) {
      dispatch(addLoadingLayer(metadata.layerID));
    } else {
      dispatch(removeLoadingLayer(metadata.layerID));
    }
  }, [isLoading]);

  const sourceConfig: SourceProps = {
    id: metadata.sourceID,
    type: "geojson",
    data: data,
  };

  const layerFillConfig: LayerProps = {
    id: metadata.layerID,
    source: metadata.sourceID,
    beforeId: getBeforeIdByLayerType(layerType),
    type: "fill",
    paint: {
      "fill-color": metadata.toggleDotColor,
      "fill-opacity": 0.8,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerLineConfig: LayerProps = {
    id: metadata.layerID,
    source: metadata.sourceID,
    beforeId: getBeforeIdByLayerType(layerType),
    type: "line",
    paint: {
      "line-color": metadata.toggleDotColor,
      "line-width": 2,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerCircleConfig: LayerProps = {
    id: metadata.layerID,
    source: metadata.sourceID,
    beforeId: getBeforeIdByLayerType(layerType),
    type: "circle",
    paint: {
      "circle-color": metadata.toggleDotColor,
      "circle-radius": 10,
      "circle-opacity": 0.5,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerConfig = useMemo(() => {
    if (layerType === LayerGeometryType.Line) return layerLineConfig;
    if (layerType === LayerGeometryType.Fill) return layerFillConfig;
    if (layerType === LayerGeometryType.Circle) return layerCircleConfig;
    return;
  }, [layerType, layerLineConfig, layerFillConfig, layerCircleConfig]);

  return (
    <>
      {layerConfig && data && (
        <>
          <Source {...sourceConfig}>
            <Layer {...layerConfig} />
          </Source>
        </>
      )}
    </>
  );
};
