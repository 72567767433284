import React, { useState, useRef } from "react";
import { DeleteSearchIcon } from "../Assets/SearchIcons/DeleteSearchIcon";
import { SearchIcon } from "../Assets/SearchIcons/SearchIcon";
import {
  DropdownWrapper,
  OtherOptionsWrapper,
  OtherOption,
} from "../SelectDropdown/SelectDropdown";
import { ClickOutside } from "../ClickOutside/ClickOutside";
import { styled } from "@mui/material/styles";
import { DatasetLayer } from "@accenture/energy-transition-interfaces";

const SearchBarInput = styled("input")<{
  isListOpen?: boolean;
}>`
  width: 173px;
  height: 24px;
  border: 0px;
  padding-left: 12px;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

const DeleteIconContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const SearchBarContainer = styled("div")<{
  isListOpen?: boolean;
  selectedValue?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 282px;
  height: 48px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.text.major};
  background: ${(props) => props.theme.colors.background.base};
  border-radius: ${(props) => (props.isListOpen ? "8px 8px 0 0" : "8px")};
  border: ${(props) =>
    props.isListOpen
      ? `4px solid ${props.theme.colors.button.primary}`
      : props.selectedValue
      ? `2px solid ${props.theme.colors.text.minor}`
      : `2px solid ${props.theme.colors.button.inactive}`};

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.button.primary};
  }

  &:focus {
    outline: none;
    border: 4px solid ${(props) => props.theme.colors.button.primary};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.text.minor};
  }

  svg {
    cursor: pointer;
    padding-left: ${(props) => (props.selectedValue ? `12px;` : `48px`)};
  }
`;

interface ISearchBar {
  title?: string;
  placeholder?: string;
  options: DatasetLayer[];
  handleSelection: (option: DatasetLayer[] | null) => void;
  value: DatasetLayer[] | null;
  id: string;
}

const SearchBar = (props: ISearchBar) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<string>();

  function changeOption(option: string) {
    setSearchResult(option);
    const searchWord = option;
    const newFilter = props.options.filter((value) => {
      return value.user_dataset_name
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      props.handleSelection(null);
      setIsListOpen(false);
    } else {
      props.handleSelection(newFilter);
    }
  }

  return (
    <>
      <ClickOutside
        ref={dropdownRef}
        closeDropdown={() => setIsListOpen(false)}
      />
      <DropdownWrapper ref={dropdownRef}>
        {props.id && (
          <label htmlFor={`${props.id}-label`}>
            <h4>{props.title}</h4>
          </label>
        )}
        <>
          <SearchBarContainer
            selectedValue={searchResult ? true : false}
            isListOpen={isListOpen}
          >
            <SearchBarInput
              type="text"
              placeholder={props.placeholder}
              value={searchResult}
              onChange={(event) => {
                setIsListOpen(true);
                changeOption(event.target.value);
              }}
            />
            {searchResult ? (
              <DeleteIconContainer onClick={() => changeOption("")}>
                <DeleteSearchIcon />
              </DeleteIconContainer>
            ) : null}
            <SearchIcon />
          </SearchBarContainer>

          <OtherOptionsWrapper>
            {isListOpen
              ? props.value?.slice(0, 15).map((option, idx) => (
                  <OtherOption
                    key={idx}
                    onClick={() => {
                      setIsListOpen(false);
                      changeOption(option.user_dataset_name);
                    }}
                  >
                    {option.user_dataset_name}
                  </OtherOption>
                ))
              : null}
          </OtherOptionsWrapper>
        </>
      </DropdownWrapper>
    </>
  );
};

export default SearchBar;
