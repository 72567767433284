import { DataSetTheme } from "@accenture/energy-transition-interfaces";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IBody } from "../Sidebar/SidebarComponents/RunPrioritization/runPrioritizationQueries";

interface initialState {
  selectedTheme: DataSetTheme | null;
  runPrioritization: boolean;
  prioritizationFactors: IBody | null;
  selectedHex: string;
}
const initialValue: initialState = {
  selectedTheme: null,
  runPrioritization: false,
  prioritizationFactors: null,
  selectedHex: "",
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: initialValue,
  reducers: {
    setSelectedTheme: (state, action) => {
      state.selectedTheme = action.payload;
    },
    setRunPrioritization: (state, action) => {
      state.runPrioritization = action.payload;
    },
    setPrioritizationFactors: (state, action) => {
      state.prioritizationFactors = action.payload;
    },
    setSelectedHex: (state, action) => {
      state.selectedHex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedTheme,
  setRunPrioritization,
  setPrioritizationFactors,
  setSelectedHex,
} = workspaceSlice.actions;

// Selectors
export const selectCurrentWorkspace = (state: RootState) =>
  state.workspace.selectedTheme;
export const selectRunPrioritization = (state: RootState) =>
  state.workspace.runPrioritization;
export const selectPrioritizationFactors = (state: RootState) =>
  state.workspace.prioritizationFactors;
export const selectSelectedHex = (state: RootState) =>
  state.workspace.selectedHex;

export default workspaceSlice.reducer;
