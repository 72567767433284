import React, { useState, useCallback } from "react";
import {
  MetricItem,
  StyledDropdown,
} from "@accenture/energy-transition-ui-library";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "8/6",
    count: 0,
  },
  {
    name: "8/7",
    count: 10,
  },
  {
    name: "8/8",
    count: 23,
  },
  {
    name: "8/9",
    count: 33,
  },
  {
    name: "8/10",
    count: 10,
  },
  {
    name: "8/11",
    count: 15,
  },
  {
    name: "8/12",
    count: 0,
  },
];

enum DropdownOptions {
  Default = "Default",
  Week = "Week",
  Month = "Month",
}

const UsageOverTime = () => {
  const [sortBy, setSortBy] = useState<string | null>(null);

  const sortElements = useCallback(
    (sortBy: string) => {
      setSortBy(sortBy);
    },
    [sortBy],
  );

  return (
    <MetricItem
      width="100%"
      height="364px"
      title="Usage Over Time"
      source="Label"
      align="flex-start"
      padding="20px"
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            right: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#460073"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <StyledDropdown
        dropdownOptions={Object.values(DropdownOptions)}
        id="data-usage-dropdown"
        handleSelection={sortElements}
        value={sortBy}
        placeholder="View by"
        persistentPlaceholder
        width="192px"
      />
    </MetricItem>
  );
};

export default UsageOverTime;
