import React from "react";

export const AddIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 19.3337C9.6223 19.3337 9.30564 19.2059 9.05008 18.9503C8.79453 18.6948 8.66675 18.3781 8.66675 18.0003V11.3337H2.00008C1.6223 11.3337 1.30564 11.2059 1.05008 10.9503C0.794526 10.6948 0.666748 10.3781 0.666748 10.0003C0.666748 9.62255 0.794526 9.30588 1.05008 9.05032C1.30564 8.79477 1.6223 8.66699 2.00008 8.66699H8.66675V2.00033C8.66675 1.62255 8.79453 1.30588 9.05008 1.05033C9.30564 0.79477 9.6223 0.666992 10.0001 0.666992C10.3779 0.666992 10.6945 0.79477 10.9501 1.05033C11.2056 1.30588 11.3334 1.62255 11.3334 2.00033V8.66699H18.0001C18.3779 8.66699 18.6945 8.79477 18.9501 9.05032C19.2056 9.30588 19.3334 9.62255 19.3334 10.0003C19.3334 10.3781 19.2056 10.6948 18.9501 10.9503C18.6945 11.2059 18.3779 11.3337 18.0001 11.3337H11.3334V18.0003C11.3334 18.3781 11.2056 18.6948 10.9501 18.9503C10.6945 19.2059 10.3779 19.3337 10.0001 19.3337Z"
        fill="#3B3944"
      />
    </svg>
  );
};
