import React, {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useEffect,
} from "react";

interface ClickOutsideProps {
  closeDropdown?: (type: boolean) => void;
  closeList?: (type: never[]) => void;
}

export const ClickOutside = forwardRef(function Input(
  props: React.PropsWithChildren<ClickOutsideProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (
        !(ref as MutableRefObject<HTMLDivElement>)?.current?.contains(
          event.target as Node,
        )
      ) {
        props.closeDropdown && props.closeDropdown(false);
        props.closeList && props.closeList([]);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });

  return <></>;
});
