import React from "react";
import { Tab, Tabs, styled } from "@mui/material";
import { CustomTabPanel } from "./CustomTabPanel";
import ToolsMetrics from "./ToolsMetrics/ToolsMetrics";
import DashboardTab from "./DashboardTab/DashboardTab";

const DashboardContainer = styled("div")`
  background-color: ${(props) => props.theme.colors.background.sidebar};
  overflow: auto;
`;
const DashboardContent = styled("div")`
  max-width: 1800px;
  width: calc(100% - 120px);
  min-height: calc(100vh - 80px - 83px);
  margin: auto;
`;

const StyledTabs = styled(Tabs)`
  padding: 40px 0;
`;

export const DashboardPage = () => {
  const [value, setValue] = React.useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardContainer>
      <DashboardContent>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Dashboard" value={1} />
          <Tab label="Tool Metrics" value={2} />
        </StyledTabs>
        <CustomTabPanel value={value} index={1}>
          <DashboardTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ToolsMetrics />
        </CustomTabPanel>
      </DashboardContent>
    </DashboardContainer>
  );
};
