import React from "react";
import { styled } from "@mui/material/styles";
import { ButtonType, Button } from "../../atoms/Button/Button";

export interface SourceProps {
  title: string;
  sourceLink: string;
}

const SourceWrapper = styled("div")`
  width: calc(25% - 18px);
  &:nth-of-type(n + 3) {
    margin-left: 24px;
  }
`;

const SourceInfo = styled("div")`
  height: 80px;
  display: flex;
  color: ${(props) => props.theme.colors.text.major};
  padding: 0.2em 24px;
  background-color: ${(props) => props.theme.colors.background.sidebar};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled("a")`
  display: block;
  color: ${(props) => props.theme.colors.text.major};

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const SourceTitle = styled("h4")`
  margin: 0;
`;

const SourceButton = styled("div")``;

export function IndividualSource(props: SourceProps) {
  return (
    <SourceWrapper>
      <Link href={props.sourceLink} target="_blank" rel="noreferrer">
        <SourceInfo>
          <SourceTitle>{props.title}</SourceTitle>
          <SourceButton>
            <Button buttonType={ButtonType.Primary}>Open</Button>
          </SourceButton>
        </SourceInfo>
      </Link>
    </SourceWrapper>
  );
}
