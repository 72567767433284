import { DataSetTheme } from "./IDataSetTheme";

export interface GlobalDatasetLayer {
  "Data Update Frequency": string;
  Styling: string;
  "UI Link": string;
  color: string;
  dataSourceTag: string;
  description: string;
  theme: string[];
  URL: string;
  user_dataset_name: string;
}
export interface DataCatalogGlobalDatasets {
  data_catalog: GlobalDatasetLayer[];
}

export interface UploadedDatasetLayer {
  athena: boolean;
  base_app: string;
  classification: string;
  color: string;
  dataSourceTag: string;
  dataset_name: string;
  description: string;
  geospatial_formats: string[];
  latColName: string;
  lonColName: string;
  saveToDataCatalog: string;
  snapshot_date_or_datetime: string;
  theme: DataSetTheme[];
  user_dataset_name: string;
  user_id: string;
  user_id_std: string;
  user_upload: boolean;
  workspace_name: string;
  workspace_name_std: string;
}
export interface DataCatalogUploadedDatasets {
  [k: string]: UploadedDatasetLayer;
}

export type DatasetLayer = GlobalDatasetLayer | UploadedDatasetLayer;

export const instanceOfGlobalDatasetLayer = (
  object: DatasetLayer,
): object is GlobalDatasetLayer => {
  return (
    "Data Update Frequency" in object && "UI Link" in object && "URL" in object
  );
};

export const instanceOfUploadedDatasetLayer = (
  object: DatasetLayer,
): object is UploadedDatasetLayer => {
  return "user_id" in object && "user_upload" in object;
};
