import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const wildfiresLayerMetadata: LayerMetadata = {
  layerName: "Wildfires",
  sourceID: "wildfires-layer-source",
  layerID: "wildfires-layer",
  dataset: "cejst_nri_census_tracts",
  toggleDotColor: "#933D41",
  description:
    "This dataset highlights census tracts based on the risk index for wildfires. Wildfires is one of the 18 natural hazards identified in the National Risk Index. In the National Risk Index, natural hazards are represented in terms of expected annual loss, which incorporate data for exposure, annualized frequency, and historic loss ratio.",
  fieldsToDisplay: {
    "Wildfire - Hazard Type Risk Index Rating": "Wildfire Risk",
    "Wildfire - Hazard Type Risk Index Score": "Wildfire Risk Score",
  },
  theme: [DataSetTheme.Climate],
};

export const WildfiresLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, wildfiresLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, wildfiresLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", wildfiresLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0].properties !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(
          e?.features[0]?.properties["National Risk Index ID"],
        );
      }
    });
    map.on("mouseleave", wildfiresLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: wildfiresLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${wildfiresLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: wildfiresLayerMetadata.layerID,
    source: wildfiresLayerMetadata.sourceID,
    "source-layer": wildfiresLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "National Risk Index ID"], hoveredFeatureID],
          [
            "in",
            ["get", "National Risk Index ID"],
            [
              "literal",
              selectedFeatures.map(
                (f) => f.properties["National Risk Index ID"],
              ),
            ],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": wildfiresLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Wildfire - Hazard Type Risk Index Score"]],
        0,
        0,
        100,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
WildfiresLayer.metadata = wildfiresLayerMetadata;
