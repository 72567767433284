import React from "react";

export const UploadFileIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <mask
        id="mask0_1218_41883"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1218_41883)">
        <path
          d="M18.6667 23.7082V30.2221C18.6667 30.6157 18.8003 30.9455 19.0675 31.2117C19.3346 31.4779 19.6656 31.611 20.0605 31.611C20.4554 31.611 20.7848 31.4779 21.0487 31.2117C21.3126 30.9455 21.4445 30.6157 21.4445 30.2221V23.7082L23.639 25.9027C23.7501 26.0138 23.9515 26.0369 24.2432 25.9721C24.5348 25.9073 24.8126 25.8355 25.0765 25.7568C25.3404 25.6781 25.5418 25.6295 25.6807 25.611C25.8196 25.5925 25.7871 25.6851 25.5834 25.8888C25.8519 25.611 25.9839 25.2869 25.9792 24.9166C25.9746 24.5462 25.8334 24.2222 25.5556 23.9444L20.9723 19.4444C20.8242 19.2962 20.6701 19.192 20.5102 19.1319C20.3504 19.0717 20.1791 19.0416 19.9964 19.0416C19.8137 19.0416 19.6436 19.0717 19.4862 19.1319C19.3288 19.192 19.176 19.2962 19.0279 19.4444L14.4723 23.9999C14.1945 24.2777 14.0579 24.6018 14.0626 24.9721C14.0672 25.3425 14.2084 25.6702 14.4862 25.9552C14.764 26.2165 15.0881 26.3494 15.4584 26.3541C15.8288 26.3587 16.1528 26.2221 16.4306 25.9444L18.6667 23.7082ZM9.4445 36.6666C8.6945 36.6666 8.04404 36.3911 7.49312 35.8402C6.94221 35.2893 6.66675 34.6388 6.66675 33.8888V6.111C6.66675 5.361 6.94221 4.71054 7.49312 4.15963C8.04404 3.60871 8.6945 3.33325 9.4445 3.33325H22.764C23.1426 3.33325 23.5035 3.40732 23.8465 3.55546C24.1897 3.70363 24.4862 3.90271 24.7362 4.15271L32.514 11.9305C32.764 12.1805 32.963 12.477 33.1112 12.8201C33.2593 13.1632 33.3334 13.5241 33.3334 13.9027V33.8888C33.3334 34.6388 33.058 35.2893 32.507 35.8402C31.9561 36.3911 31.3057 36.6666 30.5557 36.6666H9.4445ZM22.5279 12.6666V6.111H9.4445V33.8888H30.5557V14.0555H23.9167C23.5232 14.0555 23.1934 13.9224 22.9272 13.6561C22.661 13.3899 22.5279 13.0601 22.5279 12.6666Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default UploadFileIcon;
