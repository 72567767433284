import { createGlobalStyle } from "styled-components";
import { createTheme } from "@mui/material";

export interface CustomTheme {
  font: {
    family: string;
    size: string;
  };
  styles: {
    shadows: {
      section: string;
      popup: string;
      header: string;
      footer: string;
    };
  };
  colors: {
    hoverMix: number;
    background: {
      base: string;
      sidebar: string;
      contrast: string;
    };
    button: {
      primary: string;
      secondary: string;
      inactive: string;
    };
    text: {
      major: string;
      minor: string;
      white: string;
      contrast: string;
    };
    separators: {
      major: string;
      minor: string;
    };
    border: {
      light: string;
      contrast: string;
    };
  };
}

declare module "styled-components" {
  export interface DefaultTheme extends CustomTheme {}
}

export const lightTheme: CustomTheme = {
  font: {
    family: "'Graphik', Arial, sans-serif",
    size: "16px",
  },
  styles: {
    shadows: {
      section: "0 0.15em 0.7em 0 #0006;",
      popup: "0 0.15em 0.7em 0 #0006;",
      header: "0 2px 10px 0 rgba(0, 0, 0, 0.4)",
      footer: "0 -2px 10px 0 rgba(0, 0, 0, 0.4)",
    },
  },
  colors: {
    hoverMix: 0.25,
    background: {
      base: "#FFF",
      sidebar: "#E5E5E5",
      contrast: "#000",
    },
    text: {
      major: "#000",
      minor: "#3B3944",
      white: "#FFF",
      contrast: "#FFFFFF",
    },
    button: {
      primary: "#A100FF",
      secondary: "#7500C0",
      inactive: "#B3B2B5",
    },
    separators: {
      major: "#CCCBCE",
      minor: "#CCCBCE",
    },
    border: {
      light: "#CCCBCE",
      contrast: "#3b3944",
    },
  },
};

export const darkTheme: CustomTheme = {
  font: {
    family: "'Graphik', Arial, sans-serif",
    size: "16px",
  },
  styles: {
    shadows: {
      section: "0 0.15em 0.7em 0 #0006;",
      popup: "0 0.15em 0.7em 0 #0006;",
      header: "0 2px 10px 0 rgba(0, 0, 0, 0.4)",
      footer: "0 -2px 10px 0 rgba(0, 0, 0, 0.4)",
    },
  },
  colors: {
    hoverMix: 0.25,
    background: {
      base: "#20202A",
      sidebar: "#333646",
      contrast: "#E5E5E5",
    },
    text: {
      major: "#DDD",
      minor: "#A8A8A8",
      white: "#FFF",
      contrast: "#000",
    },
    button: {
      primary: "#A100FF",
      secondary: "#7500C0",
      inactive: "#B3B2B5",
    },
    separators: {
      major: "#CCCBCE",
      minor: "#CCCBCE",
    },
    border: {
      light: "#CCCBCE",
      contrast: "#CCCBCE",
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    font-family: ${(props) => props.theme.font.family};
    font-size: ${(props) => props.theme.font.size};
    background-color: ${(props) => props.theme.colors.background.base};
    color: ${(props) => props.theme.colors.text.minor};
    margin: 0;
    padding: 0;
    height: 100%;
  }
  #energy-transition-root {
    /* height: 100%; */
    display: flex;
    flex-direction: column-reverse;
    /* overflow-y: scroll; */
  }
  
  /* Mapbox control styles */
  .maplibregl-ctrl {
    margin: 24px !important;
  }
  .maplibregl-ctrl.maplibregl-ctrl-attrib {
    margin: 0 !important;
  }

  /* Icon Styles  */
  svg.dark-mode-compatible {
    path {
      fill: ${(props) => props.theme.colors.text.major};
    }
    &.stroke path {
      stroke: ${(props) => props.theme.colors.text.major};
    }
  }

  /* Text Styles */
  h1, h2, h3, h4, h5, h6 {
    color: ${(props) => props.theme.colors.text.major};
    margin: 0 0 24px;
  }
  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: -0.04em;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: -0.04em;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.02em;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.02em;
  }
  .b1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.02em;
  }
  .b2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.02em;
  }
  .b3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  .b4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
  }
  .s1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
  .s2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%; 
    letter-spacing: -0.64px;
    text-decoration-line: underline;
  }
`;

export const defaultTheme = lightTheme;

declare module "@mui/material/styles" {
  interface Theme extends CustomTheme {}
  // allow configuration using `createTheme`
  interface ThemeOptions extends CustomTheme {}
}

export const materialLightTheme = createTheme({
  typography: {
    fontFamily: lightTheme.font.family,
  },
  palette: {
    mode: "light",
    primary: {
      main: lightTheme.colors.button.primary,
    },
    secondary: {
      main: lightTheme.colors.background.sidebar,
    },
  },
  ...lightTheme,
});

export const materialDarkTheme = createTheme({
  typography: {
    fontFamily: darkTheme.font.family,
  },
  palette: {
    mode: "dark",
    primary: {
      main: darkTheme.colors.button.primary,
    },
    secondary: {
      main: darkTheme.colors.background.sidebar,
    },
  },
  ...darkTheme,
});
