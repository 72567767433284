import React from "react";

export const EVChargersIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 21.3333L9.33333 16H7.33333V12L4 17.3333H6V21.3333ZM2.66667 9.33333H10.6667V2.66667H2.66667V9.33333ZM12 24H1.33333C0.955556 24 0.638889 23.8722 0.383333 23.6167C0.127778 23.3611 0 23.0444 0 22.6667V2.66667C0 1.93333 0.261111 1.30556 0.783333 0.783333C1.30556 0.261111 1.93333 0 2.66667 0H10.6667C11.4 0 12.0278 0.261111 12.55 0.783333C13.0722 1.30556 13.3333 1.93333 13.3333 2.66667V12H15C15.6444 12 16.1944 12.2278 16.65 12.6833C17.1056 13.1389 17.3333 13.6889 17.3333 14.3333V20.5C17.3333 20.8778 17.4889 21.2222 17.8 21.5333C18.1111 21.8444 18.4556 22 18.8333 22C19.2333 22 19.5833 21.8444 19.8833 21.5333C20.1833 21.2222 20.3333 20.8778 20.3333 20.5V8H20C19.6222 8 19.3056 7.87222 19.05 7.61667C18.7944 7.36111 18.6667 7.04444 18.6667 6.66667V4.66667C18.6667 4.48889 18.7333 4.33333 18.8667 4.2C19 4.06667 19.1556 4 19.3333 4V2.66667C19.3333 2.48889 19.4 2.33333 19.5333 2.2C19.6667 2.06667 19.8222 2 20 2C20.1778 2 20.3333 2.06667 20.4667 2.2C20.6 2.33333 20.6667 2.48889 20.6667 2.66667V4H22V2.66667C22 2.48889 22.0667 2.33333 22.2 2.2C22.3333 2.06667 22.4889 2 22.6667 2C22.8444 2 23 2.06667 23.1333 2.2C23.2667 2.33333 23.3333 2.48889 23.3333 2.66667V4C23.5111 4 23.6667 4.06667 23.8 4.2C23.9333 4.33333 24 4.48889 24 4.66667V6.66667C24 7.04444 23.8722 7.36111 23.6167 7.61667C23.3611 7.87222 23.0444 8 22.6667 8H22.3333V20.5C22.3333 21.4333 21.9944 22.25 21.3167 22.95C20.6389 23.65 19.8111 24 18.8333 24C17.8778 24 17.0556 23.65 16.3667 22.95C15.6778 22.25 15.3333 21.4333 15.3333 20.5V14.3333C15.3333 14.2222 15.3056 14.1389 15.25 14.0833C15.1944 14.0278 15.1111 14 15 14H13.3333V22.6667C13.3333 23.0444 13.2056 23.3611 12.95 23.6167C12.6944 23.8722 12.3778 24 12 24Z"
        fill="black"
      />
    </svg>
  );
};
