import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ITags } from "@accenture/energy-transition-interfaces";

interface initialState {
  selectedTags: ITags[];
}

const initialValue: initialState = {
  selectedTags: [],
};

export const dataCatalogSlice = createSlice({
  name: "dataCatalog",
  initialState: initialValue,
  reducers: {
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedTags } = dataCatalogSlice.actions;

// Selectors
export const selectSelectedTags = (state: RootState) =>
  state.dataCatalog.selectedTags;

export default dataCatalogSlice.reducer;
