import { styled } from "@mui/material/styles";

export const Header = styled("div")`
  display: flex;
  min-width: 900px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const Content = styled("div")`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const Catalog = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const Card = styled("div")`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.separators.major};
  align-self: stretch;
`;

export const CardContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const CardInfo = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const Tags = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const Tag = styled("div")`
  display: flex;
  min-width: fit-content;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.button.inactive};
  background: ${(props) => props.theme.colors.background.sidebar};
`;

export const Divider = styled("div")`
  display: flex;
  background-color: ${(props) => props.theme.colors.separators.minor};
  height: 2px;
  justify-content: center;
  align-items: center;
`;

export const DataSetResults = styled("h3")`
  margin: 0px;
`;

export const DataCatalogTitle = styled("h1")`
  margin: 0px;
`;

export const DataSetTitle = styled("h2")`
  color: ${(props) =>
    props.theme.palette.mode === "dark"
      ? props.theme.colors.text.major
      : props.theme.colors.button.primary};
  margin: 0px;
  font-weight: 700;
`;

export const StyledParagraph = styled("p")`
  margin: 0px;

  &.s2 a {
    ${(props) =>
      props.theme.palette.mode === "dark" &&
      `
      color: ${props.theme.colors.text.major};
    `}
  }
`;
