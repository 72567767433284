import {
  DataCatalogGlobalDatasets,
  DataCatalogUploadedDatasets,
} from "@accenture/energy-transition-interfaces";
import axios from "../utils/axiosConfig";

export const globalDatasetsQuery = () => ({
  queryKey: ["globalDatasets"],
  queryFn: async () => {
    const response = await axios.post("/dt_base/get_global_datasets", {});
    const data = response.data as DataCatalogGlobalDatasets;
    return data.data_catalog;
  },
});

export const uploadedDatasetsQuery = () => ({
  queryKey: ["uploadedDatasets"],
  queryFn: async () => {
    const response = await axios.post("/dt_base/get_uploaded_datasets", {});
    const data = response.data as DataCatalogUploadedDatasets;
    return Object.values(data);
  },
});
