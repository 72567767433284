import React from "react";
import { ITags } from "@accenture/energy-transition-interfaces";
import { StyledChip, TagsProps, Section } from "./TagsList";

function DataSourceTags(props: TagsProps): React.ReactElement {
  const { tagsFilter, onChangeFilter } = props;

  return (
    <Section gap="8px">
      <h4>Data Source</h4>
      <div>
        <StyledChip
          label={ITags.External}
          selected={tagsFilter?.includes(ITags.External)}
          onClick={() => onChangeFilter(ITags.External)}
        />
        <StyledChip
          label={ITags.Internal}
          selected={tagsFilter?.includes(ITags.Internal)}
          onClick={() => onChangeFilter(ITags.Internal)}
        />
      </div>
    </Section>
  );
}

export default DataSourceTags;
