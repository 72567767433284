import React from "react";
import { styled } from "@mui/material/styles";
import CustomTooltip from "../../atoms/CustomTooltip/CustomTooltip";

const CardContainer = styled("div")<{
  width?: string;
  height?: string;
  prioritizationMode?: boolean;
}>`
  border-radius: 8px;
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "auto"};
  background-color: ${(props) =>
    props.prioritizationMode
      ? props.theme.colors.button.secondary
      : props.theme.colors.background.sidebar};
  h2,
  h4,
  p {
    margin: 0px;
    white-space: nowrap;
    color: ${(props) =>
      props.prioritizationMode ? "#FFF" : props.theme.colors.text.major};
  }
`;
const Header = styled("div")`
  padding: 8px;
  border-bottom: 2px solid ${(props) => props.theme.colors.separators.major};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
`;
const Content = styled("div")`
  padding: 8px;
  text-align: center;
`;

interface IMetricCardProps {
  title: string;
  subtitle?: string;
  value: number | string;
  unit?: string;
  width?: string;
  height?: string;
  tooltip?: string;
  prioritizationMode?: boolean;
}

export const MetricCard = ({
  title,
  subtitle = "",
  value,
  unit = "",
  width,
  height,
  tooltip = "",
  prioritizationMode,
}: IMetricCardProps) => {
  return (
    <CardContainer
      width={width}
      height={height}
      prioritizationMode={prioritizationMode}
    >
      <Header>
        <h4>{title}</h4>
      </Header>
      <Content>
        <CustomTooltip title={tooltip} placement="bottom">
          {
            !prioritizationMode ? (
              <h2>
                {typeof value === "number" && unit === "$" && unit}
                {value ? value.toLocaleString() : 0}
                {typeof value === "number" && unit !== "$" && unit}
              </h2>
            ) : (
              <></>
            ) /* don't show empty values in prioritization mode */
          }
        </CustomTooltip>
        {subtitle && <p className="b4">{subtitle}</p>}
      </Content>
    </CardContainer>
  );
};

export default MetricCard;
