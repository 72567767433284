import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import DefaultPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/EVChargersDefaultPin.png";
import HoverPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/EVChargersHoverPin.png";
import SelectedPin from "@accenture/energy-transition-ui-library/src/atoms/Assets/LayerImages/EVChargersSelectedPin.png";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { EVChargersIcon } from "@accenture/energy-transition-ui-library";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const evChargersLayerMetadata: LayerMetadata = {
  layerName: "EV Chargers",
  sourceID: "ev-chargers-layer-source",
  layerID: "ev-chargers-layer",
  dataset: "afdc_chargers",
  clusterLayerID: "ev-chargers-layer-cluster",
  toggleIcon: EVChargersIcon,
  description:
    "This dataset displays the location of electric vehicle charging stations.",
  layerImages: {
    evChargersDefaultPin: DefaultPin,
    evChargersHoverPin: HoverPin,
    evChargersSelectedPin: SelectedPin,
  },
  fieldsToDisplay: {
    access_code: "Access Code",
    access_days_time: "Access Days/Time",
    date_last_confirmed: "Date Last Confirmed",
    ev_connector_types: "EV Connector Type(s)",
    ev_level2_evse_num: "Number of Level 2 Ports",
    ev_network: "EV Network",
    station_phone: "Station Phone Number",
    street_address: "Street Address",
    zip: "Zip",
  },
  theme: [DataSetTheme.EnergyInfrastructure],
};

export const EVChargersLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<number>(0);
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, evChargersLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, evChargersLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", evChargersLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.id);
      }
    });
    map.on("mouseleave", evChargersLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID(0);
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: evChargersLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${evChargersLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
    // filter: ["==", ["get", "fuel_type_code"], "ELEC"],
    // cluster: true,
    // clusterMaxZoom: 12, // Max zoom to cluster points on
    // clusterRadius: 100, // Radius of each cluster when clustering points (defaults to 50)
  };

  const layerClusterConfig: LayerProps = {
    id: evChargersLayerMetadata.clusterLayerID,
    source: evChargersLayerMetadata.sourceID,
    "source-layer": evChargersLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Point),
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#fff",
      "circle-stroke-color": "#000",
      "circle-stroke-width": 2,
      "circle-radius": 30,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerClusterCountConfig: LayerProps = {
    id: evChargersLayerMetadata.clusterLayerID + "-count",
    source: evChargersLayerMetadata.sourceID,
    "source-layer": evChargersLayerMetadata.dataset,
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Graphik", "Arial Unicode MS Bold"],
      "text-size": 24,
      visibility: showLayer ? "visible" : "none",
    },
  };

  const layerPointConfig: LayerProps = {
    id: evChargersLayerMetadata.layerID,
    source: evChargersLayerMetadata.sourceID,
    "source-layer": evChargersLayerMetadata.dataset,
    filter: ["!", ["has", "point_count"]],
    type: "symbol",
    layout: {
      "icon-image": [
        "case",
        [
          "in",
          ["get", "id"],
          ["literal", selectedFeatures.map((f) => f.properties.id)],
        ],
        "evChargersSelectedPin",
        ["==", ["get", "id"], hoveredFeatureID],
        "evChargersHoverPin",
        "evChargersDefaultPin",
      ],
      "icon-allow-overlap": true,
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerClusterConfig} />
      <Layer {...layerClusterCountConfig} />
      <Layer {...layerPointConfig} />
    </Source>
  );
};
EVChargersLayer.metadata = evChargersLayerMetadata;
