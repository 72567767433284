import React, { useCallback, useMemo, useState } from "react";
import { Form } from "@accenture/energy-transition-ui-library/src/molecules/Form/Form";
import { IWorkspace } from "@accenture/energy-transition-interfaces";
import { CustomModal } from "@accenture/energy-transition-ui-library/src/molecules/CustomModal/CustomModal";

export interface IWorkspaceETSMetadataProps {
  isModalOpen: boolean;
  onCancel: () => void;
  onSuccess?: () => void;
  onSave: (workspace: IWorkspace) => void;
  action: "rename";
  workspace?: IWorkspace;
}

export function WorkspaceMetadata(
  props: IWorkspaceETSMetadataProps,
): React.ReactElement {
  const title = useMemo(() => {
    if (props.action === "rename")
      return `Rename "${props.workspace?.workspace_name}"`;
    return "";
  }, [props.action, props.workspace]);

  return (
    <CustomModal
      isModalOpen={props.isModalOpen}
      onCancel={props.onCancel}
      onSuccess={props.onSuccess}
    >
      <h2>{title}</h2>
      <p className="b2">What would you like to name your workspace?</p>
      <WorkspaceMetadataForm {...props} />
    </CustomModal>
  );
}

function WorkspaceMetadataForm(props: IWorkspaceETSMetadataProps) {
  // const [performRename] = useMutation(renameWorkspace);

  const [name, setName] = useState(props.workspace?.workspace_name ?? "");

  const onSubmit = useCallback(() => {
    // handle submit
  }, [name, props.onSave, props.workspace]);

  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  return (
    <Form onSubmit={onSubmit}>
      <Form.Input
        id="name"
        type="text"
        title="Workspace Name"
        required={true}
        value={name}
        onChange={onChangeName}
      />
      <Form.Divider />
      <Form.ButtonContainer>
        <Form.StyledButton id="Cluster" value="Submit">
          Rename Workspace
        </Form.StyledButton>
      </Form.ButtonContainer>
    </Form>
  );
}
