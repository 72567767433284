import React from "react";
import {
  Sidebar,
  MenuList,
  MenuListDivider,
} from "@accenture/energy-transition-ui-library";
import { Outlet, useParams } from "react-router-dom";
import { DataUpload } from "./SidebarComponents/DataUpload/DataUpload";

export function WorkspaceSidebar(): React.ReactElement | null {
  const { workspaceID } = useParams();

  return (
    <>
      <Sidebar>
        <MenuList>
          <MenuList.Link
            to={`/workspaces/${workspaceID}/map`}
            state={{
              workspaceID: workspaceID,
            }}
          >
            Map
          </MenuList.Link>
          <MenuList.Link
            to={`/workspaces/${workspaceID}/dataCatalog`}
            state={{
              workspaceID: workspaceID,
            }}
          >
            Data Catalog
          </MenuList.Link>
          <DataUpload />
          <MenuListDivider />
        </MenuList>
        <Outlet />
      </Sidebar>
    </>
  );
}
