import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const trafficProximityLayerMetadata: LayerMetadata = {
  layerName: "Traffic Proximity",
  sourceID: "traffic-proximity-layer-source",
  layerID: "traffic-proximity-layer",
  dataset: "cejst",
  toggleDotColor: "#6F2DA8",
  description:
    "This dataset quantifies the proximity of traffic, taking into account the count of vehicles per day (average annual daily traffic) at major roads within 500 meters (or the nearest one beyond 500 m), divided by distance in meters.",
  theme: [DataSetTheme.Transportation],
};

export const TrafficProximityLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, trafficProximityLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, trafficProximityLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", trafficProximityLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", trafficProximityLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: trafficProximityLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${trafficProximityLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: trafficProximityLayerMetadata.layerID,
    source: trafficProximityLayerMetadata.sourceID,
    "source-layer": trafficProximityLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": trafficProximityLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Traffic proximity and volume (percentile)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
TrafficProximityLayer.metadata = trafficProximityLayerMetadata;
