import React from "react";

export const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="13"
      viewBox="0 0 6 13"
      fill="none"
    >
      <path
        d="M3 11.2383L4.7415 9.49863C4.89131 9.34897 5.06419 9.27414 5.26013 9.27414C5.45608 9.27414 5.62838 9.34839 5.77703 9.49689C5.92568 9.64538 6 9.8175 6 10.0132C6 10.209 5.92638 10.3806 5.77913 10.5282L3.51533 12.7971C3.44171 12.8709 3.36196 12.9231 3.27608 12.9539C3.19019 12.9846 3.09816 13 3 13C2.90184 13 2.80981 12.9846 2.72392 12.9539C2.63804 12.9231 2.55829 12.8709 2.48467 12.7971L0.220865 10.5282C0.0736215 10.3806 0.00337838 10.209 0.0101351 10.0132C0.0168919 9.8175 0.0945946 9.64538 0.243243 9.49689C0.391892 9.34839 0.564189 9.27414 0.760135 9.27414C0.956081 9.27414 1.12818 9.34897 1.27644 9.49863L3 11.2383ZM3 1.76168L1.2585 3.50137C1.10869 3.65103 0.935811 3.72586 0.739865 3.72586C0.543919 3.72586 0.371622 3.65161 0.222973 3.50312C0.0743243 3.35462 0 3.1825 0 2.98676C0 2.79102 0.0736215 2.61936 0.220865 2.47178L2.48467 0.202917C2.55829 0.129129 2.63804 0.0768588 2.72392 0.046107C2.80981 0.0153687 2.90184 0 3 0C3.09816 0 3.19019 0.0153687 3.27608 0.046107C3.36196 0.0768588 3.44171 0.129129 3.51533 0.202917L5.77913 2.47178C5.92638 2.61936 6 2.78764 6 2.97664C6 3.16563 5.92568 3.33437 5.77703 3.48287C5.62838 3.63136 5.45608 3.70561 5.26013 3.70561C5.06419 3.70561 4.89131 3.63156 4.7415 3.48345L3 1.76168Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};

export default FilterIcon;
