import React, { useEffect } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { useAppDispatch } from "../../redux/store";
import {
  setMetricPopulation,
  setMetricUnderserved,
} from "../../redux/mapSlice";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";
import { getBeforeIdByLayerType, getUniqueFeatures } from "../../utils/utils";
import { LayerGeometryType } from "@accenture/energy-transition-interfaces/src/ILayer";

declare const AWS_API_URI: string;

const hiddenCEJSTLayerMetadata: LayerMetadata = {
  layerName: "Hidden CEJST",
  sourceID: "hidden-cejst-layer-source",
  layerID: "hidden-cejst-layer",
  dataset: "cejst",
};

export const HiddenCEJSTLayer = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;
    map.on("moveend", () => {
      updateMetrics();
    });
  }, [map]);

  const updateMetrics = () => {
    if (!map || !map.getLayer(hiddenCEJSTLayerMetadata.layerID)) return;

    const allFeatures = map.queryRenderedFeatures(undefined, {
      layers: [hiddenCEJSTLayerMetadata.layerID],
    });
    const features = getUniqueFeatures(allFeatures, "GEOID10");

    // Only get data if within the zoom bounds of the dataset (any features exist)
    if (features.length === 0) {
      dispatch(setMetricUnderserved("N/A"));
      dispatch(setMetricPopulation("N/A"));
      return;
    }

    // Population Metric
    const totalPopulation = features
      .map((f) => f.properties && f.properties["Total population"])
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
    dispatch(setMetricPopulation(totalPopulation));

    // Underserved Metric
    const populationUnderserved = features
      .filter(
        (f) =>
          f.properties &&
          f.properties[
            "Definition N community, including adjacency index tracts"
          ] === 1,
      )
      .map((f) => f.properties && f.properties["Total population"])
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
    const percentUnderserved = populationUnderserved / totalPopulation;
    const formattedUnderserved = Math.round(percentUnderserved * 100);
    dispatch(setMetricUnderserved(formattedUnderserved));
  };

  const sourceConfig: SourceProps = {
    id: hiddenCEJSTLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${hiddenCEJSTLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: hiddenCEJSTLayerMetadata.layerID,
    source: hiddenCEJSTLayerMetadata.sourceID,
    "source-layer": hiddenCEJSTLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-opacity": 0,
    },
    layout: {
      visibility: "visible",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HiddenCEJSTLayer.metadata = hiddenCEJSTLayerMetadata;
