import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonType } from "../../atoms/Button/Button";

const LargeButtonContainer = styled("div")<{
  backgroundColor?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  position: relative;
  height: ${(props) => (props.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "calc(25% - 18px)")};
  border-radius: 8px;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.background.sidebar};
  cursor: pointer;
  align-items: center;

  button {
    margin: 16px 24px;
  }
  h4 {
    margin: 0;
  }
`;

interface LargeButtonProps extends ButtonHTMLAttributes<HTMLElement> {
  title: string;
  icon: ReactNode;
  backgroundColor?: string;
  width?: string;
  height?: string;
}
export const LargeButton = (props: LargeButtonProps) => {
  const { icon, title } = props;
  return (
    <LargeButtonContainer {...props}>
      <Button buttonType={ButtonType.LargeSymbol}>{icon}</Button>
      <h4>{title}</h4>
    </LargeButtonContainer>
  );
};
