import React from "react";

export const HexagonIcon = ({ color = "#460073" }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 11L6 0.607697H18L24 11L18 21.3923H6L0 11Z" fill={color} />
    </svg>
  );
};
