import React from "react";

export const CollapseIcon = () => {
  return (
    <svg
      className="dark-mode-compatible"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1543_41123"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1543_41123)">
        <path
          d="M13.0008 11.9998C12.7174 11.9998 12.4799 11.904 12.2883 11.7123C12.0966 11.5206 12.0008 11.2831 12.0008 10.9998V4.9998C12.0008 4.71647 12.0966 4.47897 12.2883 4.2873C12.4799 4.09564 12.7174 3.9998 13.0008 3.9998C13.2841 3.9998 13.5216 4.09564 13.7133 4.2873C13.9049 4.47897 14.0008 4.71647 14.0008 4.9998V8.5998L19.9008 2.6998C20.0841 2.51647 20.3174 2.4248 20.6008 2.4248C20.8841 2.4248 21.1174 2.51647 21.3008 2.6998C21.4841 2.88314 21.5758 3.11647 21.5758 3.3998C21.5758 3.68314 21.4841 3.91647 21.3008 4.0998L15.4008 9.9998H19.0008C19.2841 9.9998 19.5216 10.0956 19.7133 10.2873C19.9049 10.479 20.0008 10.7165 20.0008 10.9998C20.0008 11.2831 19.9049 11.5206 19.7133 11.7123C19.5216 11.904 19.2841 11.9998 19.0008 11.9998H13.0008ZM2.70078 21.2998C2.51745 21.1165 2.42578 20.8831 2.42578 20.5998C2.42578 20.3165 2.51745 20.0831 2.70078 19.8998L8.60078 13.9998H5.00078C4.71745 13.9998 4.47995 13.904 4.28828 13.7123C4.09661 13.5206 4.00078 13.2831 4.00078 12.9998C4.00078 12.7165 4.09661 12.479 4.28828 12.2873C4.47995 12.0956 4.71745 11.9998 5.00078 11.9998H11.0008C11.2841 11.9998 11.5216 12.0956 11.7133 12.2873C11.9049 12.479 12.0008 12.7165 12.0008 12.9998V18.9998C12.0008 19.2831 11.9049 19.5206 11.7133 19.7123C11.5216 19.904 11.2841 19.9998 11.0008 19.9998C10.7174 19.9998 10.4799 19.904 10.2883 19.7123C10.0966 19.5206 10.0008 19.2831 10.0008 18.9998V15.3998L4.10078 21.2998C3.91745 21.4831 3.68411 21.5748 3.40078 21.5748C3.11745 21.5748 2.88411 21.4831 2.70078 21.2998Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default CollapseIcon;
