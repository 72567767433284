import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { AddIcon, LargeButton } from "@accenture/energy-transition-ui-library";
import { IWorkspace } from "@accenture/energy-transition-interfaces";
import { WorkspacesCarousel } from "./WorkspacesCarousel";
import { workspaceListQuery } from "./workspaceQueries";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import { CreateWorkspace } from "./modals/CreateWorkspace";

const WorkspacesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

interface WorkspaceListProps {
  userID: string;
}

const WorkspaceList = ({ userID }: WorkspaceListProps) => {
  const [isCreateWorkspaceOpen, setCreateWorkspaceOpen] = useState(false);

  const {
    isLoading,
    isRefetching,
    data: workspaces,
  } = useQuery<IWorkspace[]>({
    queryKey: workspaceListQuery(userID).queryKey,
    queryFn: workspaceListQuery(userID).queryFn,
  });

  return (
    <>
      <WorkspacesContainer>
        <LargeButton
          title="Create a Workspace"
          icon={<AddIcon />}
          onClick={() => setCreateWorkspaceOpen(true)}
        />
        {isLoading || isRefetching ? (
          <CircularProgress />
        ) : (
          <WorkspacesCarousel workspaces={workspaces} />
        )}
      </WorkspacesContainer>

      <CreateWorkspace
        isModalOpen={isCreateWorkspaceOpen}
        onCancel={() => setCreateWorkspaceOpen(false)}
      />
    </>
  );
};

export default WorkspaceList;
