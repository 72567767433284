import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const heatWavesLayerMetadata: LayerMetadata = {
  layerName: "Heat Waves",
  sourceID: "heat-waves-layer-source",
  layerID: "heat-waves-layer",
  dataset: "cejst_nri_census_tracts",
  toggleDotColor: "#778BA5",
  description:
    "This dataset highlights census tracts based on the risk index for heat waves. Heat waves is one of the 18 natural hazards identified in the National Risk Index. In the National Risk Index, natural hazards are represented in terms of expected annual loss, which incorporate data for exposure, annualized frequency, and historic loss ratio.",
  fieldsToDisplay: {
    "Heat Wave - Hazard Type Risk Index Rating": "Heat Wave Risk",
    "Heat Wave - Hazard Type Risk Index Score": "Heat Wave Risk Score",
  },
  theme: [DataSetTheme.Climate],
};

export const HeatWavesLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, heatWavesLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, heatWavesLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", heatWavesLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0].properties !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(
          e?.features[0]?.properties["National Risk Index ID"],
        );
      }
    });
    map.on("mouseleave", heatWavesLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: heatWavesLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${heatWavesLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: heatWavesLayerMetadata.layerID,
    source: heatWavesLayerMetadata.sourceID,
    "source-layer": heatWavesLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "National Risk Index ID"], hoveredFeatureID],
          [
            "in",
            ["get", "National Risk Index ID"],
            [
              "literal",
              selectedFeatures.map(
                (f) => f.properties["National Risk Index ID"],
              ),
            ],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": heatWavesLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Heat Wave - Hazard Type Risk Index Score"]],
        0,
        0,
        100,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HeatWavesLayer.metadata = heatWavesLayerMetadata;
