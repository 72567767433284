import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const tribalLandsLayerMetadata: LayerMetadata = {
  layerName: "Tribal Lands",
  sourceID: "tribal-lands-layer-source",
  layerID: "tribal-lands-layer",
  dataset: "cejst_nri_tribal_census_tracts",
  toggleDotColor: "#C08081",
  description:
    "This dataset highlights tribal lands from the Land Representation (LAR) dataset, which depicts American Indian land areas for Federally Recognized Tribes.",
  fieldsToDisplay: {
    "Homeland Infrastructure Foundation-Level Data (HIFLD) AIANNH Areas Dataset - Tribal Entity":
      "Tribal Entity",
    "FEMA Mitigation Planning Jurisdiction Layer - Tribal Entity":
      "Tribal Entity Detailed",
  },
  theme: [DataSetTheme.UrbanPlanning],
};

export const TribalLandsLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, tribalLandsLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, tribalLandsLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", tribalLandsLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0].properties !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(
          e?.features[0]?.properties["National Risk Index ID"],
        );
      }
    });
    map.on("mouseleave", tribalLandsLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: tribalLandsLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${tribalLandsLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: tribalLandsLayerMetadata.layerID,
    source: tribalLandsLayerMetadata.sourceID,
    "source-layer": tribalLandsLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "National Risk Index ID"], hoveredFeatureID],
          [
            "in",
            ["get", "National Risk Index ID"],
            [
              "literal",
              selectedFeatures.map(
                (f) => f.properties["National Risk Index ID"],
              ),
            ],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": tribalLandsLayerMetadata.toggleDotColor,
      "fill-opacity": 0.5,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
TribalLandsLayer.metadata = tribalLandsLayerMetadata;
