import React, { useCallback } from "react";
import { Form } from "@accenture/energy-transition-ui-library/src/molecules/Form/Form";
import { IWorkspace } from "@accenture/energy-transition-interfaces";
import { styled } from "@mui/material/styles";
import { CustomModal } from "@accenture/energy-transition-ui-library/src/molecules/CustomModal/CustomModal";
import { deleteWorkspaceQuery } from "../workspaceQueries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const DeleteButton = styled(Form.StyledButton)`
  text-decoration: none;
`;
export interface IDeleteWorkspaceProps {
  isModalOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  workspace?: IWorkspace;
}

export function DeleteWorkspace(
  props: React.PropsWithChildren<IDeleteWorkspaceProps>,
): React.ReactElement | null {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation({
    mutationFn: deleteWorkspaceQuery,
    onSuccess: () => {
      queryClient.invalidateQueries(["workspaceList"]).then(() => {
        props.onConfirm();
        navigate("/workspaces");
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onSubmit = useCallback(() => {
    if (!props.workspace || !accounts[0].username) {
      return;
    }
    mutate({
      workspaceID: props.workspace.workspace_globalid,
    });
  }, [props.workspace]);

  return (
    <>
      <CustomModal
        isModalOpen={props.isModalOpen}
        isLoading={isLoading}
        onCancel={props.onCancel}
      >
        <h2>Delete {props.workspace?.workspace_name}</h2>
        <p className="b2">
          Are you sure you would like to delete this workspace?
        </p>
        <Form>
          <Form.Divider />
          <Form.ButtonContainer>
            <DeleteButton onClick={onSubmit}>Delete Workspace</DeleteButton>
          </Form.ButtonContainer>
        </Form>
      </CustomModal>
    </>
  );
}
