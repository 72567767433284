import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const energyBurdenLayerMetadata: LayerMetadata = {
  layerName: "Energy Burden",
  sourceID: "energy-burden-layer-source",
  layerID: "energy-burden-layer",
  dataset: "cejst",
  toggleDotColor: "#32CD32",
  description:
    "This dataset highlights disadvantaged communities with energy burdens. These disadvantaged communities are in census tracts that are at or above the 90th percentile for energy cost or PM2.5 in the air and are at or above the 65th percentile for low income.",
  fieldsToDisplay: {
    "Low median household income as a percent of area median income (percentile)":
      "Low median household income as a percent of area median income (percentile)",
    "Energy burden (percentile)": "Energy burden (percentile)",
    "PM2.5 in the air (percentile)": "PM2.5 in the air (percentile)",
  },
  theme: [DataSetTheme.EnergyInfrastructure],
};

export const EnergyBurdenLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, energyBurdenLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, energyBurdenLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", energyBurdenLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", energyBurdenLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: energyBurdenLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${energyBurdenLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: energyBurdenLayerMetadata.layerID,
    source: energyBurdenLayerMetadata.sourceID,
    "source-layer": energyBurdenLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": energyBurdenLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Energy Factor (Definition N)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
EnergyBurdenLayer.metadata = energyBurdenLayerMetadata;
