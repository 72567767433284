import React, { useEffect } from "react";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import {
  BrowserCacheLocation,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";

/// from webpack.DefinePlugin() in config.
declare const OAUTH_CLIENT_ID: string;
declare const OAUTH_TENANT_ID: string;

export function AuthProvider(
  props: React.PropsWithChildren<Record<string, unknown>>,
): React.ReactElement {
  // MSAL configuration
  const configuration: Configuration = {
    auth: {
      clientId: OAUTH_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${OAUTH_TENANT_ID}`,
      redirectUri: "/",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };
  const msalInstance = new PublicClientApplication(configuration);

  useEffect(() => {
    initMsal();
  }, []);

  const initMsal = async () => {
    await msalInstance.initialize();

    await msalInstance.handleRedirectPromise();

    // Set active acccount on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    const request: SilentRequest | RedirectRequest = {
      scopes: ["User.Read"],
      account: accounts[0],
    };

    // Check if user signed in
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // if no account, immediately redirect for auth
      msalInstance.acquireTokenRedirect(request);
    }

    // If account does exist, attempt to silently aquire token
    msalInstance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        console.log("got token: ", tokenResponse);
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log("fallback to token redirect", error);
          return msalInstance.acquireTokenRedirect(request);
        }
        // handle other errors
        console.log("handle other errors", error);
      });
  };

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>Loading...</p>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}
export function Logout(): React.ReactElement {
  const { instance } = useMsal();
  instance.logoutRedirect();

  // Workaround for MSAL issue, see below.
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5807
  useEffect(() => {
    const itemKey = "msal.interaction.status";
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }
  }, []);

  return <p>Loading...</p>;
}
