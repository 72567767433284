import React, { useEffect } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState, useAppDispatch } from "../../redux/store";
import {
  selectSelectedFeatures,
  setMetricEducation,
} from "../../redux/mapSlice";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";
import { getBeforeIdByLayerType, getUniqueFeatures } from "../../utils/utils";
import { LayerGeometryType } from "@accenture/energy-transition-interfaces/src/ILayer";
import { useSelector } from "react-redux";
import { HiddenCensusDP03Layer } from "./HiddenCensusDP03Layer";
import { useTheme } from "@mui/material";

declare const AWS_API_URI: string;

const hiddenCensusDP02LayerMetadata: LayerMetadata = {
  layerName: "Hidden Census Tract DP02",
  sourceID: "hidden-census-dp02-layer-source",
  layerID: "hidden-census-dp02-layer",
  dataset: "census_tract_dp02",
};

export const HiddenCensusDP02Layer = () => {
  const dispatch = useAppDispatch();
  const { map } = useMap();
  const theme = useTheme();
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, HiddenCensusDP03Layer.metadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("moveend", () => {
      updateEducationMetric();
    });
  }, []);

  const updateEducationMetric = () => {
    if (!map || !map.getLayer(hiddenCensusDP02LayerMetadata.layerID)) return;

    const allFeatures = map.queryRenderedFeatures(undefined, {
      layers: [hiddenCensusDP02LayerMetadata.layerID],
    });
    const features = getUniqueFeatures(allFeatures, "tract");

    // Only get data if within the zoom bounds of the dataset (any features exist)
    if (features.length === 0) {
      dispatch(setMetricEducation("N/A"));
      return;
    }

    // Education Metric Calculation
    const totalPopulation = features
      .map(
        (f) =>
          f.properties &&
          f.properties[
            "Estimate!!EDUCATIONAL ATTAINMENT!!Population 25 years and over"
          ],
      )
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    const ninthToTwelthEducationNoDiploma = features
      .map(
        (f) =>
          f.properties &&
          f.properties[
            "Estimate!!EDUCATIONAL ATTAINMENT!!Population 25 years and over!!9th to 12th grade, no diploma"
          ],
      )
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    const lessThanNinthEducation = features
      .map(
        (f) =>
          f.properties &&
          f.properties[
            "Estimate!!EDUCATIONAL ATTAINMENT!!Population 25 years and over!!Less than 9th grade"
          ],
      )
      .filter((val) => typeof val !== "undefined")
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    const lowOrNoEducationPercent =
      (ninthToTwelthEducationNoDiploma + lessThanNinthEducation) /
      totalPopulation;

    const formattedLowOrNoEducation = Math.round(lowOrNoEducationPercent * 100);

    dispatch(setMetricEducation(formattedLowOrNoEducation));
  };

  const sourceConfig: SourceProps = {
    id: hiddenCensusDP02LayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${hiddenCensusDP02LayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: hiddenCensusDP02LayerMetadata.layerID,
    source: hiddenCensusDP02LayerMetadata.sourceID,
    "source-layer": hiddenCensusDP02LayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Line),
    type: "line",
    paint: {
      "line-color": [
        "case",
        [
          "in",
          ["get", "census_tract"],
          ["literal", selectedFeatures.map((f) => f.properties.census_tract)],
        ],
        theme.colors.button.primary,
        "#808080",
      ],
      "line-width": [
        "case",
        [
          "in",
          ["get", "census_tract"],
          ["literal", selectedFeatures.map((f) => f.properties.census_tract)],
        ],
        2,
        1,
      ],
    },
    layout: {
      visibility: "visible",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HiddenCensusDP02Layer.metadata = hiddenCensusDP02LayerMetadata;
