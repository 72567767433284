import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { mix } from "polished";
import {
  MdKeyboardArrowUp as UpArrow,
  MdKeyboardArrowDown as DownArrow,
} from "react-icons/md";

export const DropdownWrapper = styled("div")<{
  width?: string;
}>`
  position: relative;
  text-align: left;
  width: ${(props) => (props.width ? props.width : "282px")};
  h4 {
    margin: 12px 0 12px;
  }
`;

export const SelectedOption = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "selectedValue" &&
    prop !== "isListOpen" &&
    prop !== "dropdownOptions" &&
    prop !== "handleSelection" &&
    prop !== "persistentPlaceholder",
})<{
  isListOpen?: boolean;
  selectedValue?: boolean;
  width?: string;
}>`
  -webkit-appearance: none;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "282px")};
  height: 48px;
  appearance: none;
  display: flex;
  flex: 1;
  padding: 12px 12px 12px 13px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  font-family: ${(props) => props.theme.font.family};
  font-size: ${(props) => props.theme.font.size};
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  color: ${(props) => props.theme.colors.text.major};
  background-color: ${(props) => props.theme.colors.background.base};
  box-sizing: border-box;
  border-radius: ${(props) => (props.isListOpen ? "8px 8px 0 0" : "8px")};
  border: ${(props) =>
    props.isListOpen
      ? `4px solid ${props.theme.colors.button.primary}`
      : props.selectedValue
      ? `2px solid ${props.theme.colors.text.minor}`
      : `2px solid ${props.theme.colors.button.inactive}`};

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.button.primary};
  }

  &:focus {
    border: 4px solid ${(props) => props.theme.colors.button.primary};
  }
`;

export const TextContainer = styled("div")`
  padding-left: 8px;
`;

export const OtherOptionsWrapper = styled("div")<{
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : "282px")};
  position: absolute;
  z-index: 99;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 0 0 8px 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    margin: 16.5px;
    border-radius: 5px;
    background: #888;
  }

  &::-webkit-scrollbar-track {
    position: absolute;
    margin: 16.5px;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.background.sidebar};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.text.minor};
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;

export const Placeholder = styled("p")`
  color: ${(props) => props.theme.colors.text.minor};
`;

export const OtherOption = styled(SelectedOption)`
  display: flex;
  justify-content: flex-start;
  border-radius: 0px;
  padding: 12px 12px;
  border: 2px solid transparent;
  box-sizing: border-box;
  z-index: 99;
  width: ${(props) => (props.width ? props.width : "282px")};
  border: ${(props) =>
    props.selectedValue ? `2px solid #0041f0` : `2px solid transparent`};
  color: ${(props) => props.theme.colors.text.major};
  background-color: ${(props) =>
    props.selectedValue
      ? mix(
          props.theme.colors.hoverMix,
          props.theme.colors.separators.minor,
          props.theme.colors.background.base,
        )
      : props.theme.colors.background.base};

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    border: 2px solid transparent;
    background-color: ${(props) =>
      mix(
        props.theme.colors.hoverMix,
        props.theme.colors.separators.minor,
        props.theme.colors.background.base,
      )};
  }

  &:focus {
    border: 2px solid #0041f0;
    background-color: ${(props) =>
      mix(
        props.theme.colors.hoverMix,
        props.theme.colors.separators.minor,
        props.theme.colors.background.base,
      )};
  }
`;

interface SelectDropdownProps {
  defaultOption: string;
  dropdownOptions: string[];
}

export function SelectDropdown(props: SelectDropdownProps) {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(
    props.defaultOption ?? "",
  );

  function changeOption(option: string) {
    setSelectedOption(option);
    setIsListOpen(!isListOpen);
  }

  return (
    <>
      <DropdownWrapper>
        <SelectedOption
          onClick={() => setIsListOpen(!isListOpen)}
          isListOpen={isListOpen}
        >
          {selectedOption}
          {isListOpen ? (
            <UpArrow size={"1.5em"} fontWeight={"bold"} />
          ) : (
            <DownArrow size={"1.5em"} fontWeight={"bold"} />
          )}
        </SelectedOption>
        <OtherOptionsWrapper>
          {isListOpen
            ? props.dropdownOptions
                .filter((option) => option !== selectedOption)
                .map((option, idx) => (
                  <OtherOption key={idx} onClick={() => changeOption(option)}>
                    {option}
                  </OtherOption>
                ))
            : null}
        </OtherOptionsWrapper>
      </DropdownWrapper>
    </>
  );
}
