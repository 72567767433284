import React, { useCallback, useMemo } from "react";
import { CheckboxList } from "@accenture/energy-transition-ui-library";
import { LayerToggle } from "./LayerToggle";
import {
  addActiveLayer,
  removeActiveLayer,
  selectActiveLayers,
} from "../../../redux/mapSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";

interface LayerToggleGroupProps {
  subtitle: string;
  layerMetadata: LayerMetadata[];
  marginTop?: string;
}
export const LayerToggleGroup = ({
  subtitle,
  layerMetadata,
  marginTop,
}: LayerToggleGroupProps) => {
  const dispatch = useAppDispatch();
  const activeLayers = useSelector(selectActiveLayers);
  const layerIDs = layerMetadata.map((l) => l.layerID);

  const headerState = useMemo(() => {
    if (layerIDs.every((layer) => activeLayers.includes(layer))) {
      return true;
    } else if (layerIDs.some((layer) => activeLayers.includes(layer))) {
      return "indeterminate";
    } else {
      return false;
    }
  }, [activeLayers]);

  const onChangeAll = useCallback(() => {
    if (headerState === "indeterminate" || !headerState) {
      layerIDs.forEach((layer) => dispatch(addActiveLayer(layer)));
    } else {
      layerIDs.forEach((layer) => dispatch(removeActiveLayer(layer)));
    }
  }, [headerState]);

  return (
    <>
      <CheckboxList.Item
        onChange={onChangeAll}
        checked={headerState === true}
        indeterminate={headerState === "indeterminate"}
        subtitle={true}
        marginTop={marginTop}
      >
        {subtitle}
      </CheckboxList.Item>
      {layerMetadata.map((l) => (
        <LayerToggle key={l.layerID} metadata={l} />
      ))}
    </>
  );
};
