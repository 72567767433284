import React from "react";
import { Layer, LayerProps, Source, SourceProps } from "react-map-gl";
import { LayerMetadata } from "@accenture/energy-transition-interfaces";
import { getBeforeIdByLayerType } from "../../utils/utils";
import { LayerGeometryType } from "@accenture/energy-transition-interfaces/src/ILayer";

declare const AWS_API_URI: string;

const hiddenCensusDP05LayerMetadata: LayerMetadata = {
  layerName: "Hidden Census Tract DP05",
  sourceID: "hidden-census-dp05-layer-source",
  layerID: "hidden-census-dp05-layer",
  dataset: "census_tract_dp05",
};

export const HiddenCensusDP05Layer = () => {
  const sourceConfig: SourceProps = {
    id: hiddenCensusDP05LayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${hiddenCensusDP05LayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: hiddenCensusDP05LayerMetadata.layerID,
    source: hiddenCensusDP05LayerMetadata.sourceID,
    "source-layer": hiddenCensusDP05LayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-opacity": 0,
    },
    layout: {
      visibility: "visible",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
HiddenCensusDP05Layer.metadata = hiddenCensusDP05LayerMetadata;
