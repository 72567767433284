import React from "react";
import {
  CustomModal,
  CustomModalProps,
} from "../../molecules/CustomModal/CustomModal";
import { styled } from "@mui/material/styles";

const StyledModal = styled(CustomModal)`
  .modal-content {
    padding-bottom: 48px;
  }
`;

export const WelcomeModal = (props: CustomModalProps) => {
  return (
    <StyledModal {...props}>
      <h2>Welcome!</h2>
      <p className="b2">
        This tool is actively being developed. Please reach out to Cheryl Linder
        or Caroline Narich for any inquiries.
      </p>
    </StyledModal>
  );
};
