import React from "react";
import { styled } from "@mui/material/styles";
import { Carousel } from "../../atoms/Carousel/Carousel";
import { INewsArticle, NewsSlide } from "./NewsSlide";

const CarouselContainer = styled("div")`
  width: 100%;
  padding: 18px 0 80px;
`;

const NewsArticles: INewsArticle[] = [
  {
    title: "Growth in global oil demand is set to slow significantly by 2028",
    description:
      "The Oil 2023 medium-term market report sees oil use for transport going into decline after 2026, but overall consumption is expected to be supported by strong petrochemicals demand. According to the report, growth in the world’s demand for oil is set to slow almost to a halt in the coming years, with the high prices and security of supply concerns highlighted by the global energy crisis hastening the shift towards cleaner energy technologies...",
    date: "14 June 2023",
    url: "https://www.iea.org/news/growth-in-global-oil-demand-is-set-to-slow-significantly-by-2028",
  },
  {
    title:
      "Seventh Meeting of the Joint Federal-State Task Force on Electric Transmission",
    description:
      "In June 2021, the Commission established a Joint Federal-State Task Force on Electric Transmission (Task Force) to formally explore transmission-related topics outlined in the Commission’s order. The Commission stated that the Task Force will convene for multiple formal meetings annually. The next public meeting of the Task Force will be held on Sunday, July 16, 2023 in Austin, Texas. Commissioners may attend and participate in this meeting...",
    date: "19 April 2023",
    url: "https://www.ferc.gov/news-events/events/seventh-meeting-joint-federal-state-task-force-electric-transmission-07162023",
  },
  {
    title: "Synthesis Report of the Sixth Assessment Report",
    description:
      "The Intergovernmental Panel on Climate Change (IPCC) is finalizing its Sixth Assessment cycle, during which the IPCC has produced the Assessment reports of its three Working Groups, three Special Reports, a refinement to the Methodology Report and the Synthesis report. The Synthesis Report (SYR) is the last of the Sixth Assessment Report products, finalized in March 2023, in time to inform the 2023 Global Stocktake by the United Nations Framework Convention on Climate Change as mandated by Decision 1/CP.21...",
    date: "13 March 2023",
    url: "https://www.ipcc.ch/ar6-syr/",
  },
];

export const NewsCarousel = () => {
  return (
    <CarouselContainer>
      <Carousel>
        {NewsArticles.map((article, index) => (
          <NewsSlide
            key={`${article.title}-${index}`}
            title={article.title}
            description={article.description}
            date={article.date}
            url={article.url}
          />
        ))}
      </Carousel>
    </CarouselContainer>
  );
};
