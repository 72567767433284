import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { SelectedFeature } from "@accenture/energy-transition-interfaces";

interface initialState {
  activeLayers: string[];
  mountedLayers: string[];
  loadingLayers: string[];
  selectedFeatures: SelectedFeature[];
  metadata: string[];
  currentView: {
    totalPopulation: number | string;
    percentUnderserved: number | string;
    percentUnemployed: number | string;
    percentWithoutHSDegree: number | string;
    averageCommuteMinutes: number | string;
    averageIncome: number | string;
  };
}

const initialValue: initialState = {
  activeLayers: [],
  mountedLayers: [],
  loadingLayers: [],
  selectedFeatures: [],
  metadata: [],
  currentView: {
    totalPopulation: "N/A",
    percentUnderserved: "N/A",
    percentUnemployed: "N/A",
    percentWithoutHSDegree: "N/A",
    averageCommuteMinutes: "N/A",
    averageIncome: "N/A",
  },
};

export const mapSlice = createSlice({
  name: "map",
  initialState: initialValue,
  reducers: {
    addActiveLayer: (state, action) => {
      if (state.activeLayers.indexOf(action.payload) === -1) {
        state.activeLayers.push(action.payload);
      }
      if (state.mountedLayers.indexOf(action.payload) === -1) {
        state.mountedLayers.push(action.payload);
      }
    },
    removeActiveLayer: (state, action) => {
      state.activeLayers = state.activeLayers.filter(
        (item) => item !== action.payload,
      );
    },
    clearActiveLayers: (state) => {
      state.activeLayers = [];
    },
    addLoadingLayer: (state, action) => {
      if (state.loadingLayers.indexOf(action.payload) === -1) {
        state.loadingLayers.push(action.payload);
      }
    },
    removeLoadingLayer: (state, action) => {
      state.loadingLayers = state.loadingLayers.filter(
        (item) => item !== action.payload,
      );
    },
    addMountedLayer: (state, action) => {
      if (state.mountedLayers.indexOf(action.payload) === -1) {
        state.mountedLayers.push(action.payload);
      }
    },
    setMetricPopulation: (state, action) => {
      state.currentView.totalPopulation = action.payload;
    },
    setMetricUnderserved: (state, action) => {
      state.currentView.percentUnderserved = action.payload;
    },
    setMetricUnemployment: (state, action) => {
      state.currentView.percentUnemployed = action.payload;
    },
    setMetricEducation: (state, action) => {
      state.currentView.percentWithoutHSDegree = action.payload;
    },
    setMetricAverageCommute: (state, action) => {
      state.currentView.averageCommuteMinutes = action.payload;
    },
    setMetricAverageIncome: (state, action) => {
      state.currentView.averageIncome = action.payload;
    },
    setSelectedFeatures: (state, action) => {
      state.selectedFeatures = [...action.payload];
    },
    setMetadata: (state, action) => {
      if (state.metadata.indexOf(action.payload) === -1) {
        state.metadata.push(action.payload);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addActiveLayer,
  removeActiveLayer,
  clearActiveLayers,
  addLoadingLayer,
  removeLoadingLayer,
  addMountedLayer,
  setMetricPopulation,
  setMetricUnderserved,
  setMetricUnemployment,
  setMetricEducation,
  setMetricAverageCommute,
  setMetricAverageIncome,
  setSelectedFeatures,
  setMetadata,
} = mapSlice.actions;

// Selectors - Layers/features
const selectSelectedFeaturesRaw = (state: RootState) =>
  state.map.selectedFeatures;
export const selectSelectedFeatures = createSelector(
  [selectSelectedFeaturesRaw, (_state: RootState, layerID?: string) => layerID],
  (features, layerID) =>
    layerID
      ? features.filter(
          (feature: SelectedFeature) => feature.layerID === layerID,
        )
      : features,
);
export const selectActiveLayers = (state: RootState) => state.map.activeLayers;
export const selectMountedLayers = (state: RootState) =>
  state.map.mountedLayers;
export const selectIsLayerActive = (state: RootState, layerID: string) =>
  state.map.activeLayers.includes(layerID);
export const selectAreLayersLoading = (state: RootState) =>
  state.map.loadingLayers.length > 0;
// Selectors - Metrics
export const selectMetricPopulation = (state: RootState) =>
  state.map.currentView.totalPopulation;
export const selectMetricUnderserved = (state: RootState) =>
  state.map.currentView.percentUnderserved;
export const selectMetricUnemployment = (state: RootState) =>
  state.map.currentView.percentUnemployed;
export const selectMetricEducation = (state: RootState) =>
  state.map.currentView.percentWithoutHSDegree;
export const selectMetricAverageCommute = (state: RootState) =>
  state.map.currentView.averageCommuteMinutes;
export const selectMetricAverageIncome = (state: RootState) =>
  state.map.currentView.averageIncome;
const selectMetadataRaw = (state: RootState) => state.map.metadata;
export const selectMetadata = createSelector(
  [selectMetadataRaw],
  (metadata: string[]) => metadata.map((m) => JSON.parse(m)),
);

export default mapSlice.reducer;
