import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import { RootState } from "../../redux/store";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import { useSelector } from "react-redux";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const pipelinesLayerMetadata: LayerMetadata = {
  layerName: "Energy Pipelines",
  sourceID: "pipelines-layer-source",
  layerID: "pipelines-layer",
  dataset: "all_energy_pipelines",
  toggleDotColor: "#00B7EB",
  description:
    " This dataset displays the location of the following energy pipelines: Crude Oil, HGL, Petroleum Product, and Natural Gas.",
  fieldsToDisplay: {
    Operator: "Operator Name",
    Opername: "Operator Name",
    Status: "Status",
    TYPEPIPE: "Pipeline Type",
    Pipename: "Pipeline Name",
  },
  theme: [DataSetTheme.EnergyInfrastructure],
};
export const PipelinesLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, pipelinesLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, pipelinesLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", pipelinesLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.FID);
      }
    });
    map.on("mouseleave", pipelinesLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: pipelinesLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${pipelinesLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: pipelinesLayerMetadata.layerID,
    source: pipelinesLayerMetadata.sourceID,
    "source-layer": pipelinesLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Line),
    type: "line",
    paint: {
      "line-color": pipelinesLayerMetadata.toggleDotColor,
      "line-width": [
        "case",
        [
          "any",
          ["==", ["get", "FID"], hoveredFeatureID],
          [
            "in",
            ["get", "FID"],
            ["literal", selectedFeatures.map((f) => f.properties.FID)],
          ],
        ],
        3,
        1,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
PipelinesLayer.metadata = pipelinesLayerMetadata;
