import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const SlideContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
`;

const LeftButton = styled("button")`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 0 solid;
  gap: 8px;
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  padding: 0;
  float: left;
  position: absolute;
  z-index: 2;
`;

const RightButton = styled(LeftButton)`
  right: 0;
`;

const RadioButtonContainer = styled("form")`
  text-align: center;
`;

const RadioButton = styled("input")`
  cursor: pointer;
  -webkit-appearance: none;

  :after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    border: 2px solid ${(props) => props.theme.colors.text.minor};
    position: relative;
    background-color: ${(props) => props.theme.colors.text.contrast};
    content: "";
    display: inline-block;
    visibility: visible;
  }

  :checked:after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    position: relative;
    background-color: ${(props) => props.theme.colors.text.minor};
    content: "";
    display: inline-block;
    visibility: visible;
  }
`;

interface CarouselProps {
  children: JSX.Element | JSX.Element[];
  startLoading?: () => void;
  carousel?: {
    currentIndex: number;
    onChangeIndex: (val: number) => void;
  };
  flipButtonBorders?: boolean;
}
export function Carousel(props: CarouselProps) {
  const { children } = props;
  const theme = useTheme();

  if (!Array.isArray(children)) {
    console.log("children is not an array", children);
    return children;
  }

  const [currentIndex, setCurrentIndex] = useState(
    props.carousel?.currentIndex ?? 0,
  );
  const [length, setLength] = useState(children.length);

  useEffect(() => {
    setLength(children.length);
  }, [children]);

  useEffect(() => {
    props.carousel?.onChangeIndex(currentIndex);
  }, [currentIndex]);

  const next = () => {
    if (currentIndex < length - 1) {
      props.startLoading && props.startLoading();
      setCurrentIndex((prevState) => prevState + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      props.startLoading && props.startLoading();
      setCurrentIndex((prevState) => prevState - 1);
    } else {
      setCurrentIndex(length - 1);
    }
  };

  return (
    <>
      <SlideContainer>
        <ButtonContainer>
          <LeftButton onClick={prev}>
            <RiArrowLeftSLine
              size={"2em"}
              style={{ color: theme.colors.text.white }}
            />
          </LeftButton>
        </ButtonContainer>
        {children[currentIndex]}
        <ButtonContainer>
          <RightButton onClick={next}>
            <RiArrowRightSLine
              size={"2em"}
              style={{ color: theme.colors.text.white }}
            />
          </RightButton>
        </ButtonContainer>
      </SlideContainer>
      <RadioButtonContainer>
        {children.map((children, idx) => (
          <RadioButton
            type="radio"
            key={`slide-${idx}`}
            checked={idx === currentIndex}
            onClick={() => setCurrentIndex(idx)}
            readOnly={true}
          />
        ))}
      </RadioButtonContainer>
    </>
  );
}
