import React from "react";
import { CheckboxList } from "@accenture/energy-transition-ui-library";
import { ITags } from "@accenture/energy-transition-interfaces";
import { TagsProps } from "./TagsList";

function FilterAll(props: TagsProps): React.ReactElement {
  const { tagsFilter, onChangeFilter } = props;
  const totalTags = Object.entries(ITags);

  return (
    <CheckboxList>
      <CheckboxList.Item
        onChange={() => onChangeFilter("All Items")}
        checked={tagsFilter?.length === totalTags?.length}
      >
        Show All
      </CheckboxList.Item>
    </CheckboxList>
  );
}

export default FilterAll;
