import React, { useState } from "react";
import { IWorkspace } from "@accenture/energy-transition-interfaces";
import { WorkspaceCard } from "./WorkspaceCard";
import { CardsCarousel } from "@accenture/energy-transition-ui-library";

interface CarouselProps {
  workspaces: IWorkspace[] | undefined;
}
export function WorkspacesCarousel({ workspaces }: CarouselProps) {
  if (!workspaces || !Array.isArray(workspaces) || workspaces.length === 0)
    return <></>;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <CardsCarousel
      itemQuantity={workspaces.length}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
    >
      {workspaces
        .slice(currentIndex, currentIndex + 4)
        .map((workspace, idx) => (
          <WorkspaceCard
            index={idx}
            key={workspace.workspace_globalid}
            workspace={workspace}
          />
        ))}
    </CardsCarousel>
  );
}
