import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const pollutionBurdenLayerMetadata: LayerMetadata = {
  layerName: "Pollution Burden",
  sourceID: "pollution-burden-layer-source",
  layerID: "pollution-burden-layer",
  dataset: "cejst",
  toggleDotColor: "#003366",
  description:
    "This dataset highlights disadvantaged communities with legacy pollution burdens. These disadvantaged communities are in census tracts that have at least one abandoned mine land, used defense sites, are at or above the 90th percentile for proximity to hazardous waste facilities, proximity to Superfund sites, or proximity to RMP facilities and are at or above the 65th percentile for low income.",
  fieldsToDisplay: {
    "Low median household income as a percent of area median income (percentile)":
      "Low median household income as a percent of area median income (percentile)",
    "Is there at least one abandoned mine in this census tract, where missing data is treated as False?":
      "Is there at least one abandoned mine in this census tract, where missing data is treated as False?",
    "Is there at least one Formerly Used Defense Site (FUDS) in the tract, where missing data is treated as False?":
      "Is there at least one Formerly Used Defense Site (FUDS) in the tract, where missing data is treated as False?",
    "Is there at least one Formerly Used Defense Site (FUDS) in the tract?":
      "Is there at least one Formerly Used Defense Site (FUDS) in the tract?",
    "Proximity to NPL sites (percentile)":
      "Proximity to National Priorities List (NPL) sites (percentile)",
    "Proximity to Risk Management Plan (RMP) facilities (percentile)":
      "Proximity to Risk Management Plan (RMP) facilities (percentile)",
    "Proximity to hazardous waste sites (percentile)":
      "Proximity to hazardous waste sites (percentile)",
  },
  theme: [DataSetTheme.Environment],
};

export const PollutionBurdenLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, pollutionBurdenLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, pollutionBurdenLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", pollutionBurdenLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.GEOID10);
      }
    });
    map.on("mouseleave", pollutionBurdenLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: pollutionBurdenLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${pollutionBurdenLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: pollutionBurdenLayerMetadata.layerID,
    source: pollutionBurdenLayerMetadata.sourceID,
    "source-layer": pollutionBurdenLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "GEOID10"], hoveredFeatureID],
          [
            "in",
            ["get", "GEOID10"],
            ["literal", selectedFeatures.map((f) => f.properties.GEOID10)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": pollutionBurdenLayerMetadata.toggleDotColor,
      "fill-opacity": [
        "interpolate",
        ["linear"],
        ["to-number", ["get", "Pollution Factor (Definition N)"]],
        0,
        0,
        1,
        0.5,
      ],
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
PollutionBurdenLayer.metadata = pollutionBurdenLayerMetadata;
