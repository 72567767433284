import axios from "axios";

declare const AWS_API_URI: string;
declare const AWS_API_KEY: string;

const instance = axios.create({
  baseURL: AWS_API_URI,
  timeout: 60000,
  headers: {
    "x-api-key": AWS_API_KEY,
  },
});

export default instance;
