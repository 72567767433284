import React from "react";
import { styled } from "@mui/material/styles";
import WorkspaceList from "./WorkspaceList";
import {
  DropdownDivider,
  NewsCarousel,
  ResourcesContainer,
} from "@accenture/energy-transition-ui-library";
import { ThemesContainer } from "../LandingPage/ThemesSection/ThemesContainer";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const WorkspacesPageWrapper = styled("div")`
  color: ${(props) => props.theme.colors.text.major};
`;

const Divider = styled(DropdownDivider)`
  margin: 0;
  height: 2px;
`;

const Section = styled("section")`
  max-width: 1800px;
  padding: 0 40px;
  margin: auto;
  h2 > .b1 {
    margin: 0;
  }
  &.header {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  &.workspaces {
    margin-top: 80px;
    p {
      margin-bottom: 32px;
    }
  }
  &.themes {
    margin-top: 80px;
  }
  &.news {
    margin-top: 100px;
  }
  &.resources {
    margin-bottom: 80px;
  }
`;

export function WorkspacesPage() {
  const auth = useIsAuthenticated();
  const { accounts } = useMsal();
  const user = accounts[0].username.split(".")[0];
  const userFirstName = user && user?.charAt(0).toUpperCase() + user?.slice(1);

  return (
    <>
      <WorkspacesPageWrapper>
        <Section className="header">
          <h1>Welcome, {userFirstName}!</h1>
        </Section>
        <Divider />
        <Section className="workspaces">
          <h2>Your Workspaces</h2>
          <p className="b1">
            Create a new workspace to play around in or pick up where you left
            off with a saved workspace.
          </p>
          {auth && <WorkspaceList userID={accounts[0].username} />}
        </Section>
        <Section className="themes">
          <h2>Themes</h2>
          <p className="b1">
            Start building your workspace with the theme that addresses what’s
            important to you.
          </p>
          {auth && <ThemesContainer userID={accounts[0].username} />}
        </Section>
        <Section className="news">
          <h1>Recent News</h1>
          <NewsCarousel />
        </Section>
        <Section className="resources">
          <h2>Resources</h2>
          <ResourcesContainer />
        </Section>
      </WorkspacesPageWrapper>
    </>
  );
}
