import React, { useState, useCallback, useEffect } from "react";
import {
  MetricItem,
  StyledDropdown,
} from "@accenture/energy-transition-ui-library";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "AFDC",
    count: 4,
  },
  {
    name: "Census",
    count: 4,
  },
  {
    name: "US Energy Atlas",
    count: 8,
  },
  {
    name: "EJS",
    count: 2,
  },
  {
    name: "CEJST",
    count: 7,
  },
  {
    name: "FEMA",
    count: 6,
  },
  {
    name: "BPD",
    count: 9,
  },
];

interface IItem {
  name: string;
  count: number;
}

enum DropdownOptions {
  Default = "Default",
  Ascending = "Ascending",
  Descending = "Descending",
}

const DatasetUsage = () => {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortedData, setSortedData] = useState<IItem[] | undefined>(data);

  const sortElements = useCallback(
    (sortBy: string) => {
      setSortBy(sortBy);
      const newData = [...data];
      if (sortBy === DropdownOptions.Default) {
        newData.sort((a, b) => {
          const x = a.name.toLowerCase();
          const y = b.name.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        });
      }
      if (sortBy === DropdownOptions.Descending) {
        newData.sort((a, b) => b.count - a.count);
      }
      if (sortBy === DropdownOptions.Ascending) {
        newData.sort((a, b) => a.count - b.count);
      }
      setSortedData(newData);
    },
    [data],
  );

  useEffect(() => {
    sortElements(DropdownOptions.Default);
  }, []);

  return (
    <MetricItem
      width="100%"
      height="364px"
      title="Dataset Usage"
      source="Label"
      align="flex-start"
      padding="20px"
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={sortedData}
          margin={{
            right: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#460073" />
        </BarChart>
      </ResponsiveContainer>
      <StyledDropdown
        dropdownOptions={Object.values(DropdownOptions)}
        id="data-usage-dropdown"
        handleSelection={sortElements}
        value={sortBy}
        placeholder="Sort by"
        persistentPlaceholder
        width="192px"
      />
    </MetricItem>
  );
};

export default DatasetUsage;
