import {
  LayerGeometryType,
  LayerMetadata,
  DataSetTheme,
} from "@accenture/energy-transition-interfaces";
import React, { useEffect, useState } from "react";
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl";
import {
  selectIsLayerActive,
  selectSelectedFeatures,
} from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBeforeIdByLayerType } from "../../utils/utils";

declare const AWS_API_URI: string;

const endangeredSpeciesLayerMetadata: LayerMetadata = {
  layerName: "Endangered Species",
  sourceID: "endangered-species-layer-source",
  layerID: "endangered-species-layer",
  dataset: "threatened_endangered_and_protected_species_ranges",
  toggleDotColor: "#FFB300",
  description:
    "This dataset displays the ranges and designated critical habitats of species listed as threatened and endangered under the Endangered Species Act (ESA) in their area.",
  fieldsToDisplay: {
    COMNAME_1: "Name of Species",
    STATUS_ABB: "Status",
    SCINAME: "Scientific Name",
    FAMILY: "Family",
  },
  theme: [DataSetTheme.Environment],
};

export const EndangeredSpeciesLayer = () => {
  const { map: map } = useMap();
  const [hoveredFeatureID, setHoveredFeatureID] = useState<string>("");
  const selectedFeatures = useSelector((state: RootState) =>
    selectSelectedFeatures(state, endangeredSpeciesLayerMetadata.layerID),
  );
  const showLayer = useSelector((state: RootState) =>
    selectIsLayerActive(state, endangeredSpeciesLayerMetadata.layerID),
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", endangeredSpeciesLayerMetadata.layerID, (e) => {
      if (e === undefined || e.features === undefined) return;
      if (e.features.length > 0 && e.features[0] !== null) {
        map.getCanvas().style.cursor = "pointer";
        setHoveredFeatureID(e?.features[0]?.properties?.OBJECTID);
      }
    });
    map.on("mouseleave", endangeredSpeciesLayerMetadata.layerID, () => {
      map.getCanvas().style.cursor = "";
      setHoveredFeatureID("");
    });
  }, []);

  const sourceConfig: SourceProps = {
    id: endangeredSpeciesLayerMetadata.sourceID,
    type: "vector",
    tiles: [
      `${AWS_API_URI}/tiles/${endangeredSpeciesLayerMetadata.dataset}/{z}/{x}/{y}.mvt`,
    ],
  };

  const layerConfig: LayerProps = {
    id: endangeredSpeciesLayerMetadata.layerID,
    source: endangeredSpeciesLayerMetadata.sourceID,
    "source-layer": endangeredSpeciesLayerMetadata.dataset,
    beforeId: getBeforeIdByLayerType(LayerGeometryType.Fill),
    type: "fill",
    paint: {
      "fill-outline-color": [
        "case",
        [
          "any",
          ["==", ["get", "OBJECTID"], hoveredFeatureID],
          [
            "in",
            ["get", "OBJECTID"],
            ["literal", selectedFeatures.map((f) => f.properties.OBJECTID)],
          ],
        ],
        "rgba(0,0,0,1)",
        "rgba(0,0,0,0)",
      ],
      "fill-color": endangeredSpeciesLayerMetadata.toggleDotColor,
      "fill-opacity": 0.03,
    },
    layout: {
      visibility: showLayer ? "visible" : "none",
    },
  };

  return (
    <Source {...sourceConfig}>
      <Layer {...layerConfig} />
    </Source>
  );
};
EndangeredSpeciesLayer.metadata = endangeredSpeciesLayerMetadata;
