import React from "react";
import { Route, Routes } from "react-router-dom";
import { LayerTogglesContainer } from "./SidebarComponents/Checkboxlist/LayerTogglesContainer";
import { SidebarDropdown } from "./SidebarComponents/Checkboxlist/SidebarDropdown";
import { TagsList } from "./SidebarComponents/TagsList/TagsList";
import { WorkspaceSidebar } from "./WorkspaceSidebar";
import { styled } from "@mui/material/styles";
import { RunPrioritization } from "./SidebarComponents/RunPrioritization/RunPrioritization";

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  align-items: center;
`;

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;
  gap: 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 258px;
  // Subtracting the following:
  // - 92px: workspace name section
  // - 226.39px: map/data catalog/data upload menu
  // - 80px: header
  // - 32px: additional padding for buttom of scrollable area
  max-height: calc(100vh - (92px + 226.39px + 80px + 32px));

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.background.sidebar};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.text.minor};
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;

export function SidebarContainer(): React.ReactElement | null {
  return (
    <Routes>
      <Route path="/" element={<WorkspaceSidebar />}>
        <Route
          path="map"
          element={
            <Content>
              <SidebarDropdown />
              <Section>
                <RunPrioritization />
                <LayerTogglesContainer />
              </Section>
            </Content>
          }
        />
        <Route
          path="dataCatalog"
          element={
            <Content>
              <TagsList />
            </Content>
          }
        />
      </Route>
    </Routes>
  );
}
