import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function RouterScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const elem = document.getElementById("energy-transition-root");
    elem?.scrollTo({ top: -Number.MAX_SAFE_INTEGER, left: 0 });
  }, [pathname]);

  return null;
}
