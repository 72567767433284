import React, { useRef } from "react";
import maplibregl from "maplibre-gl";
import ReactMapGL, { MapRef } from "react-map-gl/maplibre";

export interface IMapProps {
  width?: string | number;
  height?: string | number;
  layers: JSX.Element;
  darkMode?: boolean;
  [key: string]: unknown;
}

// Start centered on United States
export const initialMapState = {
  longitude: -95.7129,
  latitude: 37.0902,
  zoom: 4.5,
};

declare const MAPTILER_ACCESS_TOKEN: string;

export const Map = ({
  width = "100%",
  height = "100%",
  darkMode = false,
  layers,
  children,
  ...rest
}: React.PropsWithChildren<IMapProps>) => {
  const mapRef = useRef<MapRef>(null);
  const mapStyle = darkMode
    ? `https://api.maptiler.com/maps/streets-v2-dark/style.json?key=${MAPTILER_ACCESS_TOKEN}`
    : `https://api.maptiler.com/maps/streets-v2-pastel/style.json?key=${MAPTILER_ACCESS_TOKEN}`;

  return (
    <ReactMapGL
      ref={mapRef}
      mapLib={maplibregl}
      initialViewState={initialMapState}
      style={{ width: width, height: height }}
      mapStyle={mapStyle}
      {...rest}
    >
      {layers}
      {children}
    </ReactMapGL>
  );
};
