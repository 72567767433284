import React, { forwardRef, InputHTMLAttributes } from "react";
import { styled } from "@mui/material/styles";

export const InputBar = styled("input")<{ invalidValue?: boolean }>`
  height: 48px;
  padding: 12px;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.font.family};
  font-size: ${(props) => props.theme.font.size};
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
  color: ${(props) => props.theme.colors.text.major};
  background: ${(props) => props.theme.colors.background.base};
  border: 2px solid
    ${(props) =>
      props.invalidValue ? "red" : props.theme.colors.button.inactive};
  border-radius: 8px;
  overflow-x: auto;
  overflow-y: hidden;

  &:not(:placeholder-shown) {
    border: 2px solid
      ${(props) => (props.invalidValue ? "red" : props.theme.colors.text.minor)};
  }

  &:hover {
    border: 2px solid
      ${(props) =>
        props.invalidValue ? "red" : props.theme.colors.button.primary};
  }

  &:disabled {
    background-color: #b3b2b529;
  }

  &:focus {
    outline: none;
    border: 4px solid
      ${(props) =>
        props.invalidValue ? "red" : props.theme.colors.button.primary};
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.text.minor};
  }
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 282px;
`;

export const Label = styled("label")`
  display: flex;
  align-items: center;
  height: 48px;
  h4 {
    margin: 0px;
  }
`;

export const Error = styled("div")`
  margin: 4px 0px 0px 4px;
  color: red;
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  id?: string;
  placeholder?: string;
  invalidValue?: boolean;
  errorMessage?: string;
}

export const Input = forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<InputProps>
>(function Input(props, ref) {
  return (
    <InputContainer>
      {props.title && (
        <Label htmlFor={props.id}>
          <h4>{props.title}</h4>
        </Label>
      )}
      <InputBar {...props} ref={ref}></InputBar>
      {props.invalidValue && <Error className="b4">{props.errorMessage}</Error>}
    </InputContainer>
  );
});
