import React, { useCallback } from "react";
import { Form } from "@accenture/energy-transition-ui-library";
import { DataClassification } from "@accenture/energy-transition-interfaces";
import { ModalPages } from "./DataUpload";

export interface IUploadModalProps {
  classification: string | null;
  setClassification: (option: string) => void;
  handleModalPage: (page: string) => void;
}

export const DataClassificationPage = (
  props: React.PropsWithChildren<IUploadModalProps>,
) => {
  const { classification, setClassification, handleModalPage } = props;

  const onSelectClassification = useCallback(
    (option: string) => {
      setClassification(option);
    },
    [classification],
  );

  return (
    <>
      <h2>Data Classification</h2>
      <p className="b2">
        There are four data classifications for this platform - Restricted,
        Unrestricted, Confidential, and Highly Confidential. You can refer to
        your organization’s classification guidelines to determine your data
        classification. Please select the appropriate classification of the file
        you wish to upload, and click “I Agree” to visualize your dataset.
      </p>
      <Form.Spacer gap="16px" />
      <Form.Dropdown
        id="dataClassification"
        title="Data Classification"
        placeholder="Select Classification"
        dropdownOptions={Object.values(DataClassification)}
        handleSelection={onSelectClassification}
        value={classification}
      />
      <Form.Divider />
      <Form.ButtonContainer>
        <Form.StyledButton
          type="button"
          id="data-clasification"
          disabled={!classification}
          onClick={() => handleModalPage(ModalPages.UploadDataSet)}
        >
          I AGREE
        </Form.StyledButton>
      </Form.ButtonContainer>
    </>
  );
};

export default DataClassificationPage;
