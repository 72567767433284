import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import {
  IndividualSource,
  SourceProps,
} from "../IndividualSource/IndividualSource";
import { darkTheme } from "../../atoms/Theme/Theme";

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 1.5em 0;
  overflow: hidden;
  position: relative;
  z-index: 0;
`;

const ButtonContainer = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
`;

const LeftButton = styled("button")`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 0 solid;
  gap: 8px;
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  padding: 0;
  float: left;
  position: absolute;
  z-index: 1;
`;

const RightButton = styled(LeftButton)`
  right: 1em;
`;

interface CarouselProps {
  sources: SourceProps[];
}
export function ResourcesCarousel(props: CarouselProps) {
  const { sources } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(sources.length);

  useEffect(() => {
    setLength(sources.length);
  }, [sources]);

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 4);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 4);
    }
  };

  return (
    <Container>
      <ButtonContainer>
        {currentIndex > 0 && (
          <LeftButton onClick={prev}>
            <RiArrowLeftSLine
              size={"2em"}
              style={{ color: darkTheme.colors.text.major }}
            />
          </LeftButton>
        )}
      </ButtonContainer>

      {sources.slice(currentIndex, currentIndex + 4).map((source, idx) => (
        <IndividualSource
          key={idx}
          title={source.title}
          sourceLink={source.sourceLink}
        />
      ))}
      <ButtonContainer>
        {currentIndex < length - 4 && length > 4 && (
          <RightButton onClick={next}>
            <RiArrowRightSLine
              size={"2em"}
              style={{ color: darkTheme.colors.text.major }}
            />
          </RightButton>
        )}
      </ButtonContainer>
    </Container>
  );
}
