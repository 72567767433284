import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAppDispatch } from "../../redux/store";
import { addLoadingLayer, removeLoadingLayer } from "../../redux/mapSlice";
import { useSelector } from "react-redux";
import {
  selectPrioritizationFactors,
  selectRunPrioritization,
  selectSelectedHex,
  setSelectedHex,
} from "../../redux/workspaceSlice";
import {
  PrioritizationFactorsResponse,
  editPrioritizationFactorsQuery,
} from "../../Sidebar/SidebarComponents/RunPrioritization/runPrioritizationQueries";
import { H3ClusterLayer } from "@deck.gl/geo-layers/typed";

export const RunPrioritizationEVChargersLayerDECKGL = (): H3ClusterLayer => {
  const dispatch = useAppDispatch();
  const factors = useSelector(selectPrioritizationFactors);
  const selectedHex = useSelector(selectSelectedHex);
  const [allData, setAllData] = useState<PrioritizationFactorsResponse[]>([]);

  // Prioritization Query
  const { isFetching: fetchingPrioritization, data: prioritizationData } =
    useQuery({
      queryKey: editPrioritizationFactorsQuery(factors).queryKey,
      queryFn: editPrioritizationFactorsQuery(factors).queryFn,
      enabled: !!factors,
    });

  const isRunPrioritizationOn = useSelector(selectRunPrioritization);
  const highColor: [number, number, number] = [71, 0, 115];
  const mediumColor: [number, number, number] = [160, 85, 245];
  const lowColor: [number, number, number] = [220, 175, 255];

  useEffect(() => {
    if (!isRunPrioritizationOn) {
      dispatch(setSelectedHex(""));
    }
  }, [isRunPrioritizationOn]);

  useEffect(() => {
    if (!prioritizationData) return;

    setAllData([
      ...prioritizationData.high,
      ...prioritizationData.medium,
      ...prioritizationData.low,
    ]);
  }, [prioritizationData]);

  useEffect(() => {
    if (fetchingPrioritization) {
      dispatch(addLoadingLayer("fetch-run-priorization-query"));
    } else {
      dispatch(removeLoadingLayer("fetch-run-priorization-query"));
    }
  }, [fetchingPrioritization]);

  const getHexagonColor = (bucket: "low" | "medium" | "high") => {
    switch (bucket) {
      case "low":
        return lowColor;
      case "medium":
        return mediumColor;
      case "high":
        return highColor;
      default:
        return [255, 255, 255, 0] as [number, number, number, number];
    }
  };

  const layerConfig = new H3ClusterLayer({
    id: "h3-cluster-layer",
    data: allData,
    pickable: true,
    stroked: true,
    filled: true,
    updateTriggers: {
      getFillColor: allData,
      getLineWidth: selectedHex,
    },
    extruded: false,
    getHexagons: (d: PrioritizationFactorsResponse) => [d.hexagon_id],
    getFillColor: (d: PrioritizationFactorsResponse) =>
      getHexagonColor(d.ev_prioritization_bucket),
    getLineColor: [255, 255, 255],
    getLineWidth: (d: PrioritizationFactorsResponse) =>
      d.hexagon_id === selectedHex ? 60 : 10,
    opacity: 0.1,
    visible: isRunPrioritizationOn,
  });

  return layerConfig;
};
