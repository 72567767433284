import React from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonType } from "../../../atoms/Button/Button";
import { MdClose } from "react-icons/md";

const Buffer = styled("div")`
  height: 1em;
`;
export interface DialogboxProps {
  dialogTitle?: string | JSX.Element;
  onCancel?: () => void;
  onSuccess?: () => void;
  top?: string;
  noDivider?: boolean;
}

const DialogContainer = styled("div")<{
  top?: string;
}>`
  background-color: ${(props) => props.theme.colors.background.sidebar};
  border-radius: 0.3em;
  width: 32.5em;
  min-height: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.top ?? "20px"};
  box-shadow: ${(props) => props.theme.styles.shadows.popup};
`;

const TitleBar = styled("div")`
  padding: 8px 8px 16px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
`;

const CloseButton = styled(Button)`
  background: transparent;
  color: ${(props) => props.theme.colors.text.major};

  :hover {
    color: ${(props) => props.theme.colors.text.contrast};
  }
`;

const DialogboxDivider = styled("p")`
  display: block;
  background-color: ${(props) => props.theme.colors.border.light};
  height: 0.15em;
  margin: 0;
`;
const Body = styled("div")`
  margin: 1em;
  text-align: right;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 48px);
  margin: 24px 16px 16px 16px;
  gap: 24px;
  max-height: 600px;
  padding: 0px 8px 8px 8px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.background.sidebar};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.text.minor};
  }
`;
export function Dialogbox(
  props: React.PropsWithChildren<DialogboxProps>,
): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onSuccess, onCancel, ...rest } = props;
  return (
    <DialogContainer {...rest}>
      <TitleBar>
        {props.dialogTitle ?? <Buffer />}
        {onCancel ? (
          <CloseButton buttonType={ButtonType.Symbol} onClick={onCancel}>
            <MdClose />
          </CloseButton>
        ) : null}
      </TitleBar>
      {props.noDivider ? null : <DialogboxDivider />}
      <Body>{props.children}</Body>
    </DialogContainer>
  );
}
